import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import * as _ from 'lodash';

import {convertFiltersToElasticQuery} from '../../shared/utils';
import {ApiRoutes} from '../../../../common/api-routes';
import {
  ClientSite,
  DeleteTCResponse,
  EnableForIPSResponse,
  LiteBatchTimecardsResponse,
  PaymasterBatchDetails,
  PaymasterBatchResponse, PayrollEvent, ReleaseInvoiceRequest, VacationHoliday
} from '../../../../common/paymaster-batch.model';
import {Paymaster} from '../../../../common/paymaster.model';
import {GtnEditReport} from '../../../../common/report.model';
import {LiteBrowseQueryParams} from '../../models/query-params.model';
import {InjectData} from '../../../../common/batch.model';

@Injectable()
export class PaymasterBatchService {
  constructor(private _http: HttpClient) {
  }

  retrieveLitePaymasterBatches(paymasterBatchLiteQueryParams: LiteBrowseQueryParams): Observable<PaymasterBatchResponse> {
    const queryParams = convertFiltersToElasticQuery(paymasterBatchLiteQueryParams);
    const elasticQuery = queryParams.replace('paymasters', 'paymasterInfo.email');
    return this._http
      .get<any>(`${ApiRoutes.Paymaster}/batches/_search?${elasticQuery}`, {observe: 'response'})
      .pipe(
        map(response => response.body)
      );
  }
  retrieveBatchHeaderDetails(batchId: string): Observable<PaymasterBatchDetails> {
    return this._http
      .get<any>(`${ApiRoutes.Paymaster}/batches/${batchId}`, {observe: 'response'})
      .pipe(
        map(response => response.body)
      );
  }
  retrieveBatchDetailsTimecards(batchId: string, timecardQueryParams: LiteBrowseQueryParams): Observable<LiteBatchTimecardsResponse> {
    const queryParams = convertFiltersToElasticQuery(timecardQueryParams);
    return this._http
      .get<any>(`${ApiRoutes.Paymaster}/batches/${batchId}/timecards?${queryParams}`, {observe: 'response'})
      .pipe(
        map(response => response.body),
        catchError((error: HttpErrorResponse) => of(error))
      );
  }

  injectBatchSites(batchId: string, clientSite: ClientSite, isReAssignCheckCodes: boolean, payrollReceivedTime: string, overridden: boolean, overrideReason: string): Observable<boolean> {
    return this._http.post<boolean>(`${ApiRoutes.Paymaster}/batches/${batchId}/inject`, {
      ...clientSite,
      payrollReceivedTime,
      isReAssignCheckCodes,
      overridden,
      overrideReason
    });
  }

  applyVacationHoliday(batchId: string, vacationHoliday: VacationHoliday): Observable<boolean> {
    return this._http.put<boolean>(`${ApiRoutes.Paymaster}/batches/${batchId}/timecards/applyVacationHoliday`, vacationHoliday);
  }

  enableForIPS(batchId, batchNumbers: string[]): Observable<EnableForIPSResponse> {
    return this._http.post<EnableForIPSResponse>(`${ApiRoutes.Paymaster}/batches/${batchId}/enableForIPS`, batchNumbers);
  }
  RetrievePaymasterManagers(): Observable<Paymaster[]> {
    return this._http.get<Paymaster[]>(`${ApiRoutes.Paymaster}/managers`)
      .pipe(
        tap((retrievedPaymasterManagers: Paymaster[]) => {
          return retrievedPaymasterManagers;
        })
      );
  }
  RetrievePaymastersByManagerId(paymasterManagerId): Observable<Paymaster[]> {
    return this._http.get<Paymaster[]>(`${ApiRoutes.Paymaster}/manager/${paymasterManagerId}/paymasters`)
      .pipe(
        map((retrievedPaymasters: Paymaster[]) => retrievedPaymasters),
      );
  }

  unsubmitBatch(batchId: string): Observable<boolean> {
    return this._http.put<boolean>(`${ApiRoutes.Paymaster}/batches/${batchId}/unsubmitBatch`, {});
  }

  // Todo: Removed mapValues() since we expect an array of Response | HttpErrorResponse
  createGTNEditReport(batchId: string, batchNumbers: string[], payload: GtnEditReport): Observable<(Response | HttpErrorResponse)[]> {
    if (_.isEmpty(batchNumbers)) {
      return of([]);
    }
    return forkJoin(_
      .chain(batchNumbers)
      .map((batchNumber) => {
        return this._http.post<Response>(
          `${ApiRoutes.Report}/batches/${batchId}/gtnEditReport`, {...payload, batchNumber},
          {responseType: 'blob' as 'json', observe: 'response' as 'body'}
        ).pipe(
          catchError((error: HttpErrorResponse) => of(error))
        );
      })
      .value()
    );
  }

  releaseInvoice(batchId, releaseInvoiceRequest: ReleaseInvoiceRequest) {
    return this._http.post(`${ApiRoutes.Paymaster}/batches/${batchId}/release`, releaseInvoiceRequest);
  }

  unsubmitTC(batchId: string, timecardIds: string[]): Observable<boolean> {
    return this._http.put<boolean>(`${ApiRoutes.Paymaster}/batches/${batchId}/timecards/unsubmit`, timecardIds);
  }

  updatePayrollEvent(batchId, batchNumber, payrollEvent: Partial<PayrollEvent>): Observable<boolean> {
    return this._http.patch<boolean>(`${ApiRoutes.Paymaster}/batches/${batchId}/batchNumber/${batchNumber}/updatePayrollEvent`, payrollEvent);
  }

  deleteTC(batchId: string, timecardIds: string[]): Observable<DeleteTCResponse> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: timecardIds
    };
    return this._http.delete<DeleteTCResponse>(`${ApiRoutes.Paymaster}/batches/${batchId}/timecards`, options);
  }

  retrieveInvoices(searchText: string): Observable<string[]> {
    return this._http.get<string[]>(`${ApiRoutes.Paymaster}/invoices/_search?search=${searchText}`);
  }

  refreshInvoiceAttribute(batchId: string): Observable<boolean> {
    return this._http.put<boolean>(`${ApiRoutes.Paymaster}/batches/${batchId}/refreshInvoiceAttribute`, {});
  }

  retrieveBatchDetailsInvoice(batchId: string): Observable<InjectData> {
    return this._http.get<InjectData>(`${ApiRoutes.Paymaster}/batches/${batchId}/injectData`);
  }
}

