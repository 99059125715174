<div class="container-fluid">
  <div class="pb-action">
    <div class="row st-w-100 mx-0 p-6-c">
      <div class="col-md-6 d-flex">
        <div class="project-browse-search browse-search mt-2">
          <span class="browse-search-icon"><i class="fa fa-search"></i></span>
          <input type="text" class="search-input" id="iSearch" #iSearch [ngModel]="searchString|async" [placeholder]="'Search For Client ID or Project Name'"
            (input)="onSearchTextChange($event)"/>
        </div>
        <div class="ms-5 filter-panel-container mt-2">
          <span class="fw-bold" qaa="timecardFilterLabel">Filters:</span>
          <span class="ms-2">
                <button type="button" qaa="timecardFilterbtn" class="btn st-fs-14 border shadow-sm"
                        placement="bottom"
                        [autoClose]="'outside'" [placement]="'bottom-left'" [ngbPopover]="projectBrowseFilterPanel" #filterPopover="ngbPopover">
                  <span class="mr-3" qaa="selectedFilterCount" *ngIf="isFilterApplied">
                          {{appliedFilterCount}} {{appliedFilterCount === 1 ? 'Filter' : 'Filters'}}
                      </span>
                      <span *ngIf="!isFilterApplied" qaa="noFilters">
                        No Filters
                      </span>
                </button>
              </span>
          <ng-template #projectBrowseFilterPanel>
            <st-filter-panel qaa="stFilterPanel" (panelClosed)="closePanel()" (clearFilters)="onClearFilters()">
              <st-text-filter projectBrowseType
                              [attr.qaa]="'textFilter-'"
                              [title]="(projectTypeFilter |async).title"
                              [options]="(projectTypeFilter |async).options"
                              [selectedItems]="(projectTypeFilter |async).selectedItems"
                              [tabIndex]="1"
                              (addFilter)="onAddFilter($event)"
                              (removeFilter)="onRemoveFilter($event)"></st-text-filter>
              <st-text-filter projectBrowseStudio
                              [attr.qaa]="'textFilter-'"
                              [title]="(studioFilters |async).title"
                              [options]="(studioFilters |async).options"
                              [selectedItems]="(studioFilters |async).selectedItems"
                              [tabIndex]="1"
                              (searchInput)="onFilterSearchTextChanged($event)"
                              (addFilter)="onAddFilter($event)"
                              (removeFilter)="onRemoveFilter($event)"></st-text-filter>
              <st-dropdown-filter *ngFor="let paymasterFilter of paymasterFilters; let i=index; trackBy: trackByFn"
                                  [attr.qaa]="'dropdownFilters-'+i"
                                  [options]="paymasterFilter.options"
                                  [titleOptions]="paymasterFilter.titleOptions"
                                  [selectedItems]="paymasterFilter.selectedItems"
                                  [tabIndex]="i+1"
                                  [disableInput]="dropDownFilterType?.value === 'paymaster_managers' && isPaymasterFilterSelected"
                                  [filterType]="dropDownFilterType"
                                  (filterTypeChange)="onFilterTypeChanged($event)"
                                  (addFilter)="onAddPaymasterFilter($event)"
                                  (removeFilter)="onRemovePaymasterFilter($event)"></st-dropdown-filter>
              <st-text-filter projectBrowseStatus
                              [attr.qaa]="'textFilter-'"
                              [title]="(projectBrowseStatusFilters |async).title"
                              [options]="(projectBrowseStatusFilters |async).options"
                              [selectedItems]="(projectBrowseStatusFilters |async).selectedItems"
                              [tabIndex]="3"
                              (addFilter)="onAddFilter($event)"
                              (removeFilter)="onRemoveFilter($event)"></st-text-filter>
            </st-filter-panel>

          </ng-template>
        </div>
        <div class="ms-5 d-flex align-items-center">
          <span class="fw-bold" qaa="tcBrowserSortLabel">Sort:</span>
          <span class="ms-2">
                <select class="sort-dropdown" [ngModel]="selectedSort|async" (change)="onSortTypeChanged($event)" qaa="sortDropdown">
                  <option qaa="projectName" value="projectName[asc];clients.clientNumberInt[asc]">Project Name</option>
                  <option qaa="projectClientIdAse" value="clients.clientNumberInt[asc];projectName[asc]">Client # - Ascending</option>
                  <option qaa="projectClientIdDesc" value="clients.clientNumberInt[desc];projectName[asc]">Client # - Descending</option>
                  <option qaa="Studio" value="studioNameNotAnalyzed[asc];projectName[asc];clients.clientNumberInt[asc]">Studio</option>
                  <option qaa="modifiedTimestamp" value="modifiedTimestamp[desc];projectName[asc];clients.clientNumberInt[asc]">Last Updated</option>
                </select>
              </span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex mx-0 w-100 actions-wrapper">
          <ul class="actions-wrapper">
            <li class="pe-0">
              <div class="position-relative manage-column" [id]="grid.projectBrowseGrid">
                <button type="button" qaa="st-btn-time-grid-manage" class="btn btn-link st-fs-14"
                        placement="bottom" [autoClose]="'outside'" [ngbPopover]="manageColumnModal">
                  <i class="fa fa-ellipsis-v st-cursor-pointer"
                     placement="bottom" aria-hidden="true">
                  </i>
                </button>
              </div>
            </li>
            <li class="pe-1">
              <button qaa="btnCreateNewProject" (click)="onCreateNewClicked()"
                      [disabled]="isCreateNewButtonDisabled"
                      class="create-btn st-font-os-sb">NEW PROJECT</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid st-browse-grid-wrap px-0">
  <st-project-browse-grid
    [projects]="projects"
    [columns]="selectedColumnList"
    [columnVisibility]="columnVisibility">
  </st-project-browse-grid>
</div>
<ng-template #manageColumnModal>
  <st-manage-column
    [isGroupingVisible]="false"
    [columnGroupConfig]="columnGroupConfig|async"
    [visibleColumnCount]="visibleColumnCount|async"
    [grid]="grid.projectBrowseGrid"
    (columnVisibilityUpdated)="toggleColumnVisibility($event)">
  </st-manage-column>
</ng-template>


