import {DayOfWeek} from './constants';
import {Paymaster} from './paymaster.model';

export enum GLType {
  normal = 0,
  required = 1,
  locked = 2
}
export enum SegmentType {
  normal = 0,
  department = 1,
  episode = 2,
  insurance = 3,
  location = 4,
  taxIncentive = 5
}

export enum ProjectType {
  internal = 'INTERNAL',
  external = 'EXTERNAL'
}

export enum CustomSickPayCode {
  Default = 'Default',
  Sick2 = 'Sick2',
  Sick3 = 'Sick3'
}

export enum DefaultPaymentType {
  NoPay = 'NOPAY',
  Guar = 'GUAR',
  MinCall = 'MIN',
  Blank = '',
}

export interface AccountCodeSegmentConfig {
  segmentId?: string;
  header: string;
  type: GLType;
  defaultValue: string;
  codingType: SegmentType;
  isInheritGL: boolean;
  hasDuplicateHeaderError?: boolean;
  hasSameHeaderValueError?: boolean;
}

export interface AccountCodeConfig {
  projectId: string;
  lastModifiedBy?: string;
  lastModifiedTimestamp?: string;
  segments: AccountCodeSegmentConfig[];
}

export interface GlShortcut {
 shortcutId: string;
 ordinal: number;
 shortcutName: string;
 values: GlShortcutValues[];
}

export interface ApiGlShortcut {
  glShortcutId: string;
  ordinal: number;
  glShortcutName: string;
  glTemplates: ApiGlTemplates[];
}

export interface ApiGlTemplates {
  glSegmentId: string;
  headerName: string;
  value: string;
}

export interface ProjectSetting {
  projectId: string;
  clientId: number;
  accountCodeConfig: AccountCodeConfig;
  payrollWeekDisplay: DayOfWeek;
  producerWorkWeek: DayOfWeek;
  breakWeeklyAsDaily: boolean;
  holidayPayCodeGroup: string;
  customSickPayCode: CustomSickPayCode;
  defaultPaymentType: DefaultPaymentType;
  productionTitle: string;
  productionCo: string;
  projectType: string;
  paymaster: Paymaster;
  workflowEnabled: boolean;
  isMFAEnabled: boolean;
  processDefinitionId: string;
  isEffectiveMP: boolean;
  effectiveMPDate: string;
  studioName: string;
  status: string;
  clientStatus: string;

  payrollStart?: string;
  prepEnd?: string;
  shootStart?: string;
  shootEnd?: string;
  wrapStart?: string;
  payrollEnd?: string;
  manualMobileUpdate: boolean;
  defaultPayType: DefaultPaymentType;
  glUpdateInprogress: boolean;
}

export interface GlShortcutValues {
  segmentId: string;
  header: string;
  value: string;
}

export interface GLShortcutsViewModel {
  shortcutId?: string;
  shortcutName: string;
  ordinal: number;
  values: GlShortcutValues[];
  hasDuplicateNameError?: boolean;
  hasShortcutNameRequiredError?: boolean;
}
