import * as _ from 'lodash';

import {PayCode, PayType1, PayType2Map} from '../pay-code.model';

export const MapToPayType1List = (payCodes: PayCode[]): PayType1[] => _
    .chain(payCodes)
    .uniqBy('payType1')
    .map(({payType1, payType1DisplayOrdinal}) => ({payType1, payType1DisplayOrdinal}))
    .sortBy('payType1DisplayOrdinal')
    .value();

export const MapToPayType2Map = (payCodes: PayCode[]): PayType2Map => _
  .chain(payCodes)
  .sortBy('payType2DisplayOrdinal')
  .groupBy('payType1')
  .value();

