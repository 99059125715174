import {APP_INITIALIZER, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {StoreModule} from '@ngrx/store';
import {CommonModule} from '@angular/common';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {ToastrModule} from 'ngx-toastr';
import {IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask} from 'ngx-mask';
import {FullRouterStateSerializer, RouterStateSerializer, StoreRouterConnectingModule} from '@ngrx/router-store';

import {environment} from '../environments/environment';
import {CoreModule} from './core/core.module';
import {AppComponent} from './core/containers/app.component';
import {metaReducers, reducers} from './reducers';
import {routes} from './routes';
import {AuthModule} from './auth/auth.module';
import {CustomRouterStateSerializer} from './shared/utils';
import {AppInitAuthService} from './auth/services/appInit-auth.service';
import {AuthInterceptorService} from './auth/services/auth-interceptor.service';
import {SelectivePreloadingStrategy} from './core/selective-preloading-strategy';
import {LookupDataService} from './services/lookup.data.service';
import {PaymasterBatchService} from './paymaster/services/paymaster-batch.service';
import {AppInitLocalStorageService} from './auth/services/appInit-local-storage.service';
import {RouterEffects} from './effects/router.effects';
import {DocumentUploadEffects} from './effects/document-upload.effects';
import {UiEffects} from './effects/ui.effects';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};
export function initialize_local_storage(service: AppInitLocalStorageService) {
  return () => service.initializeLocalStorage();
}
export function initialize_user_profile(appInitAuthService: AppInitAuthService) {
  return () => appInitAuthService.initializeUserProfile();
}

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ToastrModule.forRoot({
      maxOpened: 2,
      timeOut: 3000,
      positionClass: 'toast-top-center'
    }),
    RouterModule.forRoot(routes, {
      useHash: false,
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always',
      preloadingStrategy: SelectivePreloadingStrategy
    }),
    /**
     * StoreModule.forRoot is imported once in the root module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    StoreModule.forRoot(reducers, { metaReducers, runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true } }),
    /**
     * @ngrx/router-store keeps router state up-to-date in the store.
     */
    StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer,
      /*
        They stateKey defines the name of the state used by the router-store reducer.CustomSerializer
        This matches the key defined in the map of reducers
      */
      stateKey: 'router',
    }),
    /**
     * Store dev-tools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */
    StoreDevtoolsModule.instrument({
      name: 'Smart Time Store DevTools',
      logOnly: environment.production,
    }),
    /**
     * EffectsModule.forRoot() is imported once in the root module and
     * sets up the effects class to be initialized immediately when the
     * application starts.
     *
     * See: https://github.com/ngrx/platform/blob/master/docs/effects/api.md#forroot
     */
    EffectsModule.forRoot([RouterEffects, DocumentUploadEffects, UiEffects]),
    CoreModule.forRoot(),
    AuthModule.forRoot()
  ],
  providers: [
    LookupDataService,
    AppInitLocalStorageService,
    AppInitAuthService,
    // Note: AppInitLocalStorageService will fail when retrieving client config until user is logged in, this is because our config API requires authentication
    { provide: APP_INITIALIZER, useFactory: initialize_local_storage, deps: [AppInitLocalStorageService], multi: true},
    { provide: APP_INITIALIZER, useFactory: initialize_user_profile, deps: [AppInitAuthService], multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true},
    /**
     * The `RouterStateSnapshot` provided by the `Router` is a large complex structure.
     * A custom RouterStateSerializer is used to parse the `RouterStateSnapshot` provided
     * by `@ngrx/router-store` to include only the desired pieces of the snapshot.
     */
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    PaymasterBatchService,
    provideNgxMask()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
