import * as _ from 'lodash';
import {createFeatureSelector, createSelector} from '@ngrx/store';

import {DocumentUploadState} from '../reducers/document-upload.reducer';
import {DocumentValidationStatus, DocumentViewModel} from '../models/document.model';
import {DocumentMetadata} from '../../../common/document.model';

const _selectDocumentRootState = createFeatureSelector<DocumentUploadState>('documentUpload');

export const selectDocuments = createSelector(
  _selectDocumentRootState,
  (state: DocumentUploadState): DocumentViewModel[] => state.documentsInQueue ?? []
);
export const selectValidDocuments = createSelector(
  selectDocuments,
  (documents: DocumentViewModel[]): DocumentViewModel[] =>
    _.filter(documents, (document) => document.status === DocumentValidationStatus.PASSED )
);
export const selectIsAnyDocumentCategoryEmpty = createSelector(
  selectValidDocuments,
  (documents: DocumentViewModel[]): boolean => _.isEmpty(documents)
    || _.some(documents, (document) => _.isEmpty(document.category))
);
export const selectUploadedDocuments = createSelector(
  _selectDocumentRootState,
  (state: DocumentUploadState): DocumentMetadata[] => state.documentsUploaded ?? []
);
export const selectUploadedDocumentCount = createSelector(
  selectUploadedDocuments,
  (documentMetaData: DocumentMetadata[]): number => _.size(documentMetaData)
);
export const selectHasAnyPaperTimecardDocument = createSelector(
  selectUploadedDocuments,
  (documentMetaData: DocumentMetadata[]): boolean => _.some(documentMetaData, (document: DocumentMetadata) =>
    document.documentType === 'PAPER_TIMECARDS')
);
