<div class="popover-content manage-column" [id]="grid">
  <div class="row ms-1 me-1">
    <div class="col-md-6 ps-0 pe-0"><h5 class="popover-list-title">Manage columns</h5></div>
    <div class="col-md-6 ps-0 pe-0"><div class="float-end"><h6 class="popover-list-sub-title"><b qaa="visibilityCount">{{visibleColumnCount}}</b> Columns displayed</h6></div></div>
  </div>

  <div class="ms-0 me-0 mt-2 mb-0">

    <div class="flex-grow-1 d-flex">
      <div class="table-wrap st-scrollable-sec">

        <div *ngFor="let columnGroup of columnGroupConfig; let i = index"
             class="table-list" [ngClass]="{'table-list-next': i>0}">
          <div class="row ms-0 me-0" *ngIf="isGroupingVisible">
            <div class="col-md-12 ps-0 pe-0">
              <div class="form-check st-cust-tc-checkbox table-title-check d-flex">
                <input  type="checkbox" class="form-check-input"
                        [checked]="groupVisibility[columnGroup.section]" id="checkbox{{columnGroup.section}}"
                        attr.qaa="checkbox{{columnGroup.section}}"
                        (click)="onGroupVisibilityUpdate(columnGroup.section, grid)">
                <label for="checkbox{{columnGroup.section}}" class="ms-1 custom-control-label"><span class="ms-3">{{columnGroup.groupName}}</span></label></div>
            </div>
          </div>
          <div class="row ms-0 me-0">
            <div class="col-md-12 ps-0 pe-0">
              <table class="table mc-table mt-4">
                <thead>
                <tr>
                  <th class="mc-table-th" *ngFor="let column of columnGroup.columns" [hidden]="!!column.isOptionHidden">{{column.header}}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="td-align mc-table-td"  *ngFor="let column of columnGroup.columns" [hidden]="!!column.isOptionHidden">
                    <div class="form-check st-cust-tc-checkbox table-title-check">
                      <input id="checkbox{{column.field}}{{grid}}" type="checkbox" class="form-check-input"
                             attr.qaa="checkbox{{column.field}}{{grid}}"
                             [disabled]="column?.isMandatory"
                             [checked]="column?.isVisible" (click)="onColumnVisibilityUpdate(columnGroup.section, column.field, grid)">
                      <label for="checkbox{{column.field}}{{grid}}" class="ml-1 custom-control-label st-cursor-pointer" [ngClass]="{'st-cursor-na': column?.isMandatory}"></label>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
