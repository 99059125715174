import {BatchStatus, InjectData} from './batch.model';
import {DocumentMetadata} from './document.model';

export enum ApiInvoiceStatus {
  RELEASED = 'RELEASED',
  UNRELEASED = 'UNRELEASED',
  ENABLED_FOR_IPS = 'ENABLEDFORIPS',
  PROCESSED = 'PROCESSED'
}

export enum InvoiceStatus {
  RELEASED = 'Released',
  UNRELEASED = 'Unreleased',
  ENABLED_FOR_IPS = 'Enabled For IPS',
  PROCESSED = 'Processed',
  NO_STATUS = ''
}

export enum VacationHolidayType {
  DO_NOT_ACCRUE = '01',
  ACCRUE_AND_PAY_NOW = '02',
  ACCRUE_AND_EOP = '03',
  ACCRUE_AND_POMARCH = '04',
  NONE = ''
}

export enum BatchSyncLockStatus {
  Locked = 'Locked',
  Unlocked = 'Unlocked'
}

export interface PaymasterBatch {
  id: string;
  batchName: string;
  projectId: string;
  projectName: string;
  clientId: string;
  payPeriodEnding: string;
  timecards: number;
  errors: number;
  hasUnsubmittableErrors: boolean;
  hasSubmittableErrors: boolean;
  invoice: string;
  payrollBatchDetails: PayrollBatch[];
  status: BatchStatus;
  paymaster: string;
  submittedDate: string;
  urlParams?: string[];
  hasNotes: boolean;
  attachmentsCount: number;
  hasPayrollExceptions: boolean;
}

export interface PaymasterBatchResponse {
  total?: number;
  paymasterBatches: PaymasterBatch[];
}

export interface BatchNumber {
  batchId: string;
  batchNumber: string;
  batchNumberId: string;
}

export interface InvoiceNumber {
  batchId: string;
  invoiceNumber: string;
  invoiceNumberId: string;
}

export interface PayrollBatch {
  isSelected?: boolean;
  payrollBatchDetailId: string;
  batchId: string;
  batchNumber: string;
  invoiceNumber: string;
  invoiceStatus: InvoiceStatus;
  syncLock: BatchSyncLockStatus;
  payrollEvent?: Partial<PayrollEvent>;
  gtnEditStatus?: boolean;
  hasPensionOverrideWarning?: boolean;
}

export interface ClientSites {
  processSites: string[];
  printSites: string[];
  billingSites: string[];
}

export interface ClientSite {
  processingSite: string;
  printSite: string;
  billingSite: string;
}

export interface PaymasterBatchDetails {
  id: string;
  batchName: string;
  billingSite: string;
  projectId: string;
  projectName: string;
  clientId: string;
  payPeriodEnding: string;
  timecards: number;
  batchErrorCount: number;
  timecardErrorCount: number;
  hasUnsubmittableErrors: boolean;
  hasSubmittableErrors: boolean;
  isInternal: boolean;
  invoice: string;
  invoiceNumbers: InvoiceNumber[];
  batchNumbers: BatchNumber[];
  status: BatchStatus;
  paymaster: string;
  submittedDate: string;
  payrollBatchDetails: PayrollBatch[];
  printSite: string;
  processingSite: string;
  processSites: string[];
  billingSites: string[];
  printSites: string[];
  payrollBatchErrors: PayrollBatchIssue[];
  syncLock: BatchSyncLockStatus;
  payrollReceivedTime: string;
  initialSubmittedTime: string;
  documentMetadata: DocumentMetadata[];
  injectData: InjectData;
  hasPayrollExceptions: boolean;
}

export interface LiteBatchTimecardsResponse {
  timecardCount?: number;
  liteBatchTimecards: LiteBatchTimecard[];
}

export interface PayrollBatchIssue {
  errorCode: string;
  errorMessage: string;
}

export interface LiteBatchTimecard {
  isSelected: boolean;
  timecardId: string;
  crewName: string;
  ssn: string;
  fein: string;
  weekEnding: string;
  jobTitle: string;
  invoiceNumber: string;
  epCode: string;
  invoiceStatus: InvoiceStatus;
  vacationType: VacationHolidayType;
  holidayType: VacationHolidayType;
  discrepancy: boolean;
  hasSubmittableErrors: boolean;
  hasUnsubmittableErrors: boolean;
  errorNotes: PayrollTimecardError[];
  startcardId: string;
  batchTimecardUrlParams: string[];
  isRevised: boolean;
  isInternal: boolean;
  hasPayrollExceptions: boolean;
}

export interface VacationHoliday {
  vacationType: VacationHolidayType;
  holidayType: VacationHolidayType;
  timecardIds: string[];
}

export interface EnableForIPSResponse {
  failedBatchNumbers: string[];
  isBatchArchived: boolean;
}

export interface PayrollTimecardError {
  errorCode: string;
  errorMessage: string;
  errorType: string;
}

export interface ReleaseInvoiceResponse {
  failedBatchNumbers: string[];
  isBatchArchived: boolean;
}

export interface Initials {
  initials: string;
  paymasterId: number;
}

export interface ReleaseInvoiceRequest {
  batchNumbers: string[];
  releasedByInitials: Initials;
}

export interface EditSentDetail {
  editSentBy: string;
  editSentById: number;
  editSentDate?: string;
  editSentTime?: string;
  editSentTimestamp: string;
}

export interface EditApprovedDetail {
  editApprovedBy: string;
  editApprovedById: number;
  editApprovedDate?: string;
  editApprovedTime?: string;
  editApprovedTimestamp: string;
}

export interface PayrollEvent {
  batchKeyedBy: string;
  batchKeyedById: number;
  editCheckedBy: string;
  editCheckedById: number;
  releasedBy?: string;
  releasedById?: number;
  editSentDetail: Partial<EditSentDetail>;
  editApprovedDetail: Partial<EditApprovedDetail>;
}

export interface PayrollError {
  errorCode: string;
  errorMessage: string;
  errorType: string;
}

export interface DeleteTCResponse {
  batchId: string;
  payrollDeleteErrors: PayrollError[];
  payrollInjectErrors: PayrollError[];
  payrollInjectWarnings: PayrollError[];
}

export interface ReleaseModalResponse {
  releasedByInitials: Initials;
  payrollBatches: PayrollBatch[];
}

export interface PayrollEventResponse {
  batchNumber: string;
  payrollEvent: PayrollEvent;
}
