import {LinkButtonColumnComponent} from '../client/app/shared/components/grid-column-components/link-button-column.component';
import {FavoriteStarColumnComponent} from '../client/app/shared/components/grid-column-components/favorite-star-column.component';
import {GridColumn} from '../client/app/shared/components/grid-column-filter/grid-column-filter-view.model';
import {SearchTypeOption} from '../client/app/core/models/project-browse.model';
import {ColumnConfig} from '../client/app/shared/components/manage-column/manage-column.model';
import {PaginationParams} from '../client/app/models/query-params.model';
import {ProjectBrowseQueryParams} from '../client/app/core/reducers/project.reducer';
import {FilterOption} from '../client/app/shared/components/filter-panel/filter-item.model';

export namespace ProjectBrowseConstants {
  export class ProjectBrowse {
    public static get ColumnMappings(): any {
      return {
        'projectName': {
          component: LinkButtonColumnComponent,
          payload: {
            'url': 'defaultLandingPage',
            'name': 'projectName'
          }
        },
        'favorite': {
          component: FavoriteStarColumnComponent,
          payload: {
            'favorite': 'favorite'
          }
        }
      };
    }

    public static get InternalMasterColumnList(): ReadonlyArray<ColumnConfig> {
      return [
        {field: 'projectName', header: 'Project Name', isVisible: true, isMandatory: true, ordinal: 1},
        {field: 'projectNumber', header: 'Client ID', isVisible: true, isMandatory: false, ordinal: 2},
        {field: 'type', header: 'Int/Ext', isVisible: true, isMandatory: false, ordinal: 3},
        {field: 'studioName', header: 'Studio', isVisible: true, isMandatory: false, ordinal: 4},
        {field: 'paymaster', header: 'Paymaster', isVisible: true, isMandatory: false, ordinal: 5},
        {field: 'status', header: 'Status', isVisible: true, isMandatory: false, ordinal: 6},
        {field: 'lastUpdatedDate', header: 'Last Updated', isVisible: true, isMandatory: false, ordinal: 7},
        {field: 'lastUpdatedBy', header: 'Updated By', isVisible: true, isMandatory: false, ordinal: 8}
      ];
    }

    public static get ExternalDefaultColumnList(): ReadonlyArray<GridColumn> {
      return [
        {field: 'projectName', header: 'Project Name'},
        {field: 'projectNumber', header: 'Client ID'},
        {field: 'type', header: 'Int/Ext'},
        {field: 'studioName', header: 'Studio'},
        {field: 'paymaster', header: 'Paymaster'},
        {field: 'status', header: 'Status'},
        {field: 'lastUpdatedDate', header: 'Last Updated'},
        {field: 'lastUpdatedBy', header: 'Updated By'}
      ];
    }

    public static get SearchTypes(): SearchTypeOption[] {
      return [
        {name: 'projectNumber', value: 'Client ID'},
        {name: 'projectName', value: 'Project Name'}
      ];
    }

    public static get DefaultProjectBrowsePagination(): PaginationParams {
      return {
        offset: 1,
        pageSize: 40
      };
    }
    public static get DefaultProjectQueryParams(): ProjectBrowseQueryParams {
      return {
        ...this.DefaultProjectBrowsePagination,
        filters: [],
        sort: 'projectName[asc];clients.clientNumberInt[asc]',
        searchText: ''
      };
    }
    public static get projectBrowseIntExtFilters(): FilterOption[] {
      return [
        {id: '0', value: 'Internal', type: 'projectType'},
        {id: '1', value: 'External', type: 'projectType'},
      ];
    }
    public static get projectBrowseStatusFilters(): FilterOption[] {
      return [
        {id: '0', value: 'Active', type: 'active'},
        {id: '1', value: 'Inactive', type: 'active'},
      ];
    }
  }
}
