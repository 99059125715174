import { Injectable } from '@angular/core';
import { FilterOption } from '../components/filter-panel/filter-item.model';
import * as _ from 'lodash';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterPanelService {
  private selectedItems: FilterOption[] = [];
  constructor() { }
  FilterChanged: Subject<FilterOption[]> = new Subject<FilterOption[]>();
  get SelectedItems(): FilterOption[] {
    return this.selectedItems;
  }
  Init(value: FilterOption[]) {
    this.selectedItems = value ? value : [];
  }
  ApplyFilter(item: FilterOption): void {
    this.selectedItems = [
      ...this.selectedItems,
      item
    ];
    this.FilterChanged.next(this.selectedItems);
  }
  RemoveFilter(item: FilterOption): void {
    const idx = _.findIndex(this.selectedItems, (i: FilterOption) => i.id === item.id);
    this.selectedItems.splice(idx, 1);
    this.FilterChanged.next(this.selectedItems);
  }
  ClearFilters(): void {
    this.selectedItems = [];
    this.FilterChanged.next(this.selectedItems);
  }
}
