import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'st-confirm-action-modal',
  template: `
      <div class="st-warning-modal">
          <div class="modal-header st-fs-20 st-font-os-sb">
              {{headerText}}
          </div>
          <div class="modal-body st-fs-14 st-font-os-r multiline-text">
              {{bodyText}}
          </div>
          <div class="modal-footer">
              <button *ngIf="showSecondaryButton" class="btn st-btn-tertiary-md" (click)="modalCancel.emit()"
                      qaa="cancelBtn">CANCEL
              </button>
              <button *ngIf="showTertiaryButton" class="btn st-btn-tertiary-md" (click)="modalTertiaryBtn.emit()"
                      qaa="tertiaryBtn">{{tertiaryBtnText}}
              </button>
              <button class="btn st-btn-primary-md me-0" (click)="modalPrimaryBtn.emit()"
                      [attr.qaa]="primaryBtnText" [attr.ngbAutofocus]="focusOnPrimary || null">{{primaryBtnText}}</button>
          </div>
      </div>`,
  styles: [`
     .multiline-text {
        white-space: pre-line;
      }
  `]
})
export class ConfirmActionModalComponent {
  @Input() headerText: string;
  @Input() bodyText: string;
  @Input() primaryBtnText: string;
  @Input() tertiaryBtnText: string;
  @Input() showSecondaryButton = true;
  @Input() showTertiaryButton = false;
  @Input() focusOnPrimary = false;
  @Output() modalCancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() modalPrimaryBtn: EventEmitter<void> = new EventEmitter<void>();
  @Output() modalTertiaryBtn: EventEmitter<void> = new EventEmitter<void>();
}
