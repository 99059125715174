import * as _ from 'lodash';
import {createAction} from '@ngrx/store';

import * as fromUiModel from '../models/ui.model';
import {AccountCodeConfig} from '../../../common/project-setting.model';
import {Department, DepartmentStartcards} from '../models/crew-department.model';
import * as fromManageColumn from '../shared/components/manage-column/manage-column.model';
import {StartcardSummary, StartcardSummaryViewModel} from '../../../common/startcard-summary.model';
import {TimecardTypes} from '../../../common/timecard.model';

export const InitializeTabOrder = createAction(
  '[UI] Initialize Tab Order Directive',
  (payload: number[]) => ({payload})
);
export const ClearTabOrderMap = createAction(
  '[UI] Clear Tab Order Map'
);
export const ResetTabOrderMap = createAction(
  '[UI] Reset Tab Order Map',
  (payload: Array<number[]>) => ({payload})
);
export const RepopulateColumnConfig = createAction(
  '[UI] Repopulate Manage column configs by projectId',
  (projectId: string) => ({targetId: projectId})
);
export const ToggleColumnVisibility = createAction(
  '[UI] Toggle column visibility state',
  (columnLocation: fromManageColumn.ColumnLocation, projectId: string, timecardType: TimecardTypes) =>
    ({columnLocation, targetId: projectId, timecardType})
);
export const ClearUIColumnConfig = createAction(
  'clear UI column config based on project id',
  (projectId: string) => ({projectId})
);
// Todo refactor the gridConfig payload to generic type
export const GridColumnConfig = createAction(
  '[UI] Update Grid Column config ',
  ( grid: fromManageColumn.Grids,
           gridConfig: fromManageColumn.NewTCTimeGrid | fromManageColumn.NewTCSummaryGrid
           | fromManageColumn.StartcardBrowseGrid | fromManageColumn.TimecardBrowseGrid
           | fromManageColumn.ApprovalBrowseGrid | fromManageColumn.DailyTimeGrid
           | fromManageColumn.SmartTimeUserGrid | fromManageColumn.MobileUserGrid
           | fromManageColumn.IDailyTimeGrid | fromManageColumn.DayCostReportGrid,
           projectId: string, timecardType: TimecardTypes) => ({grid, gridConfig, targetId: projectId, timecardType})
);
// Actions to update and repopulate columnConfigs of payroll tracking only. Since Payroll Tracking route don't have projectId. Ref. Story: STF-12548
export const PaymasterToggleColumnVisibility = createAction(
  '[UI] Paymaster Toggle column visibility state',
  (columnLocation: fromManageColumn.ColumnLocation) => ({columnLocation, targetId: 'paymaster', timecardType: TimecardTypes.CREW})
);
export const PaymasterGridColumnConfig = createAction(
  '[UI] Paymaster update grid column config',
  ( grid: fromManageColumn.Grids,
           gridConfig: fromManageColumn.NewTCTimeGrid | fromManageColumn.NewTCSummaryGrid
           | fromManageColumn.StartcardBrowseGrid | fromManageColumn.TimecardBrowseGrid
           | fromManageColumn.ApprovalBrowseGrid | fromManageColumn.DailyTimeGrid) =>
    ({grid, gridConfig, targetId: 'paymaster', timecardType: TimecardTypes.CREW})
);
export const RepopulatePaymasterColumnConfig = createAction(
  '[UI] Repopulate Paymaster Manage column configs',
  () => ({targetId: 'paymaster'})
);
export const ToggleShowAllColumns = createAction(
  '[UI] Toggle ShowAll columns for a section',
  (section: fromManageColumn.Sections, grid: fromManageColumn.Grids, projectId: string, timecardType: TimecardTypes) =>
    ({section, grid, projectId, timecardType})
);
export const ToggleSectionVisibility = createAction(
  '[UI] Toggle Visibility of all columns In a Group',
  (section: fromManageColumn.Sections, grid: fromManageColumn.Grids, projectId: string, timecardType: TimecardTypes) =>
    ({section, grid, projectId, timecardType})
);
export const Toast = createAction(
  '[UI] Create Toast',
  (toast: fromUiModel.Toast) => ({toast})
);
export const DeleteToast = createAction(
  '[UI] Delete Toast',
  (id: string) => ({id})
);
export const BrowsePageEmpty = createAction(
  '[Batch Summary UI] Browse page empty state updated',
  (isEmpty: boolean) => ({isEmpty})
);
export const ResetBrowsePageEmpty = createAction(
  '[UI] Reset Browse page empty state'
);
export const BrowsePageRowGroupSelection = createAction(
  '[UI] Update browse page columns selection',
  (isChecked: boolean) => ({isChecked})
);
export const ResetBrowsePageRowGroupSelection = createAction(
  '[UI] Reset browse page rows group selection'
);

export const ShowLoadingScreen = createAction(
  '[UI] Show page loader'
);

export const HideLoadingScreen = createAction(
  '[UI] Hide page loader'
);

export const SetTimecardTemplateAvailable = createAction(
  '[UI] Timecard Template Availability Info',
  (isTimecardTemplateAvailable: boolean) => ({isTimecardTemplateAvailable})
);
export const RetrieveTemplateInfoByProjectId = createAction(
  '[UI] Retrieve Template Info By Project Id',
  (projectId: string) => ({projectId})
);
export const ExpandAccountCodeInSplitModal = createAction(
  '[UI] Expand account coding section in split modal'
);
export const ContractAccountCodeInSplitModal = createAction(
  '[UI] Contract account coding section in split modal'
);
export const DepartmentSelected = createAction(
  '[UI] Select Department',
  (departmentId: string, departments: Department[]) => ({
    departmentList: _.map(departments,
      (department) => department.id === departmentId && !department.isSelected
        ? {...department, isSelected: true}
        : {...department, isSelected: false})
  })
);
export const RetrieveDepartmentStartcards = createAction(
  '[UI] Retrieve Startcards for a Department',
  (departmentId: string) => ({departmentId})
);
export const RetrieveStartcardsSuccessful = createAction(
  '[UI] Retrieve Startcard Successful',
  (departmentId: string, startcards: StartcardSummary[]) => {
    return ({
      startcards: _.map(startcards, (startcard) => ({...startcard, isSelected: false})),
      departmentId
    });
  }
);
export const CrewSelectStatus = createAction(
  '[UI] Crew Select Status',
  ({id, status}: fromUiModel.BrowsePageRowSelection, departmentId: string, startcards: DepartmentStartcards) => ({
    departmentStartcards: {
      ...startcards,
      [departmentId]: _.map(startcards[departmentId], (startcard) => startcard.id === id
        ? {...startcard, isSelected: status}
        : {...startcard}
      )
    }
  })
);

export const CrewGroupSelectStatus = createAction(
  '[UI] Crew Group Select Status',
  (status: boolean, departmentId: string, departmentStartcards: DepartmentStartcards, loadedCrewIds: string[]  ) => ({
    departmentStartcards: {
      ...departmentStartcards,
      [departmentId]: _.map(departmentStartcards[departmentId],
        (startcard: StartcardSummaryViewModel) => !_.includes(loadedCrewIds, startcard.id)
          ? {...startcard, isSelected: status}
          : {...startcard}
      )
    }
  })
);
export const ResetCrewsSelectedStatus = createAction(
  '[UI] Reset Crews Selected Status',
  (departmentStartcards: DepartmentStartcards, loadedCrewIds: string[]) => ({
    departmentStartcards: _.reduce(departmentStartcards, (departmentStartcard, startcards, departmentId) => {
      departmentStartcard[departmentId] = _.map(startcards, startcard => ({...startcard, isSelected: _.includes(loadedCrewIds, startcard.id)}));
      return departmentStartcard;
    }, {})
  })
);
export const ChangeRemovedCrewStatus = createAction(
  '[UI] Change removed crew status',
  (selectedCrewIds: string[], departmentStartcards: DepartmentStartcards) => ({
    departmentStartcards : _.reduce(departmentStartcards, (departmentStartcard, startcards, departmentId) => {
      departmentStartcard[departmentId] = _.map(startcards, startcard => ({
          ...startcard,
          isSelected: _.includes(selectedCrewIds, startcard.id) ? false : startcard.isSelected
        }));
      return departmentStartcard;
    }, {})
  })
);
export const LoadDepartmentCrew = createAction(
  '[UI] Add crew to department',
  (startcard: StartcardSummaryViewModel) => ({startcard})
);
export const AddLoadedCrewsToDepartments = createAction(
  '[UI] Add Loaded Crews to load crew departments',
  (firstDepartmentId: string, loadedDepartmentStartcards: DepartmentStartcards, departmentStartcards: DepartmentStartcards) => ({
    firstDepartmentId,
    departmentStartcards: _.reduce(loadedDepartmentStartcards, (departmentStartcard, startcards, departmentId) => {
      const startcardMerged = _.unionBy(departmentStartcards[departmentId] ?? [], startcards, 'id');
      departmentStartcard[departmentId] = _.map(startcardMerged, (startcard) => ({
        ...startcard,
        isSelected: !!( _.find(startcards, ({id}) => id === startcard.id) )
      }));
      return departmentStartcard;
    }, {})
  })
);
export const LastVisitedUrlChanged = createAction(
  '[UI] Last Visited URL changed',
  (lastVisitedProjectId: string, lastVisitedPageUrl: string) => ({lastVisitedProjectId, lastVisitedPageUrl})
);

export const ResetProjectUiConfig = createAction(
  '[UI] Reset Project UI Config'
);
export const RetrieveAccountCodingSuccessful = createAction(
  '[UI] Retrieve AccountCoding successful',
  (accountCoding: AccountCodeConfig) => ({accountCoding})
);
export const RetrieveAccountCodeConfigFailed = createAction(
  '[UI] Retrieve Account Code Config Failed',
  (payload: string) => ({payload})
);
export const ChangeTimecardType = createAction(
  '[UI] Change Timecard Type',
  (timecardType: TimecardTypes) => ({timecardType})
);
