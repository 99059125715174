import * as _ from 'lodash';

import {CommonDetail, CrewDetail, ForceCallEnum, IPunch, MealPenaltyEnum, SagDetail, TimecardTypes, ValidationError, ValueError} from './timecard.model';
import {JobRates, Startcard, StartcardAccountCodeSegment} from './start-card.model';
import {PayCode} from './pay-code.model';
import {GLShortcutsViewModel} from './project-setting.model';

export type DtAccountCoding = DtAccountSplit | DtAccountCodes;
/**
 * DtsAccountCodes: {
 *   'CO' : '01',
     'MAJ': '001',
     'MIN': '007',
 * }
 * */
export interface DtAccountCodes {
  [header: string]: ValueError<string>;
}
export interface DtAccountSplit {
  [rowNumber: number]: DtsSplit;
}
export interface DtsSplit {
  splitType: 'BY_HOUR'|'BY_PERCENTAGE';
  accountCodes: {
    [header: string]: string;
  };
  mealPenalty: number;
  splitAmount: number; // This could be number of hours (e.g. 9) or percentage (20 as in 20%)
  glShortcutId: string;
}
export function IsSplitCoding(accountCoding: DtAccountCoding): accountCoding is DtAccountSplit {
  if (!accountCoding) {
    return true;
  }
  const splitRows: DtsSplit[]|string[] = _.values(<DtAccountSplit>accountCoding);
  if (_.isEmpty(splitRows)) {
    return false;
  }
  return !_.isUndefined(_.get(_.head(splitRows), 'splitType'));
}
export interface TaxJurisdiction {
  state: string;
  county: string;
  city: string;
  country: string;
}
/* Startcard */
export interface DtStartcard {
  startcardId: string;
  dailyTimeCrewJobId: string; // this ties to the DTS CrewJobId, UI doesn't need it but API does
  crewName: string;
  departmentName: string;
  departmentId: string;
  // groupNo: number; // WTF is this?
  crewType: string;
  ssn: string;
  fein: string;
  corpName: string;
  legacyEmployeeId: string;
  // Contract/Job
  clientNumber: number;
  contractId: string;
  contractProfileId: string;
  unionId: string;
  affiliationId: string;
  localId: string;
  unionCode: string;
  jobCode: string;
  scheduleCode: string;
  epCode: string;
  jobTitle: string;
  // Rate Info
  jobRates: JobRates;
  accountCoding: StartcardAccountCodeSegment[];
  workLocation: TaxJurisdiction;
  residentLocation: TaxJurisdiction;
  jobClassification: string;
  groupNo: number;
  vacationType: string;
  producerWorkWeek: string;
  holidayType: string;
  firstName: string;
  lastName: string;
}

export type DtPunch = IPunch;
export interface DtAllowance {
  allowanceId: string;
  allowanceType: string;  // this is Pay Type 2, but we still need a pay code right?
  payCode: PayCode;
  amount: string;
  checkCode: boolean;
  accountCodes: DtAccountCodes;
}
/**
 * BaseDailyTime
 * This interface represents what can be copied in DTS
 * Bulk Input Line shares the same fields as this as well as when
 * user use the Copy/Paste function, the system will transform the
 * Daily Time of a startcard to a DailyTimeCopySource then paste to
 * selected targets.
 * */
export interface BaseDailyTime {
  // punches
  punches: DtPunch;
  // Account Coding/Split Coding
  accountCoding: DtAccountCoding;
  // Allowances
  allowances: DtAllowance[];
  // Details
  offProduction: boolean;
  outZone: string;
  mealPenalty1: MealPenaltyEnum;
  mealPenalty2: MealPenaltyEnum;
  forceCall: ForceCallEnum;
  condition: ValueError<string>;
  nightPremium: boolean;
  payType: ValueError<string>;
  dayType1: ValueError<string>;
  dayType2: ValueError<string>;
  // Work Location: Tax Jurisdiction
  countryCode: ValueError<string>;
  stateCode: ValueError<string>;
  countyCode: ValueError<string>;
  cityCode: ValueError<string>;
  errors: ValidationError[];
  bulkGlShortcut?: GLShortcutsViewModel;
}
export interface DailyTime extends BaseDailyTime {
  isSelected: boolean; // for rendering checkbox in the UI, we may add this in the ViewModel if we need a vm.
  dailyTimeId: string; // UUID
  workDate: string; // MM-DD-YYYY
  startcard: Startcard;
  // Rerate
  rerateJobCode: ValueError<string>;
  rerateScheduleCode: ValueError<string>;
  rerateAmount: number;
  // Audit Trail
  createdBy: string;
  createdTimestamp: string;
  modifiedBy: string;
  modifiedTimestamp: string;
  glShortcutId: string;
}
export type DtCommonDetail = Omit<CommonDetail, 'shiftId'>;
export type DtWorkDetail = Omit<CrewDetail, 'shiftId'> | Omit<SagDetail, 'shiftId'>;
// TODO - Rename BaseDailyTimeNew and DailyTimeNew interface once crew and sag can use the same interface
export interface BaseDailyTimeNew {
  punches: IPunch;
  workDetails: DtWorkDetail;
  accountCoding: DtAccountCoding;
  allowances: DtAllowance[];
  errors: ValidationError[];
  bulkGlShortcut?: GLShortcutsViewModel;
  workStatuses?: ValueError<ValueError<string>[]>;
  stuntAdjustment?: ValueError<number>;
}
export interface DailyTimeNew extends BaseDailyTimeNew {
  isSelected: boolean;
  dailyTimeId: string; // UUID
  workDate: string; // MM-DD-YYYY
  startcard: Startcard;
  // Rerate
  rerateJobCode: ValueError<string>;
  rerateScheduleCode: ValueError<string>;
  rerateAmount: number;
  // Audit Trail
  createdBy: string;
  createdTimestamp: string;
  modifiedBy: string;
  modifiedTimestamp: string;
  glShortcutId: string;
  timecardType: TimecardTypes;
}
