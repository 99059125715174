import {Observable, of} from 'rxjs';
import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { ApiRoutes } from '../../../../common/api-routes';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ImportStartcardService {
  constructor(private http: HttpClient) {}

  ImportStartcards(projectId): Observable<boolean> {
    return this.http.post<boolean>(`${ApiRoutes.Startcards}/projects/${projectId}/importStartcards`, {});
  }

  ImportStartcard(projectId: string, startcardId: string): Observable<boolean> {
    return this.http.post<boolean>(`${ApiRoutes.Startcards}/projects/${projectId}/startcards/${startcardId}/importStartcard`, {})
      .pipe(catchError(() => of(true)));
  }

  ImportSelectedStartcards(projectId: string, selectedStartcardsIds: string[]): Observable<boolean> {
    return this.http.post<boolean>(`${ApiRoutes.Startcards}/projects/${projectId}/importSelectedStartcards`, selectedStartcardsIds )
      .pipe(catchError(() => of(true)));
  }
}
