import * as _ from 'lodash';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Store} from '@ngrx/store';
import {tap} from 'rxjs/operators';

import {getState} from '../utils';
import {WorkLocationViewModel} from '../../../../common/start-card.model';
import {selectTaxJurisdiction} from '../../selectors/lookup.selector';
import {RetrieveTaxJurisdictionSuccessful} from '../../actions/lookup.action';
import {TimecardLookupDataService} from '../data-services/timecard-lookup-data.service';
import {TaxJurisdiction} from '../../../../common/daily-time.model';

@Injectable({
  providedIn: 'root'
})
export class TaxJurisdictionLookupService {
  constructor(private _store: Store<any>,
              private _timecardLookupDataService: TimecardLookupDataService) {}
  private _cachedTaxJurisdiction(): WorkLocationViewModel[] {
    return getState<WorkLocationViewModel[]>(this._store, selectTaxJurisdiction);
  }
  RetrieveTaxJurisdiction(): Observable<WorkLocationViewModel[]> {
    // check if data is in the store
    const cachedTJ: WorkLocationViewModel[] = this._cachedTaxJurisdiction();
    // if so, return cached data
    if (!_.isEmpty(cachedTJ)) {
      return of(cachedTJ);
    }
    // otherwise, call the data service to retrieve
    return this._timecardLookupDataService.retrieveTaxJurisdictions().pipe(
      // and save it in the store
      tap((workLocations) => this._store.dispatch(RetrieveTaxJurisdictionSuccessful(workLocations)))
    );
  }
  PopulateCountryCode(taxJurisdiction: TaxJurisdiction): TaxJurisdiction {
    // if state, county and city are all blank ,return blank country as well
    if (_.isEmpty(taxJurisdiction.state) && _.isEmpty(taxJurisdiction.county) && _.isEmpty(taxJurisdiction.city)) {
      return {
        state: '',
        county: '',
        city: '',
        country: ''
      };
    }
    const cachedTJ: WorkLocationViewModel[] = this._cachedTaxJurisdiction();
    const targetTJ = _.find(cachedTJ, ({stateCode: {value: stateCode}, countyCode: {value: countyCode}, cityCode: {value: cityCode}}) =>
      stateCode === taxJurisdiction.state && countyCode === taxJurisdiction.county && cityCode === taxJurisdiction.city
    );
    if (_.isEmpty(targetTJ)) {
      return taxJurisdiction;
    }
    return {
      ...taxJurisdiction,
      country: targetTJ.countryCode.value
    };
  }
}
