import {Action, createReducer, on} from '@ngrx/store';
import * as _ from 'lodash';

import {LiteBrowseQueryParams} from '../../models/query-params.model';
import {FilterOption} from '../../shared/components/filter-panel/filter-item.model';
import {ProjectBrowseConstants} from '../../../../constants/project-browse.constants';
import {ProjectBrowseGrid} from '../models/project-browse.model';
import {
  EmptyProjects,
  GridColumnConfig,
  IncrementProjectSummaries,
  ProjectBrowseSort,
  ProjectBrowseToggleColumnVisibility,
  ProjectSummarySearchText,
  RemoveProjectBrowseFilter,
  RetrievedAllProjectSummaries,
  RetrieveProjects,
  RetrieveProjectSummarySuccessful,
  StudioLookup,
  UpdateProjectBrowseFilters
} from '../actions/project-browse.action';
import * as fromManageColumn from '../../shared/components/manage-column/manage-column.model';
import * as fromProjectBrowseActions from '../actions/project-browse.action';
import {ProjectSummary} from '../../../../common/project.summary.model';


export function ProjectReducer(state, action: Action) {
  return reducer(state, action);
}
export interface ProjectBrowseQueryParams extends LiteBrowseQueryParams {
  miscFilters?: FilterOption[];
}
export interface ProjectBrowseUI {
  columnConfig: ProjectBrowseGrid;
  studios: string[];
}
export interface ProjectSummaryState {
  projects: ProjectSummary[];
  total: number;
  projectSummaryQueryParams: ProjectBrowseQueryParams;
  isIncludeInActive: boolean;
  ui: ProjectBrowseUI;
  isLastProjectLoaded: boolean;
  selectedPaymasterManager?: FilterOption;
}
export const initialState: ProjectSummaryState = {
  projects: [],
  total: 0,
  projectSummaryQueryParams: {...ProjectBrowseConstants.ProjectBrowse.DefaultProjectQueryParams},
  isIncludeInActive: false,
  ui: null,
  isLastProjectLoaded: false,
  selectedPaymasterManager: null
};

const reducer = createReducer(
  initialState,
  on(GridColumnConfig, (state, {columnConfig}) => ({
      ...state,
      ui: {
        ...state.ui,
        columnConfig
      }
    }
  )),
  on(ProjectBrowseToggleColumnVisibility, (state, {columnLocation}) => {
    const columnName = _.get(columnLocation, 'column', null);
    const section = _.get(columnLocation, 'section', null);
    const sectionConfig: fromManageColumn.SectionColumnConfig = _.get(state, `ui.columnConfig.${section}.columns`, {});
    const isVisibleInStore = _.get(
      state,
      `ui.columnConfig.${section}.columns.${columnName}.isVisible`,
      true
    );
    const updatedColumnConfigs = _.mapValues(sectionConfig, columnConfig => ({
      ...columnConfig, isVisible: (columnConfig.field === columnName ? !isVisibleInStore : columnConfig.isVisible)
    }));
    const isShowAllColumns = _.every(updatedColumnConfigs, {isVisible: true});
    return {
      ...state,
      ui: {
        ...state.ui,
        columnConfig: {
          ...state.ui.columnConfig,
          [section]: {
            ...state.ui.columnConfig[section],
            isShowAllColumns,
            columns: updatedColumnConfigs
          }
        }
      }
    };
  }),
  on(RetrieveProjects, (state) => {
    const {filters = [], sort, searchText} = state.projectSummaryQueryParams;
    return {
      ...state,
      projectSummaryQueryParams: {
        ...initialState.projectSummaryQueryParams,
        searchText,
        filters,
        sort
      }
    };
  }),
  on(RetrieveProjectSummarySuccessful, (state, {total = state.total, projects}) => ({
      ...state,
      projects: [...(state.projects ?? []), ...projects],
      total
    }
  )),

  on(RetrievedAllProjectSummaries, (state) => ({
      ...state,
      isLastProjectLoaded: true
    }
  )),
  on(IncrementProjectSummaries, (state, {pageOffset}) => ({
      ...state,
      projectSummaryQueryParams: {
        ...state.projectSummaryQueryParams,
        offset: pageOffset
      }
    }
  )),
  on(ProjectSummarySearchText, (state, {searchText}) => ({
      ...state,
      projects: [],
      total: 0,
      projectSummaryQueryParams: {
        ...state.projectSummaryQueryParams,
        searchText,
        offset: 1
      }
    }
  )),
  on(ProjectBrowseSort, (state, {sort}) => ({
      ...state,
      projects: [],
      total: 0,
      projectSummaryQueryParams: {
        ...state.projectSummaryQueryParams,
        sort,
        offset: 1
      }
    }
  )),
  on(EmptyProjects, (state) => ({
    ...state,
    projects: [],
    isLastProjectLoaded: false
  })),
  on(UpdateProjectBrowseFilters, (state, {filterOption}) => {
    const {offset, pageSize} = initialState.projectSummaryQueryParams;
    const {filters} = state.projectSummaryQueryParams;
    const updateAt = _.findIndex(filters, {filterType: filterOption.type});
    return {
      ...state,
      projectSummaryQueryParams: {
        ...state.projectSummaryQueryParams,
        filters: _.epReplaceAt(filters, (updateAt === -1 ? filters?.length : updateAt), {
          filterType: filterOption.type,
          operator: '[eq]',
          value: [...filters[updateAt]?.value ?? [], filterOption.value]
        }),
        pageSize,
        offset
      }
    };
  }),
  on(RemoveProjectBrowseFilter, (state, {filterOption}) => {
    const {offset, pageSize} = initialState.projectSummaryQueryParams;
    return {
      ...state,
      projectSummaryQueryParams: {
        ...state.projectSummaryQueryParams,
        offset,
        pageSize,
        filters: _
          .chain(state.projectSummaryQueryParams.filters)
          .map(({filterType, operator, value}) => {
            const selectedValue: string[] = filterType === filterOption.type ? [filterOption.value] : [];
            return {
              filterType,
              operator,
              value: _.difference(value, selectedValue)
            };
          })
          .filter(({value}) => !_.isEmpty(value))
          .value()
      }
    };
  }),
  on(StudioLookup, (state, {studios}) => ({
      ...state,
      ui: {
        ...state.ui,
        studios
      }
    })
  ),
  on(fromProjectBrowseActions.ClearPaymasterFilters, (state) => {
    const {filters} = state.projectSummaryQueryParams;
    return {
      ...state,
      projectSummaryQueryParams: {
        ...state.projectSummaryQueryParams,
        offset: 1,
        filters: _.filter(filters, ({filterType}) => filterType !== 'paymasterInfo.paymasterId')
      },
      selectedPaymasterManager: null
    };
  }),
  on(fromProjectBrowseActions.AddPaymasterFilter, (state, {filterOption, paymasterId}) => {
    const {filters = []} = state.projectSummaryQueryParams;
    const updateAt = _.findIndex(filters, { 'filterType': filterOption.type });
    return {
      ...state,
      projectSummaryQueryParams: {
        ...state.projectSummaryQueryParams,
        offset: 1,
        filters: _.epReplaceAt(filters, (updateAt === -1 ? filters?.length : updateAt), {
          filterType: filterOption.type,
          operator: '[eq]',
          value: [...filters[updateAt]?.value ?? [], paymasterId]
        })
      }
    };
  }),
  on(fromProjectBrowseActions.AddPaymasterManagerFilter, (state, {selectedPaymasterManager}) => ({
    ...state,
    selectedPaymasterManager
  })),
  on(fromProjectBrowseActions.RemovePaymasterManagerFilter, (state, {filterOption}) => {
    const {filters} = state.projectSummaryQueryParams;
    return {
      ...state,
      projectSummaryQueryParams: {
        ...state.projectSummaryQueryParams,
        offset: 1,
        filters: _.filter(filters, ({filterType}) => filterType !== filterOption.type)
      },
      selectedPaymasterManager: null
    };
  }),
  on(fromProjectBrowseActions.RemovePaymasterFilters, (state, {filterOption}) => {
    const {filters} = state.projectSummaryQueryParams;
    return {
      ...state,
      projectSummaryQueryParams: {
        ...state.projectSummaryQueryParams,
        offset: 1,
        filters: _
          .chain(filters)
          .map(({filterType, operator, value}) => {
            const selectedValue: string[] = filterType === filterOption.type ? [filterOption.id] : [];
            return {
              filterType,
              operator,
              value: _.difference(value, selectedValue)
            };
          })
          .filter(({value}) => !_.isEmpty(value))
          .value()
      }
    };
  }),
  on(fromProjectBrowseActions.UpdatePaymastersFilter, (state, {filters}) => {
    return {
      ...state,
      projectSummaryQueryParams: {
        ...state.projectSummaryQueryParams,
        filters,
        offset: 1
      }
    };
  }),
  on(fromProjectBrowseActions.ClearAllFilters, (state) => ({
    ...state,
    selectedPaymasterManager: null,
    projectSummaryQueryParams: {
      ...state.projectSummaryQueryParams,
      offset: 1,
      filters: []
    },
    ui: {
      ...state.ui,
      studios: []
    }
  })),
);
