import {createAction} from '@ngrx/store';
import {Department} from '../../../../common/department-setting.model';

export const UpdateDepartmentsSuccessful = createAction(
  '[Project-department] Update Departments Successful',
  (departmentList: Department[]) => ({departmentList})
);

export const DeleteDepartmentClicked  = createAction(
  '[Project-department] Delete Department Clicked',
  (departmentId: string) => ({departmentId})
);
