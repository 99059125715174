import {createAction} from '@ngrx/store';
import {HttpErrorResponse, HttpEvent} from '@angular/common/http';

import {Pages} from '../models/ui.model';
import {DocumentMetadata} from '../../../common/document.model';
import {DocumentCategory, DocumentErrorTypes, DocumentValidationStatus} from '../models/document.model';

export const PrepareDocuments = createAction(
  '[Document Upload] Prepare documents To Upload',
  (files: File[], documentMetadata: DocumentMetadata[]) => ({files, documentMetadata})
);

export const EmptyDocuments = createAction(
  '[Document Upload] Empty documents'
);

export const RemoveDocument = createAction(
  '[Document Upload] Remove documents',
  (documentId: string) => ({documentId})
);

export const RejectUnsupportedDocument = createAction(
  '[Document Upload] Reject Unsupported Document',
  (file: File) =>
    ({file, errorType: DocumentErrorTypes.UNSUPPORTED_FILETYPE, category: DocumentCategory.NONE, status: DocumentValidationStatus.FAILED})
);

export const RejectDuplicateDocument = createAction(
  '[Document Upload] Reject Duplicate Document',
  (file: File) =>
    ({file, errorType: DocumentErrorTypes.DUPLICATE_FILE, category: DocumentCategory.NONE, status: DocumentValidationStatus.FAILED})
);

export const RejectLargeDocument = createAction(
  '[Document Upload] Reject Large Document',
  (file: File) =>
    ({file, errorType: DocumentErrorTypes.LARGE_FILE, category: DocumentCategory.NONE, status: DocumentValidationStatus.FAILED})
);

export const AcceptedDocument = createAction(
  '[Document Upload] Document passed validation',
  (file: File) => ({file, category: DocumentCategory.NONE, status: DocumentValidationStatus.PASSED})
);

export const UploadDocuments = createAction(
  '[Document Upload] Upload documents',
  (uploadServiceFunction: any, entityId: string, pageName: Pages) => ({uploadServiceFunction, entityId, pageName})
);

export const UploadRequestEvent = createAction(
  '[Document Upload] Track upload request event',
  (event: HttpEvent<any>, documentId: string) => ({event, documentId})
);

export const TrackDocumentUploadProgress = createAction(
  '[Document Upload] Track document upload Progress',
  (documentId: string, progress: number) => ({documentId, progress})
);

export const DocumentUploadSuccessful = createAction(
  '[Document Upload] Document upload successful',
  (documentId: string, documentMetadata: DocumentMetadata) => ({documentId, documentMetadata})
);

export const DocumentUploadError = createAction(
  '[Document Upload] Document upload error',
  (documentId: string, error: HttpErrorResponse, pageName: Pages) => ({documentId, error, pageName})
);

export const ChangeDocumentCategory = createAction(
  '[Document Upload] Document category change',
  (documentId: string, category: string) => ({documentId, category})
);

export const RetrieveUploadedDocumentSuccessful = createAction(
  '[Document Upload] Retrieve uploaded documents successful',
  (documents: DocumentMetadata[]) => ({documentsUploaded: documents})
);

export const UpdateDocumentMetadataSuccessful = createAction(
  '[Document Upload] Update uploaded Document Metadata',
  (documentMetadata: DocumentMetadata[]) => ({documentsUploaded: documentMetadata})
);

export const RetrieveDocumentFailed = createAction(
  '[Document Upload] Retrieve document failed',
  (documentId: string, errorResponse: HttpErrorResponse) => ({documentId, errorResponse})
);

export const InfectedDocumentDeleted = createAction(
  '[Document Upload] Infected document deleted',
  (documentId: string) => ({documentId})
);

export const DownloadDocumentFailed = createAction(
  '[Document Upload] Download document failed'
);

export const DocumentNotFound = createAction(
  '[Document Upload] Document not found'
);

export const DocumentScanStatusNotAvailable = createAction(
  '[Document Upload] Document scan status not available yet'
);
