import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import * as _ from 'lodash';

import {StartcardSummary} from '../../../common/startcard-summary.model';
import {ApiRoutes} from '../../../common/api-routes';

@Injectable({
  providedIn: 'root'
})
export class LoadDepartmentStartcardDataService {
  constructor(private _http: HttpClient) {}

  RetrieveDepartmentStartcards(projectId: string, departmentId: string, userType: string = ''): Observable<StartcardSummary[]> {
    if (_.isEmpty(departmentId)) {
      return of([]);
    }
    return this._http.get<StartcardSummary[]>(`${ApiRoutes.Startcards}/projects/${projectId}/department/${departmentId}?userType=${userType}`);
  }
}
