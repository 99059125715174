<div class="st-warning-modal">
  <div class="modal-header st-fs-20 st-font-os-sb" qaa="title">
    Create New Project
  </div>
  <div class="modal-body st-fs-14 st-font-os-r" qaa="body">
    <div class="row mb-3 pt-1">
      <div class="col-md-12">
        <mat-form-field class="w-100 mb-3" floatLabel="always">
          <mat-label>Client ID</mat-label>
          <input [formControl]="clientIdFormControl"
                 mask="0*"
                 name="clientId"
                 qaa="createProjectClientIdInput"
                 maxlength="8"
                 matInput>
          <button matSuffix class="btn st-btn-tertiary-sm st-cursor-pointer" qaa="createProject-FindProjectBtn"
                  [disabled]="!clientIdFormControl.value" *ngIf="!showLoadingSpinner"
                  (click)="searchProject(clientIdFormControl.value)">FIND PROJECT
          </button>
          <mat-spinner *ngIf="showLoadingSpinner" matSuffix diameter="14"></mat-spinner>
          <mat-error qaa="error-clientId-Required" *ngIf="clientIdFormControl.hasError('required')">
            Client ID is required
          </mat-error>
          <mat-error qaa="error-clientId-NotFound" *ngIf="clientIdFormControl.hasError('notFound')">
            Client ID not found
          </mat-error>
          <mat-error qaa="error-projectExists" *ngIf="clientIdFormControl.hasError('existing')">
            Project Already Exists
          </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" floatLabel="always" appearance="none">
          <mat-label>Production Title</mat-label>
          <input type="text"
                 name="productionTitle"
                 qaa="createProjectProductionTitleInput"
                 value="{{searchResult?.productionTitle || '-'}}"
                 readonly
                 matInput>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button qaa="createProjectCancelBtn" ngbAutofocus class="btn st-btn-tertiary-md" (click)="cancel.emit()">Cancel</button>
    <button (click)="onCreateProjectClicked(clientIdFormControl.value)"
            class="btn st-btn-primary-md me-0"
            qaa="createProjectNextBtn"
            [disabled]="clientIdFormControl.invalid || !searchResult?.productionTitle || searchResult?.isInFenix">
      Create
    </button>
  </div>
</div>
