<div>
  <div class="st-header">
    <div class="container-fluid">
      <div class="row st-header-content">
        <div class="st-logo-container col-auto">
          <img class="st-logo st-cursor-pointer" alt="logo" src="https://fenix-public-media-dev.s3-us-west-2.amazonaws.com/common/logo.svg" height="100%" routerLink="/">
        </div>
        <div class="col-auto ml-lg-3 mr-lg-3">
          <st-project-picker
            [projects]="projects | async"
            [selectedProject]="selectedProject"
            [itemsToShowCount]="4"
            [isPaymaster]="isPaymaster|async"
            [isPayrollTrackingEnabled]="isPayrollTrackingEnabled|async"
            (seeMoreClicked)="onSeeMoreProjectsClicked()"
            (selectedProjectChanged)="onSelectedProjectChange($event)">
          </st-project-picker>
        </div>
        <!--<div class="col-md-5 col-lg-4 ml-lg-5">
          <st-project-search></st-project-search>
        </div>-->
        <div class="col-auto ms-auto mb-2 mb-lg-0">
          <st-user-menu [firstName]="userFirstName | async"></st-user-menu>
        </div>
      </div>
    </div>
  </div>
  <st-loader></st-loader>
  <router-outlet></router-outlet>
</div>
