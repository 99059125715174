<div class="filter-container">
  <div class="header">
    <h3 class="left" qaa="filterTitle" [title]="title">{{ title }}</h3>
    <label class="right" qaa="filterAppliedCount">{{ selectedItems.length }} Applied</label>
  </div>
  <div class="content">
    <mat-form-field>
      <input
        id="filterInput"
        type="text"
        qaa="filterInput"
        placeholder="Search by {{title}}"
        tabindex="{{tabIndex}}"
        [(ngModel)]="searchString"
        (keyup)="onKeyUp()"
        [maxlength]="maximumSearchTextLength"
        (input)="onInput($event.target.value)"
        [focusFirst]="false"
        [ngbTypeahead]="ngbSearch"
        [inputFormatter]="formatter"
        [resultTemplate]="filterOptionsPicker"
        (focus)="inputFocus.next($event.target.value)"
        (click)="inputClick.next($event.target.value)"
        (selectItem)="onAddFilter($event)" matInput #instance="ngbTypeahead"/>
      <ng-template #filterOptionsPicker let-filterOptions="result" let-t="term">
        <div [ngSwitch]="filterOptions.type">
          <ng-container *ngSwitchCase="'batchNameNotAnalyzed'">
            <span [attr.qaa]="filterOptions.type+'-'+filterOptions.id">
              {{filterOptions.id === 'NONE' ? 'No Batch' : filterOptions.value}}
            </span>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <span [attr.qaa]="filterOptions.type+'-'+filterOptions.id">{{filterOptions.value}}</span>
            <span  *ngIf="filterOptions?.type === 'hasUnsubmittableErrors'">
              (<i class="unSubmittable" data-html="true"></i>)
            </span>
            <span *ngIf="filterOptions?.type === 'hasSubmittableErrors'" >
              (<i class="submittable" data-html="true"></i>)
            </span>
          </ng-container>
        </div>
      </ng-template>
      <a matSuffix class="mr-2"><i class="fas fa-search st-text-muted"></i></a>
    </mat-form-field>
  </div>
  <div class="selected-pills">
    <mat-chip-list>
      <mat-chip *ngFor="let item of selectedItems; let i =index; trackBy: trackByFn"
                [removable]="true" [attr.qaa]="'selectedFilter-'+i" [disabled]="item?.isDisabled">
        <span class="selected-pills-label" title="{{selectedFilterLabel(item)}}">{{selectedFilterLabel(item)}}</span>
        <mat-icon matChipRemove color="warn" qaa="cancelIcon" (click)="onRemoveFilter(item)">cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>
</div>
