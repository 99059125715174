import {ResolveFn} from '@angular/router';
import { inject } from '@angular/core';
import {catchError, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import { of } from 'rxjs';

import {RetrieveStartcardExportReportOptionFailed, RetrieveStartcardExportReportOptionSuccessful} from '../actions/lookup.action';
import {ExportReportOption} from '../../../common/report.model';
import {LookupDataService} from './lookup.data.service';

export const StartcardExportReportOptionResolver: ResolveFn<ExportReportOption[]> = () => {
    const store = inject(Store);
    return inject(LookupDataService)
        .RetrieveStartcardExportReportOptions()
        .pipe(
            catchError(() => {
                store.dispatch(RetrieveStartcardExportReportOptionFailed());
                return of(null);
            }),
            tap((reportOptions: ExportReportOption[]) => store.dispatch(RetrieveStartcardExportReportOptionSuccessful(reportOptions)))
        );
};
