import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { getParser } from 'bowser';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// chrome-only angular bootstrapping
const client = getParser(window.navigator.userAgent);
if (client.getBrowser().name === 'Chrome') {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err))
    .then(_ => console.log(`Bootstrap success`));
} else {
  document.querySelector('.download-chrome').classList.add('show-download-chrome');
}
