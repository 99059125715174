import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {switchMap} from 'rxjs/operators';
import * as _ from 'lodash';

import {getState, setSessionPOJO} from '../shared/utils';
import * as fromTimecardSummaryAction from '../timecard/actions/timecard-summary.action';
import * as fromStartcardSummary from '../startcard/actions/startcard-summary.action';
import * as fromApprovalBrowse from '../approvals/actions/approval-browse.action';
import * as fromUI from '../actions/ui.action';
import {IsValidUUID} from '../../../common/utilities/uuid';
import {selectProjectId} from '../core/selectors';
import {AutoApplyTimecardTemplate} from '../timecard/models/timecard-entry.view.model';
import {selectisContinueTcNavigationWithoutSaving, selectTimecardEntryDirtyStatus} from '../timecard/selectors/timecard-entry-ui.selector';
import {TimecardTemplateConstants} from '../../../constants/timecard-template.constants';
import {TimecardTypes} from '../../../common/timecard.model';

export const NAVIGATION_REQUEST = '@ngrx/router-store/request';

@Injectable()
export class RouterEffects {
  constructor(private actions: Actions, private _store: Store) {}

  routerEffect = createEffect(() => this.actions.pipe(
    ofType(NAVIGATION_REQUEST),
    switchMap(({payload}) => {
      const routingUrl = _.get(payload, 'event.url', '');
      const newProjectId = routingUrl.split('/')[2];
      const currentProjectId = getState<string>(this._store, selectProjectId);
      const isTimecardDirty = getState<string>(this._store, selectTimecardEntryDirtyStatus);
      const isContinueTcNavigationWithoutSaving = getState<string>(this._store, selectisContinueTcNavigationWithoutSaving);
      const timecardType = routingUrl.indexOf('/sag') > -1
        ? TimecardTypes.SAG
        : TimecardTypes.CREW;
      if (isTimecardDirty && !isContinueTcNavigationWithoutSaving) {
        return [];
      }
      if (IsValidUUID(newProjectId) && routingUrl.indexOf('/projects') === 0 && currentProjectId !== newProjectId) {
        setSessionPOJO<AutoApplyTimecardTemplate>(TimecardTemplateConstants.TimecardTemplate.autoApplySessionKey, {
          templateName: 'Default',
          isAutoApply: false
        });
        return [
          fromTimecardSummaryAction.Reset(),
          fromStartcardSummary.Reset(),
          fromApprovalBrowse.Reset(),
          fromUI.RepopulateColumnConfig(newProjectId),
          fromUI.SetTimecardTemplateAvailable(false),
          fromUI.ChangeTimecardType(timecardType)
        ];
      }
      if (routingUrl.indexOf('/paymasters') === 0) {
        return [
          fromUI.RepopulatePaymasterColumnConfig(),
          fromUI.ChangeTimecardType(timecardType)
        ];
      }
      return [fromUI.ChangeTimecardType(timecardType)];
    })
  ));
}
