import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AuthService } from './auth.service';
import { UserProfile } from '../../../../common/user-profile.model';
import { RetrieveUserSuccessful } from '../actions/auth';

@Injectable()
export class AppInitAuthService {
  constructor(private _authService: AuthService, private _store: Store<UserProfile>) {}
  initializeUserProfile(): Promise<UserProfile> {
    return new Promise<UserProfile>((resolve, reject) => {
      this._authService.retrieveUserAsPromise()
        .then((userProfile: UserProfile) => {
          this._store.dispatch(RetrieveUserSuccessful(userProfile));
          resolve(userProfile);
        })
        .catch((error) => {
          console.log(`[AppInitAuthService] - Failed to Retrieve User Profile`);
          window.location.href = '/auth/login';
        });
    });
  }
}
