import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import * as _ from 'lodash';
import {of} from 'rxjs';

import {
  IncrementProjectSummaries,
  ProjectBrowseSort,
  ProjectSummarySearchText,
  RetrievedAllProjectSummaries,
  RetrievedProjectSummaryNotFoundResponse,
  RetrieveProjects,
  RetrieveProjectSummarySuccessful,
  StudioFilterSearchText,
  StudioLookup,
} from '../actions/project-browse.action';
import {ProjectSummaryResponse} from '../../../../common/project.model';
import {selectProjectBrowseFilter, selectProjectBrowseQueryParams} from '../selectors/project-browse.selector';
import {ProjectDetailsDataService} from '../../shared/data-services/project-details.data.service';
import {LookupDataService} from '../../services/lookup.data.service';
import * as fromProjectBrowseAction from '../actions/project-browse.action';
import {getState} from '../../shared/utils';
import {FilterParams} from '../../models/query-params.model';
import {Paymaster} from '../../../../common/paymaster.model';
import {PaymasterBatchService} from '../../paymaster/services/paymaster-batch.service';
import {selectPayManagerLookUp} from '../../selectors/lookup.selector';
import {ProjectStatus, ProjectStudio} from '../models/project-browse.model';
import '../../../../common/lodash-mixins/replaceAt';

@Injectable()
export class ProjectBrowseEffect {
  constructor(private _store: Store<any>,
              private actions: Actions,
              private _projectDataService: ProjectDetailsDataService,
              private _lookupDataService: LookupDataService,
              private _paymasterBatchService: PaymasterBatchService,
              private store: Store<any>) {
  }
  incrementBrowsePage = createEffect(
    () => this.actions.pipe(
      ofType(
        IncrementProjectSummaries,
        RetrieveProjects,
        ProjectBrowseSort,
        ProjectSummarySearchText,
        fromProjectBrowseAction.UpdateProjectBrowseFilters,
        fromProjectBrowseAction.AddPaymasterFilter,
        fromProjectBrowseAction.AddPaymasterManagerFilter,
        fromProjectBrowseAction.RemoveProjectBrowseFilter,
        fromProjectBrowseAction.RemovePaymasterFilters,
        fromProjectBrowseAction.RemovePaymasterManagerFilter,
        fromProjectBrowseAction.ClearPaymasterFilters,
        fromProjectBrowseAction.ClearAllFilters,
      ),
      withLatestFrom(this._store.pipe(select(selectProjectBrowseQueryParams))),
      switchMap(([action, queryParams]) => {
        const {offset = 0, filters = []} = queryParams;
        const projectStudioFilterIndex = _.findIndex(filters, {filterType: 'studioNameNotAnalyzed'});
        const projectStudioFilter = _
          .chain(filters)
          .filter(item => item.filterType === 'studioNameNotAnalyzed')
          .flatMap('value')
          .map(studio => studio === ProjectStudio.NO_STUDIO ? 'NONE' : studio)
          .value();
        const projectStatusFilterIndex = _.findIndex(filters, {filterType: 'active'});
        const projectStatusFilter = _.map(_.find(filters, {filterType: 'active'})?.value, (status) => {
          if (_.toLower(status) === ProjectStatus.active) {
            return 'true';
          } else if (_.toLower(status) === ProjectStatus.inactive) {
            return 'false';
          }
        });
        if (projectStudioFilter.length > 0 && projectStudioFilterIndex > -1) {
          queryParams = {
            ...queryParams,
            filters: _.epReplaceAt(queryParams.filters, projectStudioFilterIndex, {
              filterType: 'studioNameNotAnalyzed',
              operator: '[eq]',
              value: projectStudioFilter
            })
          };
        }
        if (projectStatusFilter.length > 0 && projectStatusFilterIndex > -1) {
          queryParams = {
            ...queryParams,
            filters: _.epReplaceAt(queryParams.filters, projectStatusFilterIndex, {
              filterType: 'active',
              operator: '[eq]',
              value: projectStatusFilter
            })
          };
        }
        return this._projectDataService.RetrieveProjectSummary(queryParams).pipe(
          map((projectSummaryResponse: ProjectSummaryResponse) => {
            const {projectSummaries = [], total = 0} = projectSummaryResponse;
            if (projectSummaries.length) {
              return RetrieveProjectSummarySuccessful(projectSummaries, false, _.toNumber(total));
            }
            return offset <= 1
              ? RetrievedProjectSummaryNotFoundResponse()
              : RetrievedAllProjectSummaries();
          })
        );
      }))
  );
  studioFilterSearchText = createEffect(() => this.actions.pipe(
    ofType(StudioFilterSearchText),
    exhaustMap(({searchText}) => {
      if (searchText.length < 2) {
        return of(StudioLookup([]));
      }
      return this._lookupDataService.RetrieveStudios(_.toUpper(searchText))
        .pipe(
          map((response: string[]) => {
            return StudioLookup(response);
          }),
          catchError(() => {
            return of(StudioLookup([]));
          })
        );
    })
  ));
  emptyProjectBrowse = createEffect(() => this.actions.pipe(
    ofType(
      fromProjectBrowseAction.UpdateProjectBrowseFilters,
      fromProjectBrowseAction.AddPaymasterFilter,
      fromProjectBrowseAction.AddPaymasterManagerFilter,
      fromProjectBrowseAction.RemoveProjectBrowseFilter,
      fromProjectBrowseAction.RemovePaymasterFilters,
      fromProjectBrowseAction.RemovePaymasterManagerFilter,
      fromProjectBrowseAction.ClearPaymasterFilters,
      fromProjectBrowseAction.ClearAllFilters,
      fromProjectBrowseAction.ProjectSummarySearchText,
      fromProjectBrowseAction.ProjectBrowseSort
    ),
    switchMap(() => [fromProjectBrowseAction.EmptyProjects()])
  ));
  updatePaymastersFilteronAddPaymasterManager = createEffect(() => this.actions.pipe(
    ofType(
      fromProjectBrowseAction.AddPaymasterManagerFilter
    ),
    switchMap(({selectedPaymasterManager: filterOption}) => {
      return this._paymasterBatchService.RetrievePaymastersByManagerId(_.toNumber(filterOption.id)).pipe(
        switchMap((allPaymasters) => {
          const paymasterFilters = getState<FilterParams[]>(this.store, selectProjectBrowseFilter) ?? [];
          const paymastersId = _.reject(_.map(allPaymasters, (paymaster) => _.toString(paymaster.id)), _.isNil);
          const paymasterManagers = getState<Paymaster[]>(this.store, selectPayManagerLookUp);
          const paymasterManager = _.find(paymasterManagers, (paymaster: Paymaster) => paymaster?.id === _.toNumber(filterOption.id)) ?? {};
          paymastersId.push(_.toString(_.get(paymasterManager, `id`, '')));
          const indexAt = _.findIndex(paymasterFilters, { 'filterType': filterOption.type });
          const filteredParams: FilterParams[] = _.epReplaceAt(paymasterFilters, (indexAt === -1 ? paymasterFilters?.length : indexAt), {
            filterType: filterOption.type,
            operator: '[eq]',
            value: paymastersId
          });
          return [
            fromProjectBrowseAction.UpdatePaymastersFilter(filteredParams),
            fromProjectBrowseAction.RetrieveProjects()
          ];
        })
      );
    })
  ));
}
