import {inject} from '@angular/core';
import {Observable, of} from 'rxjs';
import { Store } from '@ngrx/store';
import {catchError, tap} from 'rxjs/operators';
import {ResolveFn} from '@angular/router';

import {RetrieveUnionFailed, RetrieveUnionSuccessful} from '../actions/lookup.action';
import {getState} from '../shared/utils';
import {selectProjectSettings} from '../auth/selectors';
import {ProjectSetting} from '../../../common/project-setting.model';
import {CMSDataService} from '../shared/data-services/cms-data.service';
import {FullUnion} from '../../../common/contract-rate.model';

export const UnionResolver: ResolveFn<FullUnion[]> = (): Observable<FullUnion[]> => {
  const cmsDataService: CMSDataService = inject(CMSDataService);
  const store: Store = inject(Store);
  const {clientId} = getState<ProjectSetting>(store, selectProjectSettings);
  return cmsDataService.RetrieveUnions(clientId).pipe(
    catchError(() => {
      store.dispatch(RetrieveUnionFailed());
      return of();
    }),
    tap((unions: FullUnion[]) => store.dispatch(RetrieveUnionSuccessful(unions)))
  );
};
