import * as _ from 'lodash';
import {Action, createReducer, on} from '@ngrx/store';

import {
    AddLoadedCrewsToDepartments,
    BrowsePageEmpty,
    BrowsePageRowGroupSelection,
    ChangeRemovedCrewStatus,
    ChangeTimecardType,
    ClearTabOrderMap,
    ClearUIColumnConfig,
    ContractAccountCodeInSplitModal,
    CrewGroupSelectStatus,
    CrewSelectStatus,
    DeleteToast,
    DepartmentSelected,
    ExpandAccountCodeInSplitModal,
    GridColumnConfig,
    HideLoadingScreen,
    InitializeTabOrder,
    LastVisitedUrlChanged,
    LoadDepartmentCrew,
    PaymasterGridColumnConfig,
    PaymasterToggleColumnVisibility,
    RepopulateColumnConfig,
    RepopulatePaymasterColumnConfig,
    ResetBrowsePageEmpty,
    ResetBrowsePageRowGroupSelection,
    ResetCrewsSelectedStatus,
    ResetProjectUiConfig,
    ResetTabOrderMap,
    RetrieveAccountCodingSuccessful,
    RetrieveStartcardsSuccessful,
    SetTimecardTemplateAvailable,
    ShowLoadingScreen,
    Toast,
    ToggleColumnVisibility,
    ToggleSectionVisibility,
    ToggleShowAllColumns
} from '../actions/ui.action';
import * as fromManageColumn from '../shared/components/manage-column/manage-column.model';
import * as uiModel from '../models/ui.model';
import {LoadCrew, LoadStartcards} from '../models/ui.model';
import * as TimecardEntryUiActions from '../timecard/actions/timecard-entry-ui.action';
import * as TimecardTemplateUiActions from '../template/timecards/actions/timecard-template-ui.action';
import {DisplayWorkLocationPickerForShift, DisplayWorkLocationPickerForSummary} from '../template/timecards/actions/timecard-template-ui.action';
import {WorkLocationPickerTargetEntity} from '../timecard-component/models/work-location-picker.view.model';
import {StartcardSummaryViewModel} from '../../../common/startcard-summary.model';
import '../../../common/lodash-mixins/titleCaseWithSpecialChar';
import {Department} from '../models/crew-department.model';
import {AccountCodeConfig} from '../../../common/project-setting.model';
import {
    AddSagDepartmentStartcard,
    ChangeRemovedPerformerStatus,
    PerformerGroupSelectStatus,
    PerformerSelectStatus,
    ResetPerformersSelectedStatus,
    RetrieveSagStartcardsSuccessful,
    SagDepartmentSelected
} from '../actions/sag-ui.action';
import {TimecardTypes} from '../../../common/timecard.model';
import {RetrieveUserDepartmentsSuccessful} from '../auth/actions/auth';

/**
 * Immutable Sorted Insert method
 * */
export function immutableSplice(arr, start, deleteCount, ...items) {
  return [...arr.slice(0, start), ...items, ...arr.slice(start + deleteCount)];
}

const initialTabOrderMapState = [];

// all expandable sections in modals can be added here
export type ModalSections = 'isAccountCodeExpanded';

export interface UiState {
  activeTimecardType: TimecardTypes;
  tabOrderMap: Array<number[]>;
  columnConfigs: fromManageColumn.ColumnConfigStore;
  toasts: uiModel.Toast[];
  isBrowsePageEmpty: boolean;
  browsePageRowGroupStatus: boolean;
  isWorkLocationPickerOpened: boolean;
  workLocationPickerTargetEntity?: WorkLocationPickerTargetEntity;
  isShowLoadingMask: boolean;
  projectUiConfigs: fromManageColumn.ProjectUiConfigs;
  isTimecardTemplateAvailable: boolean;
  modal: Record<ModalSections, boolean>;
  loadCrew: LoadCrew;
  loadStartcards: LoadStartcards;
  accountCoding: AccountCodeConfig;
  lastVisitedProjectId?: string;
  lastVisitedPageUrl?: string;
}

export const initialState: UiState = {
  activeTimecardType: null,
  tabOrderMap: [],
  columnConfigs: null,
  toasts: [],
  isBrowsePageEmpty: false,
  browsePageRowGroupStatus: false,
  isWorkLocationPickerOpened: false,
  isShowLoadingMask: false,
  projectUiConfigs: {},
  isTimecardTemplateAvailable: false,
  modal: {
    isAccountCodeExpanded: false,
  },
  loadCrew: {
    departmentList: [],
    departmentStartcards: {}
  },
  loadStartcards: {
    departmentList: [],
    departmentStartcards: {},
    sagDepartmentStartcards: {}
  },
  lastVisitedProjectId: '',
  lastVisitedPageUrl: '',
  accountCoding: null
};

export const reducer = createReducer(
  initialState,
  on(InitializeTabOrder, (state, {payload}) => (
    {
      ...state,
      tabOrderMap: immutableSplice(
        state.tabOrderMap,
        _.sortedIndexBy(state.tabOrderMap, payload, (item: number[]) => {
          // sort the relative tab index such that the Row get traversed first, then column, then component and finally section
          return ((item[0] + 1)) + ((item[1] + 1) * .01) + ((item[2] + 1) * .1) + ((item[3] + 1) * .0001);
        }),
        0,
       payload
      )
    }
  )),
  on(ClearTabOrderMap, (state) => ({
    ...state,
    tabOrderMap: initialTabOrderMapState
  }
  )),
  on(ResetTabOrderMap, (state, {payload}) => ({
      ...state,
      tabOrderMap: payload
    }
  )),
  on(RepopulateColumnConfig,
    RepopulatePaymasterColumnConfig, (state, {targetId}) => ({
    ...state,
    columnConfigs: _.get(state.projectUiConfigs, `${targetId}.columnConfigs`, null)
  })),
  on(GridColumnConfig,
    PaymasterGridColumnConfig,
    (state, {grid, gridConfig, targetId, timecardType}) => ({
      ...state,
      columnConfigs: {
        ...state.columnConfigs,
        [timecardType]: {
          ...(_.get(state, `columnConfigs.${timecardType}`, {})),
          [grid]: {
            ...(_.get(state, `columnConfigs.${timecardType}.${grid}`, {})),
            ...gridConfig
          }
        }
      },
      projectUiConfigs: {
        ...state.projectUiConfigs,
        [targetId]: {
          columnConfigs: {
            ...(_.get(state, `projectUiConfigs.${targetId}.columnConfigs`, {} as fromManageColumn.ColumnConfigStore)),
            [timecardType]: {
              ...(_.get(state, `projectUiConfigs.${targetId}.columnConfigs.${timecardType}`, {})),
              [grid]: {
                ...(_.get(state, `projectUiConfigs.${targetId}.columnConfigs.${timecardType}.${grid}`, {})),
                ...gridConfig
              }
            }
          }
        }
      }
    }
  )),
  on(ToggleColumnVisibility,
    PaymasterToggleColumnVisibility, (state, {columnLocation, targetId, timecardType}) => {
      const columnName = _.get(columnLocation, 'column', null);
      const section = _.get(columnLocation, 'section', null);
      const grid = _.get(columnLocation, 'grid', null);
    const sectionConfig: fromManageColumn.SectionColumnConfig = _.get(state, `columnConfigs.${timecardType}.${grid}.${section}.columns`, {});
    const isVisibleInStore = _.get(
      state,
      `columnConfigs.${timecardType}.${grid}.${section}.columns.${columnName}.isVisible`,
      true
    );
    const updatedColumnConfigs = _.mapValues(sectionConfig, columnConfig => ({
      ...columnConfig, isVisible: (columnConfig.field === columnName ? !isVisibleInStore : columnConfig.isVisible)
    }));
    const isShowAllColumns = _.every(updatedColumnConfigs, {isVisible: true});
      return {
        ...state,
        columnConfigs: {
          ...state.columnConfigs,
          [timecardType]: {
            ...(_.get(state, `columnConfigs.${timecardType}`, {})),
            [grid]: {
              ...(_.get(state, `columnConfigs.${timecardType}.${grid}`, {})),
              [section]: {
                ...(_.get(state, `columnConfigs.${timecardType}.${grid}.${section}`, {})),
                isShowAllColumns,
                columns: updatedColumnConfigs
              }
            }
          }
        },
        projectUiConfigs: {
          ...state.projectUiConfigs,
          [targetId]: {
            columnConfigs: {
              ...state.projectUiConfigs[targetId].columnConfigs,
              [timecardType]: {
                ...(_.get(state, `projectUiConfigs.${targetId}.columnConfigs.${timecardType}`, {} as fromManageColumn.ColumnConfigStore)),
                [grid]: {
                  ...(_.get(state, `projectUiConfigs.${targetId}.columnConfigs.${timecardType}.${grid}`, {})),
                  [section]: {
                    ...(_.get(state, `projectUiConfigs.${targetId}.columnConfigs.${timecardType}.${grid}.${section}`, {})),
                    isShowAllColumns,
                    columns: updatedColumnConfigs
                  }
                }
              }
            }
          }
        }
      };
    }),
  on(ClearUIColumnConfig, (state, {projectId}) => ({
    ...state,
    columnConfigs: null,
    projectUiConfigs: {
      ...state.projectUiConfigs,
      [projectId]: {
        columnConfigs: null
      }
    }
  })),
  on(ToggleShowAllColumns, (state, {section, grid, projectId, timecardType}) => ({
      ...state,
      columnConfigs: {
        ...state.columnConfigs,
        [timecardType]: {
          ...(_.get(state, `columnConfigs.${timecardType}`, {})),
          [grid]: {
            ...(_.get(state, `columnConfigs.${timecardType}.${grid}`, {})),
            [section]: {
              ...(_.get(state, `columnConfigs.${timecardType}.${grid}.${section}`, {})),
              isShowAllColumns: !_.get(state, `columnConfigs.${timecardType}.${grid}.${section}.isShowAllColumns`, false)
            }
          }
        }
      },
      projectUiConfigs: {
        ...state.projectUiConfigs,
        [projectId]: {
          columnConfigs: {
            ...state.columnConfigs,
            [timecardType]: {
              ...(_.get(state, `projectUiConfigs.${projectId}.columnConfigs.${timecardType}`, {} as fromManageColumn.ColumnConfigStore)),
              [grid]: {
                ...(_.get(state, `projectUiConfigs.${projectId}.columnConfigs.${timecardType}.${grid}`, {})),
                [section]: {
                  ...(_.get(state, `projectUiConfigs.${projectId}.columnConfigs.${timecardType}.${grid}.${section}`, {})),
                  isShowAllColumns: !_.get(state,
                    `projectUiConfigs.${projectId}.columnConfigs.${timecardType}.${grid}.${section}.isShowAllColumns`, false)
                }
              }
            }
          }
        }
      }
    }
  )),
  on(ToggleSectionVisibility, (state, {section, grid, projectId, timecardType}) => {
    const sectionConfig: fromManageColumn.SectionColumnConfig = _.get(state, `columnConfigs.${timecardType}.${grid}.${section}.columns`, {});
    const isAllColumnInSectionVisible = _.chain(sectionConfig)
      .findKey(config => !config.isVisible)
      .thru(hiddenField => hiddenField === undefined)
      .value();
    const updatedColumnConfigs = _.mapValues(sectionConfig, columnConfig => ({...columnConfig, isVisible: (!columnConfig.isMandatory ?
        !isAllColumnInSectionVisible : columnConfig.isVisible)}));
    const isShowAllColumns: boolean = _.every(updatedColumnConfigs, {isVisible: true});
    return {
      ...state,
      columnConfigs: {
        ...state.columnConfigs,
        [timecardType]: {
          ...(_.get(state, `columnConfigs.${timecardType}`, {})),
          [grid]: {
            ...(_.get(state, `columnConfigs.${timecardType}.${grid}`, {})),
            [section]: {
              ...(_.get(state, `columnConfigs.${timecardType}.${grid}.${section}`, {})),
              isShowAllColumns,
              columns: updatedColumnConfigs
            }
          }
        }
      },
      projectUiConfigs: {
        ...state.projectUiConfigs,
        [projectId]: {
          columnConfigs: {
            ...state.projectUiConfigs[projectId].columnConfigs,
            [timecardType]: {
              ...(_.get(state, `projectUiConfigs.${projectId}.columnConfigs.${timecardType}`, {} as fromManageColumn.ColumnConfigStore)),
              [grid]: {
                ...(_.get(state, `projectUiConfigs.${projectId}.columnConfigs.${timecardType}.${grid}`, {})),
                [section]: {
                  ...(_.get(state, `projectUiConfigs.${projectId}.columnConfigs.${timecardType}.${grid}.${section}`, {})),
                  isShowAllColumns,
                  columns: updatedColumnConfigs
                }
              }
            }
          }
        }
      }
    };
  }),
  on(Toast, (state, {toast}) => ({
      ...state,
      toasts: [...state.toasts, {...toast, id: _.uniqueId()}]
    }
  )),
  on(DeleteToast, (state, {id}) => ({
      ...state,
      toasts: state.toasts.filter(toast => toast.id !== id)
    }
  )),
  on(BrowsePageEmpty, (state, {isEmpty}) => ({
      ...state,
      isBrowsePageEmpty: isEmpty
    }
  )),
  on(ResetBrowsePageEmpty, (state) => ({
      ...state,
      isBrowsePageEmpty: false
    }
  )),
  on(BrowsePageRowGroupSelection, (state, {isChecked}) => ({
      ...state,
      browsePageRowGroupStatus: isChecked
    }
  )),
  on(ResetBrowsePageRowGroupSelection, (state) => ({
      ...state,
      browsePageRowGroupStatus: false
    }
  )),
  on(DisplayWorkLocationPickerForSummary,
      DisplayWorkLocationPickerForShift,
    TimecardEntryUiActions.DisplayWorkLocationPickerForShift,
    TimecardEntryUiActions.DisplayWorkLocationPickerForSummary, (state, {id ,  location }) => ({
        ...state,
        isWorkLocationPickerOpened: true,
        workLocationPickerTargetEntity: {
          ...state.workLocationPickerTargetEntity,
          entityId: id,
          type: location
        }
    }
  )),
  on(TimecardEntryUiActions.DisplayWorkLocationPickerForDefaultValue, (state, {location}) => ({
      ...state,
      isWorkLocationPickerOpened: true,
      workLocationPickerTargetEntity: {
        ...state.workLocationPickerTargetEntity,
        entityId: null,
        type: location
      }
    }
  )),
  on(TimecardTemplateUiActions.CloseWorkLocationPicker,
    TimecardEntryUiActions.CloseWorkLocationPicker, (state) => ({
        ...state,
        isWorkLocationPickerOpened: false,
        workLocationPickerTargetEntity: null
    }
  )),
  on(ShowLoadingScreen, (state) => ({
    ...state,
    isShowLoadingMask: true
  })),
  on(HideLoadingScreen, (state) => ({
    ...state,
    isShowLoadingMask: false
  })),
  on(SetTimecardTemplateAvailable, (state, {isTimecardTemplateAvailable}) => ({
    ...state,
    isTimecardTemplateAvailable
  })),
  on(ExpandAccountCodeInSplitModal, state => ({
    ...state,
    modal: {
      ...state.modal,
      isAccountCodeExpanded: true
    }
  })),
  on(ContractAccountCodeInSplitModal, state => ({
    ...state,
    modal: {
      ...state.modal,
      isAccountCodeExpanded: false
    }
  })),
  on(RetrieveUserDepartmentsSuccessful, (state, {departments}) => ({
    ...state,
    loadCrew: {
      ...state.loadCrew,
      departmentList: _.map(departments, ({id, name}) => {
        const departmentId = _.head(departments).id ?? '';
        return {
          id,
          name: _.epTitleCaseWithSpecialChar(name),
          isSelected: id === departmentId
        };
      })
    },
    loadStartcards: {
      ...state.loadStartcards,
      departmentList: _.map(departments, ({id, name}) => {
        const departmentId = _.head(departments).id ?? '';
        return {
          id,
          name: _.epTitleCaseWithSpecialChar(name),
          isSelected: id === departmentId
        };
      })
    }
  })),
  on(DepartmentSelected, (state, {departmentList}) => ({
    ...state,
    loadCrew: {
      ...state.loadCrew,
      departmentList
    }
  })),
  on(SagDepartmentSelected, (state, {departmentList}) => ({
    ...state,
    loadStartcards: {
      ...state.loadStartcards,
      departmentList
    }
  })),
  on(RetrieveStartcardsSuccessful, (state, {departmentId, startcards}) => ({
    ...state,
    loadCrew: {
      ...state.loadCrew,
      departmentStartcards: {
        ...state.loadCrew.departmentStartcards,
        [departmentId]: _.map(startcards, (startcard: StartcardSummaryViewModel) => {
          const departmentStartcard: StartcardSummaryViewModel = _.find(state.loadCrew.departmentStartcards[departmentId], { 'id' : startcard.id });
          return departmentStartcard ? {...startcard, isSelected: departmentStartcard.isSelected} : startcard;
        })
      }
    }
  })),
  on(RetrieveSagStartcardsSuccessful, (state, {departmentId, startcards}) => ({
    ...state,
    loadStartcards: {
      ...state.loadStartcards,
      sagDepartmentStartcards: {
        ...state.loadStartcards.sagDepartmentStartcards,
        [departmentId]: _.map(startcards, (startcard: StartcardSummaryViewModel) => {
          const sagDepartmentStartcard: StartcardSummaryViewModel = _.find(state.loadStartcards.sagDepartmentStartcards[departmentId], { 'id' : startcard.id });
          return sagDepartmentStartcard ? {...startcard, isSelected: sagDepartmentStartcard.isSelected} : startcard;
        })
      }
    }
  })),
  on(CrewSelectStatus,
    CrewGroupSelectStatus,
    ResetCrewsSelectedStatus,
    ChangeRemovedCrewStatus,
    (state, {departmentStartcards}) => ({
      ...state,
      loadCrew: {
        ...state.loadCrew,
        departmentStartcards
      }
  })),
  on(PerformerSelectStatus,
    PerformerGroupSelectStatus,
    ResetPerformersSelectedStatus,
    ChangeRemovedPerformerStatus,
    (state, {sagDepartmentStartcards}) => ({
      ...state,
      loadStartcards: {
        ...state.loadStartcards,
        sagDepartmentStartcards
      }
    })),
  on(AddLoadedCrewsToDepartments, (state, {firstDepartmentId, departmentStartcards}) => ({
    ...state,
    loadCrew: {
      departmentList: _.map(state.loadCrew.departmentList, (department: Department) => ({...department, isSelected: department.id === firstDepartmentId})),
      departmentStartcards
    }
  })),
  // reducer for the new ui slice - loadStartcards
  on(AddSagDepartmentStartcard, (state, {startcard}) => {
    // If there isn't any startcard in the selected department, then we just add the startcard to that department
    const selectedDeptStartcards = state.loadStartcards.sagDepartmentStartcards[startcard.departmentId];
    if (_.isUndefined(selectedDeptStartcards) || _.isEmpty(selectedDeptStartcards)) {
      return {
        ...state,
        loadStartcards: {
          ...state.loadStartcards,
          sagDepartmentStartcards: {
            ...state.loadStartcards.sagDepartmentStartcards,
            [startcard.departmentId]: [startcard]
          }
        }
      };
    }
    // Otherwise this could mean there is another startcard added individually or using load startcard panel.
    // If there selectedDeptStartcards already contains the startcard being added, we just need to flip the isSelected.
    // If the startcard doesn't exists yet, we need to add that to the selectedDeptStartcards list
    return {
      ...state,
      loadStartcards: {
        ...state.loadStartcards,
        sagDepartmentStartcards: {
          ...state.loadStartcards.sagDepartmentStartcards,
          [startcard.departmentId]: _.chain(selectedDeptStartcards).map('id').includes(startcard.id).value()
            ? _.map(selectedDeptStartcards, sc => (sc.id === startcard.id)
              ? {...sc, isSelected: true}
              : sc
            )
            : _.concat(selectedDeptStartcards, startcard)
        }
      }
    };
  }),
  on(LoadDepartmentCrew, (state, {startcard}) => {
    // If there isn't any startcard in the selected department, then we just add the startcard to that department
    const selectedDeptStartcards = state.loadCrew.departmentStartcards[startcard.departmentId];
    if (_.isUndefined(selectedDeptStartcards) || _.isEmpty(selectedDeptStartcards)) {
      return {
        ...state,
        loadCrew: {
          ...state.loadCrew,
          departmentStartcards: {
            ...state.loadCrew.departmentStartcards,
            [startcard.departmentId]: [startcard]
          }
        }
      };
    }
    // Otherwise this could mean there is another crew added individually or using load crew panel.
    // If there selectedDeptStartcards already contains the startcard being added, we just need to flip the isSelected.
    // If the startcard doesn't exists yet, we need to add that to the selectedDeptStartcards list
    return {
      ...state,
      loadCrew: {
        ...state.loadCrew,
        departmentStartcards: {
          ...state.loadCrew.departmentStartcards,
          [startcard.departmentId]: _.chain(selectedDeptStartcards).map('id').includes(startcard.id).value()
            ? _.map(selectedDeptStartcards, sc => (sc.id === startcard.id)
              ? {...sc, isSelected: true}
              : sc
            )
            : _.concat(selectedDeptStartcards, startcard)
        }
      }
    };
  }),
  on(LastVisitedUrlChanged, (state, {lastVisitedProjectId, lastVisitedPageUrl}) => ({
    ...state,
    lastVisitedProjectId,
    lastVisitedPageUrl,
  })),
  on(ResetProjectUiConfig, (state) => ({
    ...state,
    projectUiConfigs: {}
  })),
  on(RetrieveAccountCodingSuccessful, (state, {accountCoding}) => ({
    ...state,
    accountCoding
  })),
  on(ChangeTimecardType, (state, {timecardType: activeTimecardType}) => ({
    ...state,
    activeTimecardType
  }))
);

export function uiStateReducer(state = initialState, action: Action): UiState {
  return reducer(state, action);
}
