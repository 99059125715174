import {Component, Input, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';

import { GridColumn } from '../../../shared/components/grid-column-filter/grid-column-filter-view.model';
import {ProjectBrowseConstants} from '../../../../../constants/project-browse.constants';
import {ColumnVisibilityMap} from '../../../shared/components/manage-column/manage-column.model';
import {ProjectSummary} from '../../../../../common/project.summary.model';
import {getState} from '../../../shared/utils';
import {IncrementProjectSummaries} from '../../actions/project-browse.action';
import {selectIsAllProjectsLoaded, selectProjectBrowsePageOffset} from '../../selectors/project-browse.selector';
import {ProjectSummaryState} from '../../reducers/project.reducer';

@Component({
  selector: 'st-project-browse-grid',
  templateUrl: './project-browse-grid.component.html',
  styleUrls: ['./project-browse-grid.component.scss']
})
export class ProjectBrowseGridComponent {
  @Input() projects: Observable<ProjectSummary[]>;
  @Input() columns: GridColumn[];
  @Input() columnVisibility: ColumnVisibilityMap;
  @ViewChild('browseScroll', {static: true}) private _browseScroll: CdkVirtualScrollViewport;
  columnConfig = ProjectBrowseConstants.ProjectBrowse.ColumnMappings;
  scrollUpdate = new Subject<number>();
  trackByFn = (index, projectSummary) => projectSummary.projectId;
  constructor(private _store: Store<ProjectSummaryState>) {
    this.scrollUpdate.pipe(
      debounceTime(250),
      distinctUntilChanged(),
    ).subscribe(() => {
      const end = this._browseScroll.getRenderedRange().end;
      const total = this._browseScroll.getDataLength();
      const offset = getState<number>(this._store, selectProjectBrowsePageOffset);
      const isAllProjectLoaded = getState<boolean>(this._store, selectIsAllProjectsLoaded);
      if (end === total && !isAllProjectLoaded && end !== 0) {
        this._store.dispatch(IncrementProjectSummaries(offset + 1));
      }
    });
  }

  onScrollIndexChange(event: number) {
    this.scrollUpdate.next(event);
  }
}
