import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';

import {TimecardLookupData, TimecardTypes} from '../../../../common/timecard.model';
import {ApiRoutes} from '../../../../common/api-routes';

@Injectable({
  providedIn: 'root'
})
export class TimecardLookupService {

  constructor(private _http: HttpClient) { }

  retrieveTimecardLookupData(): Observable<TimecardLookupData> {
    const params = new HttpParams()
        // API Expect the timecardType = CREW
      .set('timecardType', TimecardTypes.CREW);
    return this._http.get<TimecardLookupData>(`${ApiRoutes.Timecard}/lookup`, {params});
  }

  retrieveSagTimecardLookupData(): Observable<TimecardLookupData> {
    const params = new HttpParams()
        // API Expect the timecardType = SAG
      .set('timecardType', TimecardTypes.SAG);
    return this._http.get<TimecardLookupData>(`${ApiRoutes.Timecard}/lookup`, {params});
  }
}
