import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {TimecardLookupData} from '../../../../common/timecard.model';
import {ApiRoutes} from '../../../../common/api-routes';
import {HttpClient} from '@angular/common/http';
import {WorkLocationViewModel} from '../../../../common/start-card.model';

@Injectable({
  providedIn: 'root'
})
export class TimecardLookupDataService {

  constructor(private http: HttpClient) { }
  retrieveTimecardLookupData(): Observable<TimecardLookupData> {
    return this.http.get<TimecardLookupData>(`${ApiRoutes.Timecard}/lookup`);
  }
  retrieveTaxJurisdictions(): Observable<WorkLocationViewModel[]> {
    return this.http.get<WorkLocationViewModel[]>(`${ApiRoutes.Tax}`);
  }
}
