import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiRoutes } from '../../../../../common/api-routes';
import { TimecardTemplate } from '../../../../../common/template.model';

@Injectable({
  providedIn: 'root'
})
export class TimecardTemplateService {

  constructor(private http: HttpClient) { }

  retrieveTemplate(projectId: string): Observable<TimecardTemplate> {
    return this.http.get<TimecardTemplate>(`${ApiRoutes.Projects}/${projectId}/templates/timecardTemplate/default`);
  }
  retrieveTemplateIsAvailable(projectId: string): Observable<TimecardTemplate> {
    return this.http.get<TimecardTemplate>(`${ApiRoutes.Projects}/${projectId}/templates/timecardTemplate`);
  }
  retrieveNewTemplate(projectId: string): Observable<TimecardTemplate> {
    return this.http.get<TimecardTemplate>(`${ApiRoutes.Projects}/${projectId}/templates/timecardTemplate/new`);
  }
  saveTimecardTemplate(timecardTemplate: TimecardTemplate): Observable<TimecardTemplate> {
    return this.http.put<TimecardTemplate>(`${ApiRoutes.Templates}/timecard`, timecardTemplate);
  }
}
