import * as _ from 'lodash';
import {createFeatureSelector, createSelector} from '@ngrx/store';

import {TimecardEntryState, TimecardFeatureState} from '../reducers';
import {ShiftConsequentialChanges, TimecardEntryUiState} from '../reducers/timecard-entry-ui.reducer';
import {Clipboard, PunchTab, Shift, SummaryLine} from '../models/timecard-entry.view.model';
import {AccountCodeConfig} from '../../../../common/project-setting.model';
import {CrewDetail, LiteTimecard, Punch, ShiftRate, SummaryPayDetail} from '../../../../common/timecard.model';
import {JobRates} from '../../../../common/start-card.model';
import {NoteType} from '../../../../common/timecard-note.model';

const  _selectDefaultShift = (state: TimecardEntryUiState) => state && state.defaultShift;
const _selectClipboard = (state: TimecardEntryUiState): Clipboard => state && state.clipboard;
const _selectClipboardShiftId = (state: Shift): string => state && state.shiftId;
const _selectClipboardShift = (state: Clipboard): Shift => state && state.shift;
const _selectClipboardSummaryLine = (state: Clipboard): SummaryLine => state && state.summaryLine;
const _selectClipboardSummaryLineId = (state: SummaryLine): string => state && state.summaryId;
const _selectTimecardFeatureState = createFeatureSelector<TimecardFeatureState>('timecard');
const _selectTimecardEntryState = (state: TimecardFeatureState) => state && state.timecardEntry;
const _selectTimecardEntryDirtyStatus = (state: TimecardEntryUiState): boolean => state ? state.isTimecardDirty : null;
const _selectAccountCodeConfig = (state: TimecardEntryUiState): AccountCodeConfig => state ? state.accountCodeConfig : null;
const _selectIsAssignBatchModalOpen = (state: TimecardEntryUiState): boolean => state ? state.isAssignBatchModalOpen : false;
const _selectIsTimecardSaving = (state: TimecardEntryUiState): boolean => state ? state.isTimecardSaving : false;
const _selectPrevTimecard = (state: TimecardEntryUiState): LiteTimecard => state ? state.prevTimecard : null;
const _selectNextTimecard = (state: TimecardEntryUiState): LiteTimecard => state ? state.nextTimecard : null;
const _selectChangedSummaryLineId = (state: TimecardEntryUiState): string => state ? state.changedSummaryLineId : null;
const _selectShiftConsequentialChanges = (state: TimecardEntryUiState): ShiftConsequentialChanges => state && state.shiftConsequentialChanges;
const _generateTimecardToolTipMessage = (timecard: LiteTimecard) =>  timecard.fein ?
  `${timecard.lastName}, ${timecard.firstName} (${timecard.fein})` :
  `${timecard.lastName}, ${timecard.firstName} (${timecard.ssn})`;
export const selectTimeCardEntryRootState  = createSelector(
  _selectTimecardFeatureState,
  _selectTimecardEntryState
);

export const selectTimecardEntryUIState = createSelector(
  selectTimeCardEntryRootState,
  (state: TimecardEntryState) => {
    return state ? state.ui : null;
  }
);

export const selectDefaultShift = createSelector(
  selectTimecardEntryUIState,
  _selectDefaultShift
);

export const selectClipboard = createSelector(
  selectTimecardEntryUIState,
  _selectClipboard
);

export const selectClipboardShift = createSelector(
  selectClipboard,
  _selectClipboardShift
);

export const selectIsShiftCopied = createSelector(
  selectClipboardShift,
  (state: Shift): boolean  => !!(state)
);

export const selectClipboardShiftId = createSelector(
  selectClipboardShift,
  _selectClipboardShiftId
);

export const selectTimecardEntryDirtyStatus = createSelector(
  selectTimecardEntryUIState,
  _selectTimecardEntryDirtyStatus
);

export const selectStartcardJobRates = createSelector(
  selectTimeCardEntryRootState,
  (state: TimecardEntryState): JobRates => _.get(state, 'timecard.startcard.jobRates', {studio: {}, location: {}})
);

export const selectDefaultSummaryLine = createSelector(
  selectTimecardEntryUIState,
  selectStartcardJobRates,
  (
    state: TimecardEntryUiState,
    {studio: {hourlyRate: hourlyStudioRate = 0}, location: {hourlyRate: hourlyLocationRate = 0}}: JobRates
  ) => {
    const payDetail: SummaryPayDetail|undefined = state.defaultSummaryLine?.payDetail;
    if (_.isUndefined(payDetail)) {
      throw new Error('Cannot find payDetail from defaultSummaryLine');
    }
    return {
      ...state.defaultSummaryLine,
      payDetail: {
        ...payDetail,
        hourlyRate: hourlyStudioRate ? hourlyStudioRate : hourlyLocationRate
      }
    };
  }
);

export const selectDefaultSummaryHourlyRate = createSelector(
  selectDefaultSummaryLine,
  ({payDetail: { hourlyRate = 0 }}: SummaryLine): number => hourlyRate
);

export const selectClipboardSummaryLine = createSelector(
  selectClipboard,
  _selectClipboardSummaryLine
);

export const selectIsAuditHistoryReportPanelOpened = createSelector(
  selectTimecardEntryUIState,
  (state: TimecardEntryUiState): boolean => state && state.isAuditHistoryReportPanelOpened
);

export const selectIsSummaryLineCopied = createSelector(
  selectClipboardSummaryLine,
  (state: SummaryLine): boolean => !!(state)
);

export const selectClipboardSummaryLineId = createSelector(
  selectClipboardSummaryLine,
  _selectClipboardSummaryLineId
);

export const selectAccountCodeConfig = createSelector(
  selectTimecardEntryUIState,
  _selectAccountCodeConfig
);

export const selectIsAssignBatchModalOpen = createSelector(
  selectTimecardEntryUIState,
  _selectIsAssignBatchModalOpen
);

export const selectIsTimecardSaving = createSelector(
  selectTimecardEntryUIState,
  _selectIsTimecardSaving
);

export const selectJobDetails = createSelector(
  selectTimecardEntryUIState,
  (state: TimecardEntryUiState) => state.jobDetails ? state.jobDetails : []
);

export const selectLoading = createSelector(
  selectTimecardEntryUIState,
  (state: TimecardEntryUiState) => state ? state.loading : false
);

export const selectPrevTimecard = createSelector(
  selectTimecardEntryUIState,
  _selectPrevTimecard
);

export const selectNextTimecard = createSelector(
  selectTimecardEntryUIState,
  _selectNextTimecard
);

export const selectChangedSummaryLineId = createSelector(
  selectTimecardEntryUIState,
  _selectChangedSummaryLineId
);

export const selectIsTimecardNotesPanelOpened = createSelector(
  selectTimecardEntryUIState,
  (state: TimecardEntryUiState) => state ? state.isTimecardNotesPanelOpened : false
);
export const selectIsResetTimecardNotes = createSelector(
  selectTimecardEntryUIState,
  (state: TimecardEntryUiState) => state ? state.isResetTcNotes : false
);
export const selectSelectedPunchTab = createSelector(
  selectTimecardEntryUIState,
  (state: TimecardEntryUiState) => state ? state.selectedPunchTab : PunchTab.Pay_Times
);
export const selectIsPayTimesTab = createSelector(
  selectTimecardEntryUIState,
  (state: TimecardEntryUiState) => state?.selectedPunchTab === PunchTab.Pay_Times ?? false
);

export const selectIsPayCodesFocused = createSelector(
  selectTimecardEntryUIState,
  (state: TimecardEntryUiState) => state ? state.isPayCodeFocused : false
);

export const selectSelectedNoteType = createSelector(
  selectTimecardEntryUIState,
  (state: TimecardEntryUiState) => state ? state.noteType : NoteType.ALL
);

export const selectShowRevisedStatus = createSelector(
  selectTimecardEntryUIState,
  (state: TimecardEntryUiState) => state ? state.isRevised : false
);

export const selectisContinueTcNavigationWithoutSaving = createSelector(
  selectTimecardEntryUIState,
  (state: TimecardEntryUiState) => state ? state.isContinueTcNavigationWithoutSaving : true
);
export const selectIsWtcReportPanelOpened = createSelector(
  selectTimecardEntryUIState,
  (state: TimecardEntryUiState): boolean => state && state.isWtcReportPanelOpenedInTcDetails
);

export const selectHasPreviousTimecard = createSelector(
  selectPrevTimecard,
  (timecard: LiteTimecard) => !_.isUndefined(timecard)
);

export const selectHasNextTimecard = createSelector(
  selectNextTimecard,
  (timecard: LiteTimecard) => !_.isUndefined(timecard)
);

export const selectIsPayCodesNotFocused = createSelector(
  selectTimecardEntryUIState,
  (state: TimecardEntryUiState) => !state?.isPayCodeFocused
);

export const selectShiftConsequentialChanges = createSelector(
  selectTimecardEntryUIState,
  _selectShiftConsequentialChanges
);

export const selectHasShiftConsequentialChanges = (shiftId: string, punch: Punch, shiftRate: ShiftRate, detail: CrewDetail) => createSelector(
  selectShiftConsequentialChanges,
  (shiftConsequentialChanges: ShiftConsequentialChanges): boolean => {
    // Since shiftConsequentialChanges slice is updated only when a Split is applied,
    // undefined shift information should trigger RetrieveSplitCodingBreakages API
    if (_.isUndefined(_.get(shiftConsequentialChanges, `${shiftId}`))) {
      return true;
    }
    return !_.isEqual(punch, _.get(shiftConsequentialChanges, `${shiftId}.punch`))
      || !_.isEqual(shiftRate, _.get(shiftConsequentialChanges, `${shiftId}.shiftRate`))
      || !_.isEqual(detail, _.get(shiftConsequentialChanges, `${shiftId}.detail`));
  }
);

export const selectPreviousTimecardToolTip = createSelector(
  selectPrevTimecard,
  (state: LiteTimecard) => state && state.timecardId ? _generateTimecardToolTipMessage(state) : ''
);
export const selectNextTimecardToolTip = createSelector(
  selectNextTimecard,
  (state: LiteTimecard) => state && state.timecardId ? _generateTimecardToolTipMessage(state) : ''
);
