import {Store} from '@ngrx/store';
import {getState} from '../../utils';
import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

import {selectProjectPermissions} from '../../../auth/selectors';
import {ProjectPermissions, ValidatePermission} from '../../../../../common/user-profile.model';

@Directive({
  selector: '[stPermissionToDisplay]'
})
export class PermissionToDisplayDirective {
  @Input() set stPermissionToDisplay(fn: ValidatePermission) {
    fn(getState<ProjectPermissions>(this._store, selectProjectPermissions))
      ? this._viewContainer.createEmbeddedView(this._templateRef)
      : this._viewContainer.clear();
  }
  constructor(private _store: Store<any>, private _templateRef: TemplateRef<any>, private _viewContainer: ViewContainerRef) { }
}
