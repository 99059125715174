import * as _ from 'lodash';
import {Action, createReducer, on} from '@ngrx/store';
import {CloseNgbModal, OpenNgbModal, ResetNgbModals} from '../actions/ngbModal.action';

export interface ModalState {
  ngbModals: {[key: string]: boolean};
  payloads: {[key: string]: any};
}
export const initialState: ModalState = {
  ngbModals: null,
  payloads: null
};
const reducer = createReducer(
  initialState,
  on(ResetNgbModals, (state) => ({
    ...state,
    ngbModals: {
      ..._.mapValues(state.ngbModals, () => false)
    }
  })),
  on(
    OpenNgbModal, CloseNgbModal,
    (state, {modalName, isModalOpen = false, payload = null}) => {
    return {
      ...state,
      ngbModals: {
        ...state.ngbModals,
        [modalName]: isModalOpen
      },
      payloads: {
        ...state.payloads,
        [modalName]: payload // Upon modal close, this gets reset to null again
      }
    };
  })
);
export function NgbModalReducer(state: ModalState, action: Action) {
  return reducer(state, action);
}
