import {TimecardTypes} from '../../../../../common/timecard.model';

export enum Sections {
  commonPunch = 'commonPunch',
  accountCoding = 'accountCoding',
  reRate = 'reRate',
  details = 'details',
  breakages = 'breakages',
  summaryBasics = 'summaryBasics',
  summaryAccountCode = 'summaryAccountCode',
  summaryJobDetail = 'summaryJobDetail',
  summaryPayDetail = 'summaryPayDetail',
  all = 'all',
  crew = 'crew',
  startcardInfo = 'startcardInfo',
  summary = 'summary',
  coding = 'coding',
  dailyTimes = 'dailyTimes',
  dayCostReport = 'dayCostReport',
  budgetedAmounts = 'budgetedAmounts'
}

export enum Grids {
  newTCTimeGrid = 'newTCTimeGrid',
  newTCSummaryGrid = 'newTCSummaryGrid',
  startcardBrowseGrid = 'startcardBrowseGrid',
  timecardBrowseGrid = 'timecardBrowseGrid',
  approvalBrowseGrid = 'approvalBrowseGrid',
  projectBrowseGrid = 'projectBrowseGrid',
  dailyTimeGrid = 'dailyTimeGrid',
  sagDailyTimeGrid = 'sagDailyTimeGrid',
  paymasterBatchBrowseGrid = 'paymasterBatchBrowseGrid',
  paymasterBatchDetailsTcGrid = 'paymasterBatchDetailsTcGrid',
  smartTimeUserGrid = 'smartTimeUserGrid',
  mobileUserGrid = 'mobileUserGrid',
  dayCostReportGrid = 'dayCostReportGrid'
}

export interface DailyTimeGrid {
  crew: SectionConfig;
  dailyTimes: SectionConfig;
  accountCoding: SectionConfig;
  reRate: SectionConfig;
  details: SectionConfig;
}

// Currently being used by sag daily time grid.
// We can use this same interface for both sag and crew.
export interface IDailyTimeGrid {
  startcardInfo: SectionConfig;
  dailyTimes: SectionConfig;
  accountCoding: SectionConfig;
  reRate: SectionConfig;
  details: SectionConfig;
}

export interface DayCostReportGrid {
  crew: SectionConfig;
  accountCoding: SectionConfig;
  budgetedAmounts: SectionConfig;
}

export interface ColumnLocation {
  grid: Grids;
  section: Sections;
  column: string;
}

export interface ColumnVisibilityMap {
  [section: string]: boolean;
}

// For popover
export interface ColumnGroupConfig {
  groupName?: string;
  section?: Sections;
  isShowAllColumns?: boolean;
  columns: ColumnConfig[];
  ordinal?: number;
}

// For Store
export type ColumnConfigStore = {
  [key in TimecardTypes]: {
    newTCTimeGrid: NewTCTimeGrid;
    newTCSummaryGrid: NewTCSummaryGrid;
    startcardBrowseGrid: StartcardBrowseGrid;
    timecardBrowseGrid: TimecardBrowseGrid;
    approvalBrowseGrid: ApprovalBrowseGrid;
    dailyTimeGrid: DailyTimeGrid;
    sagDailyTimeGrid: IDailyTimeGrid;
    paymasterBatchBrowseGrid: PaymasterBatchBrowseGrid;
    paymasterBatchDetailsTcGrid: PaymasterBatchDetailsTcGrid;
    smartTimeUserGrid: SmartTimeUserGrid;
    mobileUserGrid: MobileUserGrid;
    dayCostReportGrid: DayCostReportGrid;
  };
};

export interface NewTCTimeGrid {
  commonPunch?: SectionConfig;
  reRate?: SectionConfig;
  details?: SectionConfig;
  accountCoding?: SectionConfig;
}

export interface NewTCSummaryGrid {
  summaryBasics?: SectionConfig;
  summaryAccountCode?: SectionConfig;
  summaryJobDetail?: SectionConfig;
  summaryPayDetail?: SectionConfig;
}
export interface PaymasterBatchBrowseGrid {
  all: SectionConfig;
}
export interface PaymasterBatchDetailsTcGrid {
  all: SectionConfig;
}
export interface StartcardBrowseGrid {
  all: SectionConfig;
}
export interface TimecardBrowseGrid {
  all: SectionConfig;
}
export interface ApprovalBrowseGrid {
  crew: SectionConfig;
  details: SectionConfig;
  summary: SectionConfig;
}
export interface SmartTimeUserGrid {
  all: SectionConfig;
}
export interface MobileUserGrid {
  all: SectionConfig;
}
export interface SectionConfig {
  isShowAllColumns: boolean;
  groupName?: string;
  section?: string;
  columns: SectionColumnConfig;
}
export interface SectionColumnConfig {
  [fieldName: string]: ColumnConfig;
}

export interface ColumnConfig {
  field: string;
  header: string;
  isVisible: boolean;
  isMandatory: boolean;
  isOptionHidden?: boolean; // This flag identify whether it is visible on manage column
  ordinal: number;
}

export interface ProjectUiConfigs {
  [projectId: string]: {
    columnConfigs: ColumnConfigStore;
  };
}
