export namespace TimecardTemplateConstants {
  /**
   *  Todo use manageColumnConfigs when implementing manage column for Templates: https://jira.ep.com/browse/STF-1923
   */
  export class TimecardTemplate {
    public static readonly autoApplySessionKey = 'AutoApplyTimecardTemplate';

    public static get DetailsVisibilityMap() {
      return {
        offProd: true,
        workCondition: true,
        payType: true,
        dayType1: true,
        dayType2: true,
        mealPenalty1: true,
        mealPenalty2: true,
        forceCall: true,
        nightPremium: true,
        countryCode: true,
        outZone: true,
        stateCode: true,
        countyCode: true,
        cityCode: true,
        locFinder: true
      };
    }

    public static get PunchVisibilityMap() {
      return{
        call: true,
        ndb1Out: true,
        ndb1In: true,
        meal1Out: true,
        meal1In: true,
        ndb2Out: true,
        ndb2In: true,
        meal2Out: true,
        meal2In: true,
        wrap: true,
        last: true
      };
    }

    public static get SummaryBasicsVisibilityMap() {
      return {
        checkCode: true,
        state: true,
        county: true,
        locFinder: true,
        city: true,
        country: true,
        workDay: true,
        taxDay: true,
      };
    }

    public static get SummaryPayDetailsVisibilityMap() {
      return{
        payCode: true,
        payType1: true,
        payType2: true,
        payFactor: true,
        hourlyRate: true,
        hours: true,
        amount: true,
      };
    }
  }
}
