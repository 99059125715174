import * as _ from 'lodash';
import {select, Store} from '@ngrx/store';
import {AfterViewInit, ContentChild, Directive, ElementRef, Input, OnChanges, OnDestroy, Renderer2} from '@angular/core';
import {MatLegacySelect as MatSelect} from '@angular/material/legacy-select';
import {filter, takeWhile} from 'rxjs/operators';

import {getState} from '../../utils';
import {selectProjectPermissions} from '../../../auth/selectors';
import {ProjectPermissions, ValidatePermission} from '../../../../../common/user-profile.model';

type handlerFunc = () => void;
@Directive({
  selector: '[stPermissionToEnable]'
})
export class PermissionToEnableDirective implements OnChanges, AfterViewInit, OnDestroy {
  @ContentChild(MatSelect, {static: true}) matSelect: MatSelect;
  private _isAlive = true;
  private _permissionToEnable: ValidatePermission;
  private readonly toggleEnable: handlerFunc;
  @Input() set stPermissionToEnable(fn: ValidatePermission) {
    this._permissionToEnable = fn;
  }
  private readonly matSelectHandler: handlerFunc = () => {
    if (!this.matSelect) {
      return;
    }
    this.matSelect.disabled = !this._permissionToEnable(getState<ProjectPermissions>(this._store, selectProjectPermissions));
  }
  private readonly anchorHandler: handlerFunc = () => {
    !this._permissionToEnable(getState<ProjectPermissions>(this._store, selectProjectPermissions))
      ? this._renderer.addClass(this._el.nativeElement, 'disabled')
      : this._renderer.removeClass(this._el.nativeElement, 'disabled');
  }
  private readonly inputHandler: handlerFunc = () => {
    const permissionToDisable = !this._permissionToEnable(getState<ProjectPermissions>(this._store, selectProjectPermissions));
    this._renderer.setProperty(this._el.nativeElement, 'disabled', permissionToDisable);
  }
  private readonly standardHandler: handlerFunc = () => {
    this._el.nativeElement.disabled = !this._permissionToEnable(getState<ProjectPermissions>(this._store, selectProjectPermissions));
  }
  private readonly fieldsetHandler: handlerFunc = () => {
    const permissionToDisable = !this._permissionToEnable(getState<ProjectPermissions>(this._store, selectProjectPermissions));
    this._el.nativeElement.disabled = permissionToDisable;
    permissionToDisable
      ? this._renderer.setStyle(this._el.nativeElement, 'pointer-event', 'none')
      : this._renderer.setStyle(this._el.nativeElement, 'pointer-event', 'auto');
  }

  constructor(private _store: Store<any>, private _el: ElementRef, private _renderer: Renderer2) {
    const elementType = _.toUpper(_.get(this._el, 'nativeElement.tagName', 'UNKNOWN'));
    switch (elementType) {
      case 'A':
        this.toggleEnable = this.anchorHandler;
        break;
      case 'MAT-SELECT':
        this.toggleEnable = this.matSelectHandler;
        break;
      case 'INPUT':
        this.toggleEnable = this.inputHandler;
        break;
      case 'FIELDSET':
        this.toggleEnable = this.fieldsetHandler;
        break;
      default:
        this.toggleEnable = this.standardHandler;
        break;
    }
    // subscribe to permission change
    this._store.pipe(
      select(selectProjectPermissions),
      filter(() => !!this._permissionToEnable),
      takeWhile(() => this._isAlive),
    ).subscribe(() => {
      this.toggleEnable();
    });
  }
  ngOnDestroy(): void {
    this._isAlive = false;
  }

  // TODO: HACK - we are applying the toggleEnable TWICE because if we simply do it in ngAfterViewInit, it would throw the dreaded change after check exception
  ngOnChanges(): void {
    this.toggleEnable();
  }
  ngAfterViewInit(): void {
    this.toggleEnable();
  }
}
