import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {takeWhile} from 'rxjs/operators';

import {ProjectDetailsDataService} from '../../../shared/data-services/project-details.data.service';
import {ToastTypes} from '../../../models/ui.model';
import {ProjectSearch} from '../../../../../common/project.model';
import {ToastService} from '../../../shared/providers/toast.service';

@Component({
  selector: 'st-create-project-modal',
  templateUrl: './create-project-modal.component.html',
  styleUrls: ['./create-project-modal.component.scss']
})
export class CreateProjectModalComponent implements OnInit, OnDestroy {
  @Output() cancel: EventEmitter<null> = new EventEmitter<null>();
  @Output() create: EventEmitter<string> = new EventEmitter<string>();
  private _isAlive = true;
  clientIdFormControl = new UntypedFormControl('', { validators: [Validators.required]});
  searchResult: ProjectSearch;
  showLoadingSpinner = false;
  constructor(private _projectService: ProjectDetailsDataService, private _toastService: ToastService) {}

  ngOnInit() {
    this.clientIdFormControl?.valueChanges.pipe(
      takeWhile(() => this._isAlive)
    ).subscribe(workflowName => {
      this.searchResult = null;
    });
  }

  onCreateProjectClicked(clientId: string): void {
    this.create.emit(clientId);
  }

  ngOnDestroy() {
    this._isAlive = false;
  }

  toggleLoadingSpinner(): void {
    this.showLoadingSpinner = !this.showLoadingSpinner;
  }

  searchProject(clientId: string): void {
    this.toggleLoadingSpinner();
    this._projectService.SearchProjectByClientId(clientId).subscribe((response: ProjectSearch) => {
        this.searchResult = response;
        this.toggleLoadingSpinner();
        if (response.isInFenix) {
          this.clientIdFormControl.setErrors({existing: true});
        }
      },
      (err) => {
        this.toggleLoadingSpinner();
        if (err.status === 404) {
          this.clientIdFormControl.setErrors({notFound: true});
        } else {
          this._toastService.flash('searchProjectFail', ToastTypes.Error, 'Project search failed');
        }
      }
    );
  }

}
