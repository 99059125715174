import {
  ActionReducerMap,
  ActionReducer,
  MetaReducer,
} from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from '../../environments/environment';
import { RouterStateUrl } from '../shared/utils';
import * as fromRouter from '@ngrx/router-store';
import * as fromUi from '../reducers/ui.reducer';
import * as fromNgbModal from '../reducers/ngbModal.reducer';
import * as fromLookup from '../reducers/lookup.reducer';
import * as fromDocumentReducer from './document-upload.reducer';
import {DocumentUploadState} from './document-upload.reducer';

/**
 * Every reducers module's default export is the reducers function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducers plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */

// import * as fromLayout from '../core/reducers/layout';

/**
 * As mentioned, we treat each reducers like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  router: fromRouter.RouterReducerState<RouterStateUrl>;
  ui: fromUi.UiState;
  modal: fromNgbModal.ModalState;
  lookup: fromLookup.LookupState;
  documentUpload: DocumentUploadState;
}

/**
 * Our state is composed of a map of action reducers functions.
 * These reducers functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<State> = {
  router: fromRouter.routerReducer,
  ui: fromUi.uiStateReducer,
  modal: fromNgbModal.NgbModalReducer,
  lookup: fromLookup.LookupReducer,
  documentUpload: fromDocumentReducer.documentStateReducer
};

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return function(state: State, action: any): State {
    console.log('state', state);
    console.log('action', action);

    return reducer(state, action);
  };
}

// stores column config settings in local storage (default storage option)
export function sessionStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [
      {ui: ['tabOrderMap']},
      {auth: [{status: ['config']}]}
    ],
    rehydrate: true, storage: sessionStorage})(reducer);
}

// As per STF-17305, restoring the feature to store projectUiConfigs in the local storage
export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [{ui: ['projectUiConfigs']}],
    rehydrate: true, storage: localStorage})(reducer);
}

/**
 * By default, @ngrx/store uses combineReducers with the reducers map to compose
 * the root meta-reducers. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducers.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [logger, sessionStorageSyncReducer, localStorageSyncReducer]
  : [sessionStorageSyncReducer, localStorageSyncReducer];
