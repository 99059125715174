<div class="filter-container">
  <div class="header">
    <mat-form-field class="filter-dropdown" floatLabel="never" appearance="none">
      <select matNativeControl qaa="dropdownFilter"
              (change)="onFilterTypeChanged($event.target.value)">
        <option *ngFor="let titleOption of titleOptions;" [value]="titleOption.value" [selected]="filterType?.value === titleOption.value"
                [attr.qaa]="'dropdownFilter'+titleOption.value">{{titleOption.label}}</option>
      </select>
    </mat-form-field>
    <label class="applied-count" qaa="dropdownFilterAppliedCount">{{ selectedItems.length }} Applied</label>
  </div>
  <div class="content">
    <mat-form-field>
      <input
        id="dropdownFilterInput"
        type="text"
        qaa="dropdownFilterInput"
        [placeholder]="'Search by '+filterType?.label"
        tabindex="{{tabIndex}}"
        [(ngModel)]="searchString"
        (keyup)="onKeyUp()"
        [focusFirst]="false"
        [disabled]="disableInput"
        [ngbTypeahead]="ngbSearch"
        [inputFormatter]="formatter"
        [resultTemplate]="dropdownFilterOptionsPicker"
        (focus)="inputFocus.next($event.target.value)"
        (click)="inputClick.next($event.target.value)"
        (selectItem)="onAddFilter($event)" matInput #instance="ngbTypeahead"/>
      <ng-template #dropdownFilterOptionsPicker let-filterOptions="result" let-t="term">
        <div>
          <span [attr.qaa]="filterOptions.type+'-'+filterOptions.id">{{filterOptions.value}}</span>
        </div>
      </ng-template>
      <a matSuffix class="mr-2"><i class="fas fa-search st-text-muted"></i></a>
    </mat-form-field>
  </div>
  <div class="selected-pills">
    <mat-chip-list>
      <mat-chip *ngFor="let item of selectedItems; let i =index; trackBy: trackByFn"
                [removable]="true" [attr.qaa]="'selectedFilter-'+i">
        {{item.value}}
        <mat-icon matChipRemove color="warn" qaa="cancelIcon" (click)="onRemoveFilter(item)">cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>
</div>
