import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as _ from 'lodash';

import {PaymasterFeatureState} from '../reducers';
import {PaymasterLookupState} from '../reducers/paymaster-lookup.reducer';
import {Paymaster} from '../../../../common/paymaster.model';
import {PaymasterBatchConstants} from '../../../../constants/paymaster-batch.constants';
import {ProjectSummary} from '../../../../common/project.summary.model';

export const selectPaymasterFeatureState = createFeatureSelector<PaymasterFeatureState>('paymaster');

const selectPaymasterLookupRootState = createSelector(
  selectPaymasterFeatureState,
  (state: PaymasterFeatureState): PaymasterLookupState => state && state.lookupData
);

export const selectPaymasters = createSelector(
  selectPaymasterLookupRootState,
  (state: PaymasterLookupState): Paymaster[] => state && state.paymasters
);
export const selectPaymasterManagers = createSelector(
  selectPaymasterLookupRootState,
  (state: PaymasterLookupState): Paymaster[] => state && state.paymasterManagers
);
export const selectBatchStatus = createSelector(
  selectPaymasterLookupRootState,
  (state: PaymasterLookupState): string[] => state && state.batchStatus
);
export const selectPaymasterLookup = createSelector(
  selectPaymasters,
  (paymasters) => ({
    paymasters : _.map(paymasters, ({id, firstName, lastName}) => ({
      id: _.toString(id),
      value: `${lastName}, ${firstName}`,
      type: 'paymasters'
    }))
  })
);

export const selectPaymasterManagerLookup = createSelector(
  selectPaymasterManagers,
  (paymasters) => ({
    paymasters : _.map(paymasters, ({id, firstName, lastName}) => ({
      id: _.toString(id),
      value: `${lastName}, ${firstName}`,
      type: 'paymasters'
    }))
  })
);

export const selectPaymastersForRelease = createSelector(
  selectPaymasters,
  selectPaymasterManagers,
  (paymasters: Paymaster[], paymasterManagers: Paymaster[]) => {
    const defaultPaymaster = PaymasterBatchConstants.PaymasterBatch.DefaultBatchPaymasterForRelease;
    return _.uniqBy([defaultPaymaster, ...paymasters, ...paymasterManagers], 'id');
  }
);

export const selectProjectLookup = createSelector(
  selectPaymasterLookupRootState,
  (state: PaymasterLookupState): ProjectSummary[] => state && state.projects
);

export const selectInvoiceLookup = createSelector(
  selectPaymasterLookupRootState,
  (state: PaymasterLookupState): string[] => state && state.invoices
);
