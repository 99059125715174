import { Component, OnInit } from '@angular/core';
import { GridColumnComponent } from './base-grid-column-component';
import * as _ from 'lodash';
import fontawesome from '@fortawesome/fontawesome';
import { faStar } from '@fortawesome/fontawesome-free-solid';

@Component({
  selector: 'st-favorite-star-column',
  template: `<i [ngClass]="isFavorite ? 'fas fa-star' : 'far fa-star'"></i>`
})
export class FavoriteStarColumnComponent extends GridColumnComponent implements OnInit {
  isFavorite = false;
  ngOnInit() {
    fontawesome.library.add(faStar);
    this.isFavorite = _.get(this.context, this.configPayload.favorite, false);
  }
}
