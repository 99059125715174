import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, exhaustMap, map, switchMap} from 'rxjs/operators';
import {Store} from '@ngrx/store';

import {TimecardTemplate} from '../../../common/template.model';
import {
  LastVisitedUrlChanged,
  RetrieveDepartmentStartcards,
  RetrieveStartcardsSuccessful,
  RetrieveTemplateInfoByProjectId,
  SetTimecardTemplateAvailable
} from '../actions/ui.action';
import {TimecardTemplateService} from '../template/timecards/services/timecard-template.service';
import {getState} from '../shared/utils';
import {selectIsSagEnabled, selectSelectedProjectId} from '../auth/selectors';
import {StartcardSummary} from '../../../common/startcard-summary.model';
import {UserPreferences} from '../shared/data-services/user-preferences.data.service';
import {selectLastVisited} from '../selectors/ui.selectors';
import {RetrieveSagDepartmentStartcards, RetrieveSagStartcardsSuccessful} from '../actions/sag-ui.action';
import {LoadDepartmentStartcardDataService} from '../services/load-department-startcard-data.service';
import {TimecardTypes} from '../../../common/timecard.model';

@Injectable()
export class UiEffects {
  constructor(private _actions: Actions,
              private _timecardTemplateService: TimecardTemplateService,
              private _loadStartcardDataService: LoadDepartmentStartcardDataService,
              private _userPreference: UserPreferences,
              private _store: Store<TimecardTemplate>) {
  }
  retrieveTemplateInfo = createEffect(() => this._actions.pipe(
    ofType(RetrieveTemplateInfoByProjectId),
    exhaustMap(({projectId}) => {
      return this._timecardTemplateService.retrieveTemplateIsAvailable(projectId).pipe(
        map(() => SetTimecardTemplateAvailable(true)),
        catchError((error) => of(SetTimecardTemplateAvailable(false)))
      );
    })
  ));
  retrieveDepartmentStartcards = createEffect(() => this._actions.pipe(
    ofType(RetrieveDepartmentStartcards),
    switchMap(({departmentId}) => {
      const projectId = getState<string>(this._store, selectSelectedProjectId);
      if (!projectId) {
        throw new Error('ProjectId not found');
      }
      const userType: string = getState<boolean>(this._store, selectIsSagEnabled) ? TimecardTypes.CREW : '';
      return this._loadStartcardDataService.RetrieveDepartmentStartcards(projectId, departmentId, userType).pipe(
        map((departmentStartcards: StartcardSummary[]) => RetrieveStartcardsSuccessful(departmentId, departmentStartcards))
      );
    })
  ));
  retrieveSagDepartmentStartcards = createEffect(() => this._actions.pipe(
    ofType(RetrieveSagDepartmentStartcards),
    switchMap(({departmentId, userType}) => {
      const projectId = getState<string>(this._store, selectSelectedProjectId);
      if (!projectId) {
        throw new Error('ProjectId not found');
      }
      return this._loadStartcardDataService.RetrieveDepartmentStartcards(projectId, departmentId, userType).pipe(
        map((departmentStartcards: StartcardSummary[]) => RetrieveSagStartcardsSuccessful(departmentId, departmentStartcards))
      );
    })
  ));
  lastVisitedUrlChanged = createEffect(() => this._actions.pipe(
    ofType(LastVisitedUrlChanged),
    switchMap(({lastVisitedProjectId, lastVisitedPageUrl}) => {
      if (getState(this._store, selectLastVisited) !== lastVisitedPageUrl) {
        return this._userPreference.UpdateLastVisited(lastVisitedPageUrl, lastVisitedProjectId);
      }
    })),  {dispatch: false});
}
