import {createAction} from '@ngrx/store';

import {ProjectPermissions as _ProjectPermissions, UserProfile} from '../../../../common/user-profile.model';
import {Project} from '../../../../common/project.model';
import {ProjectSetting} from '../../../../common/project-setting.model';
import * as fromPaymaster from '../../../../common/paymaster.model';
import {ClientConfig} from '../../../../common/client-config.model';
import {Department} from '../../../../common/department-setting.model';

export const RetrieveUser = createAction(
  '[Auth] Retrieve User'
);

export const RetrieveUserSuccessful = createAction(
  '[Auth] Retrieve User Successful',
  (payload: UserProfile) => ({payload})
);

export const RetrieveUserFailed = createAction(
  '[Auth] Retrieve User Failed'
);

export const Logout = createAction(
  '[Auth] Logout'
);

export const RetrieveProjects = createAction(
  '[Project Browse] Retrieve Projects'
);

export const RetrieveProjectsSuccessful = createAction(
  '[Project Browse] Retrieve Projects Successful',
  (projects: Project[]) => ({projects})
);

export const RetrieveProjectsFailed = createAction(
  '[Project Browse] Retrieve Projects Failed'
);

export const ProjectPermissions = createAction(
  '[Project Permissions] Update Project Permissions',
  (projectPermissions: _ProjectPermissions) => ({projectPermissions})
);

export const ProjectSettings = createAction(
  '[Project Settings] Update Project Settings',
  (projectSettings: ProjectSetting) => ({projectSettings})
);

export const Paymaster = createAction(
  '[Paymaster] Update Paymaster',
  (paymaster: fromPaymaster.Paymaster) => ({paymaster})
);

// Client Config Actions
export const RetrieveClientConfigSuccessful = createAction(
  '[Auth] Client Config',
  (clientConfig: ClientConfig) => ({clientConfig})
);

export const SelectedProject = createAction(
  '[Auth] Selected Project',
  (selectedProject: Project) => ({selectedProject})
);

export const RetrieveUserDepartmentsSuccessful = createAction(
  '[Auth] Retrieve User Departments Successful',
  (departments: Department[]) => ({departments})
);

export const RetrieveVaEnableStatus = createAction(
  '[VA Status] Retrieve VA Enable Status',
  (clientId: number) => ({clientId})
);

export const RetrieveVaEnableStatusSuccess = createAction(
  '[API] Retrieve VA Enable Status Success',
  (responseData: boolean) => ({responseData})
);

export const RetrieveVaEnableStatusFailure = createAction(
  '[API] Retrieve VA Enable Status Failure'
);
