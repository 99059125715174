import * as _ from 'lodash';
import {createAction} from '@ngrx/store';

import {StartcardSummary, StartcardSummaryViewModel} from '../../../common/startcard-summary.model';
import * as fromUiModel from '../models/ui.model';
import {Department, DepartmentStartcards} from '../models/crew-department.model';


export const RetrieveSagStartcardsSuccessful = createAction(
  '[SAG UI] Retrieve SAG Startcard Successful',
  (departmentId: string, startcards: StartcardSummary[]) => {
    return ({
      startcards: _.map(startcards, (startcard) => ({...startcard, isSelected: false})),
      departmentId
    });
  }
);
export const PerformerSelectStatus = createAction(
  '[SAG UI] Performer Select Status',
  ({id, status}: fromUiModel.BrowsePageRowSelection, departmentId: string, startcards: DepartmentStartcards) => ({
    sagDepartmentStartcards: {
      ...startcards,
      [departmentId]: _.map(startcards[departmentId], (startcard) => startcard.id === id
        ? {...startcard, isSelected: status}
        : {...startcard}
      )
    }
  })
);
export const PerformerGroupSelectStatus = createAction(
  '[SAG UI] Performer Group Select Status',
  (status: boolean, departmentId: string, departmentStartcards: DepartmentStartcards, loadedCrewIds: string[]  ) => ({
    sagDepartmentStartcards: {
      ...departmentStartcards,
      [departmentId]: _.map(departmentStartcards[departmentId],
        (startcard: StartcardSummaryViewModel) => !_.includes(loadedCrewIds, startcard.id)
          ? {...startcard, isSelected: status}
          : {...startcard}
      )
    }
  })
);

export const ResetPerformersSelectedStatus = createAction(
  '[SAG UI] Reset Performers Selected Status',
  (departmentStartcards: DepartmentStartcards, loadedCrewIds: string[]) => ({
    sagDepartmentStartcards: _.reduce(departmentStartcards, (departmentStartcard, startcards, departmentId) => {
      departmentStartcard[departmentId] = _.map(startcards, startcard => ({...startcard, isSelected: _.includes(loadedCrewIds, startcard.id)}));
      return departmentStartcard;
    }, {})
  })
);
export const ChangeRemovedPerformerStatus = createAction(
  '[SAG UI] Change removed performer status',
  (sagDepartmentStartcards) => ({sagDepartmentStartcards})
);

export const SagDepartmentSelected = createAction(
  '[SAG UI] Select Department',
  (departmentId: string, departments: Department[]) => ({
    departmentList: _.map(departments,
      (department) => department.id === departmentId && !department.isSelected
        ? {...department, isSelected: true}
        : {...department, isSelected: false})
  })
);

export const RetrieveSagDepartmentStartcards = createAction(
  '[SAG UI] Retrieve Startcards for a Department',
  (departmentId: string, userType: string) => ({departmentId, userType})
);

export const AddSagDepartmentStartcard = createAction(
  '[SAG UI] Add startcard to department',
  (startcard: StartcardSummaryViewModel) => ({startcard})
);
