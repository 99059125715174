import {inject} from '@angular/core';
import {ResolveFn} from '@angular/router';
import {of} from 'rxjs';
import {Store} from '@ngrx/store';
import {catchError, tap} from 'rxjs/operators';

import {ExportReportOption} from '../../../common/report.model';
import {LookupDataService} from './lookup.data.service';
import {
  RetrievePayrollDistributionExportReportOptionFailed,
  RetrievePayrollDistributionExportReportOptionSuccessful
} from '../actions/lookup.action';

export const PayrollDistributionExportReportOptionResolver: ResolveFn<ExportReportOption[]> = () => {
  const store = inject(Store);
  return inject(LookupDataService).RetrievePayrollDistributionExportReportOptions().pipe(
      catchError(() => {
        store.dispatch(RetrievePayrollDistributionExportReportOptionFailed());
        return of(null);
      }),
      tap(reportOptions => store.dispatch(RetrievePayrollDistributionExportReportOptionSuccessful(reportOptions)))
  );
};
