import {createAction} from '@ngrx/store';
import * as _ from 'lodash';

import * as fromManageColumn from '../../shared/components/manage-column/manage-column.model';
import {ProjectBrowseGrid} from '../models/project-browse.model';
import {ProjectSummary} from '../../../../common/project.summary.model';
import {FilterOption} from '../../shared/components/filter-panel/filter-item.model';
import {FilterParams} from '../../models/query-params.model';
import '../../.././../common/lodash-mixins/replaceAt';

export const GridColumnConfig = createAction(
  '[Project Browse] Update Grid Column config ',
  (columnConfig: ProjectBrowseGrid) => ({columnConfig})
);

export const ProjectBrowseToggleColumnVisibility = createAction(
  '[Project Browse] Toggle column visibility state',
  (columnLocation: fromManageColumn.ColumnLocation) => ({columnLocation})
);
export const ProjectSummarySearchText = createAction(
  '[Project Browse] Update Project Browse Search Text',
  (searchText: string) => ({searchText})
);
export const ProjectBrowseSort = createAction(
  '[Project Browse] Sort',
  (sort: string) => ({sort})
);
export const RetrieveProjects = createAction(
  '[Project Browse] Retrieve projects'
);

export const IncrementProjectSummaries = createAction(
  '[Project Browse] Retrieve projectSummaries with offset',
  (pageOffset: number) => ({pageOffset})
);

export const RetrieveProjectSummarySuccessful = createAction(
  '[Project Browse] Retrieve projectSummaries successful',
  (projects: ProjectSummary[], browsePageRowSelection: boolean, total: number) => ({
    projects: _.map(projects,
      (projectSummary) => ({...projectSummary, isSelected: browsePageRowSelection})),
    total
  })
);

export const RetrievedAllProjectSummaries = createAction(
  '[Project Browse] Retrieved all projectSummaries'
);

export const RetrievedProjectSummaryNotFoundResponse = createAction(
  '[Project Browse] Retrieved Not found response'
);
export const StudioLookup = createAction(
  '[Project Browse] Studio Lookup',
  (studios: string[]) => ({studios})
);
export const EmptyProjects = createAction(
  '[Project Browse] Empty Project summaries'
);
export const UpdateProjectBrowseFilters = createAction(
  '[Project Browse] Update Project Browse Filters',
  (filterOption: FilterOption) => ({filterOption})
);
export const RemoveProjectBrowseFilter = createAction(
  '[Project Browse] Remove Project Browse Filter',
  (filterOption: FilterOption) => ({filterOption})
);
export const StudioFilterSearchText = createAction(
  '[Project Browse] Studio Filter Search Text changed',
  (searchText: string) => ({searchText})
);
export const AddPaymasterManagerFilter = createAction(
  '[Project Browse] Paymaster Manager Filter',
  (selectedPaymasterManager: FilterOption) => ({selectedPaymasterManager})
);
export const AddPaymasterFilter = createAction(
  '[Project Browse] Add Paymaster Filter',
  (filterOption: FilterOption, paymasterId: string) => ({filterOption, paymasterId})
);
export const RemovePaymasterManagerFilter = createAction(
  '[Project Browse] Remove Paymaster Manager Filter',
  (filterOption: FilterOption) => ({filterOption})
);
export const RemovePaymasterFilters = createAction(
  '[Project Browse] Remove Paymaster filter',
  (filterOption: FilterOption) => ({filterOption})
);
export const UpdatePaymastersFilter = createAction(
  '[Project Browse] Update Filter Paymaster under Paymanager',
  (filters: FilterParams[]) => ({filters})
);
export const ClearPaymasterFilters = createAction(
  '[Project Browse] Clear paymaster and Paymaster Manager Filters'
);
export const ClearAllFilters = createAction(
  '[Project Browse] Clear All Filters'
);
