import * as _ from 'lodash';
import {localStorageSync} from 'ngrx-store-localstorage';
import {Action, ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {InjectionToken} from '@angular/core';

import * as fromRoot from '../../reducers';
import {ProjectReducer, ProjectSummaryState} from './project.reducer';
import {INIT_ACTION, UPDATE_ACTION} from '../../shared/constants';

export interface ProjectFeatureState {
  projectBrowse: ProjectSummaryState;
}
export interface State extends fromRoot.State {
  project: ProjectFeatureState;
}
function getReducers() {
  return {projectBrowse: ProjectReducer};
}
export const ProjectBrowseReducerToken = new InjectionToken<ActionReducerMap<State>>('ProjectBrowseReducers');
export const ProjectBrowseReducerProvider = [{
  provide: ProjectBrowseReducerToken,
  useFactory: getReducers
}];
const mergeReducer = (state: ProjectSummaryState, rehydratedState: ProjectSummaryState, action: Action): ProjectSummaryState => {
  if ((action.type === INIT_ACTION || (action.type === UPDATE_ACTION && _.includes(action['features'], 'project')))
    && rehydratedState) {
    state = _.merge(state, rehydratedState);
  }
  return state;
};

export function sessionStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({keys: [{projectBrowse: ['projectSummaryQueryParams']}],
    rehydrate: true,
    storage: sessionStorage,
    mergeReducer
  })(reducer);
}

export const metaReducers: MetaReducer<State>[] = [sessionStorageSyncReducer];
