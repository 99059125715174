import * as _ from 'lodash';

const epReplaceAt = <A>(array: A[], index: number, replacement: A): A[] => {
  return array.slice(0, index).concat([replacement]).concat(array.slice(index + 1));
};

declare module 'lodash' {
  interface LoDashStatic {
    epReplaceAt<TValue>(array: TValue[], index: number, replacement: TValue): TValue[];
  }

  interface LoDashExplicitWrapper<TValue> {
    epReplaceAt(array: TValue[], index: number, replacement: TValue): LoDashExplicitWrapper<TValue[]>;
  }
}
_.mixin({epReplaceAt: epReplaceAt});
