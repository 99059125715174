import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, map, tap} from 'rxjs/operators';

import {AuthService} from '../services/auth.service';
import {
  RetrieveUserSuccessful,
  RetrieveProjectsSuccessful,
  RetrieveUser, Logout, RetrieveProjects,
  RetrieveVaEnableStatus,
  RetrieveVaEnableStatusSuccess, RetrieveUserDepartmentsSuccessful, RetrieveVaEnableStatusFailure
} from '../actions/auth';

@Injectable()
export class AuthEffects {

  getUser = createEffect(() => this.actions.pipe(
    ofType(RetrieveUser),
    exhaustMap(() => this.authService.retrieveUser()
      .pipe(
        catchError(error => this.authService.handleAuthError(error)),
        map(userProfile => RetrieveUserSuccessful(userProfile))
      ))
  ));

  logOut = createEffect(() => this.actions.pipe(
    ofType(Logout),
    tap(() => this.authService.logout())
  ), {dispatch: false});

  getProjects = createEffect(() => this.actions.pipe(
    ofType(RetrieveProjects),
    exhaustMap(() => this.authService.retrieveProjects()
      .pipe(
        map(projects => RetrieveProjectsSuccessful(projects))
      ))
  ));

  loadVaEnableStatus$ = createEffect(() =>
    this.actions.pipe(
      ofType(RetrieveVaEnableStatus),
      exhaustMap(({clientId}) =>
        this.authService.RetrieveVaConfig(clientId).pipe(
          map((response: boolean) => RetrieveVaEnableStatusSuccess(response))
        )
      )
    )
  );

  constructor(
    private actions: Actions,
    private authService: AuthService,
  ) {
  }

}
