import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as _ from 'lodash';

import {LookupState, TemplateLookup, TimecardTemplateLookup} from '../reducers/lookup.reducer';
import {PayCode, PayType1, PayType2Map} from '../../../common/pay-code.model';
import {WorkLocationViewModel} from '../../../common/start-card.model';
import {Department} from '../../../common/department-setting.model';
import {FullUnion, JobDetails, Schedule} from '../../../common/contract-rate.model';
import {TimecardTemplate} from '../../../common/template.model';
import {GLShortcutsViewModel} from '../../../common/project-setting.model';
import {Paymaster} from '../../../common/paymaster.model';
import {IWorkStatusCombinations} from '../../../common/work-status.model';
import {DayType, PayType, TimecardLookupData, TimecardTypes} from '../../../common/timecard.model';
import {ExportReportOption} from '../../../common/report.model';
import {selectRerateJobCodeValue} from '../daily-time/selectors/sag/daily-time.selector';
import {selectUserDepartments} from '../auth/selectors';

const _selectLookupRootState = createFeatureSelector<LookupState>('lookup');
export const selectPayType2Map = createSelector(
  _selectLookupRootState,
  (state: LookupState): PayType2Map => state && state.payType2Map
);

export const selectAllowanceOptions = createSelector(
  selectPayType2Map,
  (state: PayType2Map): PayCode[] => state ? state['ALLOWANCE'] : []
);

export const selectTaxJurisdiction = createSelector(
  _selectLookupRootState,
  (state): WorkLocationViewModel[] => state && state.taxJurisdiction
);

export const selectDepartments = createSelector(
  _selectLookupRootState,
  (state: LookupState): Department[] => state && state.departmentList
);

export const selectFirstDepartmentId = createSelector(
  selectDepartments,
  (state: Department[]): string => _.head(state).id ?? ''
);

export const selectUnionCodeDetails = createSelector(
  _selectLookupRootState,
  (lookup: LookupState ): FullUnion[] => lookup ? lookup.unions : []
);

export const selectPayType1List = createSelector(
  _selectLookupRootState,
  (state: LookupState): PayType1[] => state && state.payType1List
);

export const selectPayCodeByPayCodeNumber = (paycodeNumber: string) =>
  createSelector(
  _selectLookupRootState,
  (state: LookupState): PayCode => (state && _.find(state.payType2List, {paycodeNumber: paycodeNumber}))
    ?? null
);

export const selectGlShortcutList = createSelector(
  _selectLookupRootState,
  (state: LookupState): GLShortcutsViewModel[] => state ? state.glShortcutList : []
);

export const selectTemplateLookup = createSelector(
  _selectLookupRootState,
  (state: LookupState): TemplateLookup => state ? state.template : null
);

export const selectTimecardTemplateLookupState = createSelector(
  selectTemplateLookup,
  (state: TemplateLookup): TimecardTemplateLookup => state ? state.timecardTemplate : null
);

export const selectTimecardTemplateLookup = createSelector(
  selectTimecardTemplateLookupState,
  (state: TimecardTemplateLookup): TimecardTemplate => state && state.timecard
);

export const selectPayManagerLookUp = createSelector(
  _selectLookupRootState,
  (state: LookupState): Paymaster[] => state ? state.paymasterManagers : []
);

export const selectPayMasterLookUp = createSelector(
  _selectLookupRootState,
  (state: LookupState): Paymaster[] => state ? state.paymasters : []
);

export const selectWorkStatusCombination = createSelector(
  _selectLookupRootState,
  (state: LookupState): IWorkStatusCombinations => state && state.workStatusCombinations
);

export const selectTimecardLookupData = createSelector(
  _selectLookupRootState,
  (state: LookupState): {[key in keyof typeof TimecardTypes]?: TimecardLookupData} => state && state.timecardLookupData
);

export const selectSagPayTypeLookupData = createSelector(
  selectTimecardLookupData,
  (state: { [key in keyof typeof TimecardTypes]?: TimecardLookupData }): PayType[] | DayType[] => _.get(state, `SAG.payType`, [])
);
export const selectSagDayTypeLookupData = (workType: string) => createSelector(
  selectTimecardLookupData,
  (state: { [key in keyof typeof TimecardTypes]?: TimecardLookupData }): DayType[] => _.get(state, `SAG.${workType}`, [])
);

export const selectCrewPayTypeLookupData = createSelector(
  selectTimecardLookupData,
  (state: { [key in keyof typeof TimecardTypes]?: TimecardLookupData }): PayType[] => _.get(state, `CREW.payType`, [])
);
export const selectCrewDayType1LookupData = createSelector(
  selectTimecardLookupData,
  (state: { [key in keyof typeof TimecardTypes]?: TimecardLookupData }): DayType[] => _.get(state, `CREW.dayType1`, [])
);
export const selectCrewDayType2LookupData = createSelector(
  selectTimecardLookupData,
  (state: { [key in keyof typeof TimecardTypes]?: TimecardLookupData }): DayType[] => _.get(state, `CREW.dayType2`, [])
);

export const selectStartcardExportReportOptions = createSelector(
    _selectLookupRootState,
    (state: LookupState): ExportReportOption[] => state && state.startcardExportReportOptions
);

export const selectPayrollDepartmentExportReportOptions = createSelector(
    _selectLookupRootState,
    (state: LookupState): ExportReportOption[] => state && state.payrollDistributionExportReportOptions
);

export const selectJobDetails = (contractProfileId: string) => createSelector(
  _selectLookupRootState,
  (state: LookupState): JobDetails[] => state.jobDetails?.[contractProfileId] || []
);

export const selectScheduleDetails = (contractProfileId: string, startcardId: string) => createSelector(
  _selectLookupRootState,
  selectRerateJobCodeValue(startcardId),
  (state: LookupState, code: string): Schedule[] => {
    // Combine all the schedules from the jobDetails that matches the user selection. Because, in SAG union's the jobCodes are similar but multiple jobDetails
    // are present. So since we are not persisting the jobId, the schedules in other job details are not visible to user.
    return _
      .chain(state.jobDetails?.[contractProfileId] || [])
      .filter({code})
      .map((jobDetail: JobDetails) => jobDetail.schedules ?? [])
      .flatten()
      .uniqWith(_.isEqual)
      .value();
  }
);

// Todo: Need to move this selector to auth, will do in https://epglobal.atlassian.net/browse/STF-26495
// If we moved this selector to auth, after that some existing client test is not working, so we should find new solution and root cause of this issue.
export const selectHasAllDepartmentAccess = createSelector(
  selectDepartments,
  selectUserDepartments,
  (projectDepartments: Department[], userDepartments: Department[]): boolean => _.isEqual(projectDepartments, userDepartments)
);
