import {ResolveFn} from '@angular/router';
import { inject } from '@angular/core';
import {catchError, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import { of } from 'rxjs';

import {DepartmentSetting} from '../../../common/department-setting.model';
import {ProjectDetailsDataService} from '../shared/data-services/project-details.data.service';
import {getState} from '../shared/utils';
import {selectSelectedProjectId} from '../auth/selectors';
import {RetrieveUserDepartmentsSuccessful} from '../auth/actions/auth';

export const UserDepartmentsResolver: ResolveFn<DepartmentSetting> = () => {
  const store = inject(Store);
  const projectId = getState<string>(store, selectSelectedProjectId);
  return inject(ProjectDetailsDataService)
    .RetrieveUserDepartmentDetails(projectId).pipe(
      catchError((error) => of(error)),
      tap(({departments}: DepartmentSetting) => store.dispatch(RetrieveUserDepartmentsSuccessful(departments)) )
    );
};
