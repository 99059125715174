import {ApiDocumentProxyResponse} from '../../../common/document.model';

export interface DocumentViewModel {
  file: File;
  documentId: string;
  category: string;
  status: DocumentValidationStatus;
  errorType?: DocumentErrorTypes;
  uploadProgress?: number;
}

export interface DocumentResponse {
  displayName: string;
  documentDate: string;
  documentId: string;
  documentType: string;
  expirationDate?: null;
  fileType: string;
  metaData?: {
    [key: string]: string
  };
  sourceSystem: string;
  sourceSystemReferenceId: string;
  content: string;
}

export interface ApiDocumentResponse extends ApiDocumentProxyResponse {
  data: DocumentResponse[];
}

export enum DocumentValidationStatus {
  PASSED = 'Passed',
  FAILED = 'Failed',
  NONE = ''
}

export enum DocumentErrorTypes {
  UNSUPPORTED_FILETYPE = 'Unsupported Filetype',
  DUPLICATE_FILE = 'Duplicate File',
  LARGE_FILE = 'File larger than 150MB',
  UPLOAD_ERROR = 'File upload error'
}

export enum DocumentCategory {
  TIMECARD_DOCUMENTS= 'Timecard documents',
  RENTAL = 'Box / Computer Rental',
  MILEAGE_LOGS = 'Mileage Log',
  SALARY_DEDUCTION = 'Salary Deduction',
  NONE = ''
}

export enum FileIcon {
  PDF = 'far fa-file-pdf',
  IMAGE = 'far fa-file-image',
  EXCEL = 'far fa-file-excel',
  DOC = 'far fa-file'
}

export enum AllowedMimeTypes {
  TIFF = 'tiff',
  HEIC = 'heic',
  PNG = 'png',
  PDF = 'pdf',
  JPG = 'jpg',
  JPEG = 'jpeg',
  IMAGE_PNG = 'image/png',
  IMAGE_JPG = 'image/jpg',
  IMAGE_JPEG = 'image/jpeg',
  APPLICATION_PDF = 'application/pdf',
  DOC = 'application/doc',
  EXCEL = 'application/excel',
  X_EXCEL = 'application/x-excel',
  X_MSEXCEL = 'application/x-msexcel',
  VND_EXCEL = 'application/vnd.ms-excel',
  OPEN_XML = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}
