import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as _ from 'lodash';

import {BaseDailyTimeNew} from '../../../../../common/daily-time.model';
import {SagDailyTimeFeatureState} from '../../reducers/sag';
import {ValueError} from '../../../../../common/timecard.model';


const selectDailyTimeFeatureStateNew = createFeatureSelector<SagDailyTimeFeatureState>('dailyTimeSag');
const getBulkInputLineSliceNew = (state: SagDailyTimeFeatureState): Partial<BaseDailyTimeNew> => state?.bulkInputLine || {};

export const selectBulkInputLineNew = createSelector(
  selectDailyTimeFeatureStateNew,
  getBulkInputLineSliceNew
);

export const selectBulkInputAllowancesNew = createSelector(
  selectBulkInputLineNew,
  (state: Partial<BaseDailyTimeNew>) => state && state.allowances || []
);

export const selectBulkInputLinePunches = _.memoize((valuePath: string) => createSelector(
  selectBulkInputLineNew,
  (bulkInputLine: Partial<BaseDailyTimeNew>): ValueError<string> => _.get(bulkInputLine, `punches.${valuePath}`, {value: ''}))
);

export const selectBulkInputLineWorkDetails = _.memoize((valuePath: string) => createSelector(
  selectBulkInputLineNew,
  (bulkInputLine: Partial<BaseDailyTimeNew>): string|boolean => _.get(bulkInputLine, `workDetails.${valuePath}`, ''))
);

export const selectBulkInputLineWorkDetailsErrorValue = _.memoize((valuePath: string) => createSelector(
  selectBulkInputLineNew,
  (bulkInputLine: Partial<BaseDailyTimeNew>): ValueError<string> => _.get(bulkInputLine, `workDetails.${valuePath}`, {value: ''}))
);

export const selectBulkInputAccountCodingNew = createSelector(
  selectBulkInputLineNew,
  (state: Partial<BaseDailyTimeNew>) => state && state.accountCoding || {}
);
// selector for checkbox or boolean value in work details
export const selectIsBulkInputLineWorkDetailsCheckBoxChecked = _.memoize((valuePath: string) => createSelector(
  selectBulkInputLineNew,
  (bulkInputLine: Partial<BaseDailyTimeNew>): boolean => _.get(bulkInputLine, `workDetails.${valuePath}`, false))
);

export const selectBulkInputLineAccountCodes = _.memoize((valuePath: string) => createSelector(
  selectBulkInputLineNew,
  (bulkInputLine: Partial<BaseDailyTimeNew>): ValueError<string> => _.get(bulkInputLine, `accountCoding.${valuePath}`, {value: ''}))
);

export const selectBulkGlShortcutNew = createSelector(
  selectBulkInputLineNew,
  (bulkInputLine: Partial<BaseDailyTimeNew>) => bulkInputLine && bulkInputLine.bulkGlShortcut
);

export const selectBulkWorkStatus = createSelector(
  selectBulkInputLineNew,
  (bulkInputLine: Partial<BaseDailyTimeNew>): ValueError<ValueError<string>[]> =>
    !_.isEmpty(bulkInputLine?.workStatuses) ? bulkInputLine.workStatuses : {value : []}
);

export const selectBulkInputLineStuntAdjustment = createSelector(
  selectBulkInputLineNew,
  (bulkInputLine: Partial<BaseDailyTimeNew>): ValueError<number> =>
    !_.isEmpty(bulkInputLine?.stuntAdjustment) ? bulkInputLine.stuntAdjustment : null
);

