import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';

export namespace ModalConstants {
  export class Modal {
    public static get ModalConfigs(): NgbModalOptions {
      return {
        backdrop: 'static',
        keyboard: true
      };
    }
    public static get UploadTimecardModalConfigs(): NgbModalOptions {
      return {
        size: 'lg',
        windowClass: 'upload-modal',
        backdrop: 'static'
      };
    }
    public static get ReleaseModalConfigs(): NgbModalOptions {
      return {
        size: 'xl' as 'lg',
        windowClass: 'release-modal',
        backdrop: 'static'
      };
    }
    public static get AssignToBatchModalConfigs(): NgbModalOptions {
      return {
        size: 'lg',
        windowClass: 'timecard-to-batch-modal'
      };
    }
    public static get PunchDiscrepancyModalConfigs(): NgbModalOptions {
      return {
        size: 'xl',
        windowClass: 'punch-discrepancy-modal'
      };
    }
    public static get BreakageOverrideModalConfigs(): NgbModalOptions {
      return {
        size: 'lg',
        windowClass: 'breakage-override-modal',
        backdrop: 'static',
        keyboard: false
      };
    }
    public static get SummaryLineFilterModalConfigs(): NgbModalOptions {
      return {
        windowClass: 'summary-filter-modal',
        backdrop: 'static',
        keyboard: false
      };
    }
    public static get AllowancesModalConfigs(): NgbModalOptions {
      return {
        size: 'xl' as 'lg',
        windowClass: 'dt-allowances-modal'
      };
    }
    public static get WorkLocationModalConfigs(): NgbModalOptions {
      return {
        windowClass: 'work-location-picker-modal'
      };
    }
    public static get SplitCodingConfigs(): NgbModalOptions {
      return {
        size: 'xl' as 'lg',
        windowClass: 'split-coding',
        backdrop: 'static',
        keyboard: false
      };
    }
    public static get OverwriteSplitModalConfigs(): NgbModalOptions {
      return {
        size: 'sm',
        windowClass: 'split-handling-modal',
        backdrop: 'static',
        keyboard: false
      };
    }
    public static get LineChangesModalConfigs(): NgbModalOptions {
      return {
        size: 'xl' as 'lg',
        windowClass: 'line-history-modal',
        backdrop: 'static',
        keyboard: false
      };
    }
    public static get InviteMobileUserModalConfigs(): NgbModalOptions {
      return {
        centered: true,
        backdrop: 'static'
      };
    }
    public static get CreateProjectModalConfigs(): NgbModalOptions {
      return {
        centered: true,
        backdrop: 'static',
        keyboard: true
      };
    }
    public static get DuplicateBatchModalConfigs(): NgbModalOptions {
      return {
        size: 'lg',
        windowClass: 'duplicate-batch'
      };
    }
    public static get DuplicateMultipleBatchModalConfigs(): NgbModalOptions {
      return {
        size: 'lg',
        windowClass: 'duplicate-multiple-batch'
      };
    }
  }
}
