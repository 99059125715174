import {SectionConfig} from '../../shared/components/manage-column/manage-column.model';

export interface SearchTypeOption {
  name: string;
  value: string;
}
export interface ProjectBrowseGrid {
  all: SectionConfig;
}
export enum ProjectStatus {
  active = 'active',
  inactive = 'inactive'
}

export enum ProjectStudio {
  NO_STUDIO = 'No Studio'
}
