import * as _ from 'lodash';
import { Component, ComponentFactoryResolver, ViewContainerRef, Input, ViewChild, OnInit, ComponentRef, OnDestroy } from '@angular/core';
import { GridColumnComponent } from '../grid-column-components/base-grid-column-component';
import { DefaultGridColumnComponent } from '../grid-column-components/default-grid-column.component';

@Component({
  selector: 'st-grid-column-loader',
  template: `<ng-template #container></ng-template>`
})
export class GridColumnLoaderComponent implements OnInit, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef, static: true}) container: ViewContainerRef;
  // Type of the component to load
  @Input() type: string;
  // Context or Payload for the component to display
  @Input() context: any; // TODO: make this a special type
  @Input() config: any;
  private _componentRef: ComponentRef<{}>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit() {
    if (!this.type) {
      throw new Error('Grid Column Loader requires a Type');
    }
    const componentType = this._getComponentType(this.type);
    const factory = this.componentFactoryResolver.resolveComponentFactory(componentType);
    this._componentRef = this.container.createComponent(factory);

    const instance = <GridColumnComponent> this._componentRef.instance;
    instance.type = this.type;
    instance.context = this.context;
    instance.config = this.config;
  }
  ngOnDestroy() {
    if (this._componentRef) {
      this._componentRef.destroy();
      this._componentRef = null;
    }
  }
  private _getComponentType(typeName: string) {
    return _.get(this.config[typeName], 'component', null) || DefaultGridColumnComponent;
  }
}

