import {createAction} from '@ngrx/store';
import * as _ from 'lodash';
import {StartcardMobileInviteViewModel, StartcardStatus, StartcardSummary, StartcardSummaryViewModel} from '../../../../common/startcard-summary.model';
import { AccountCodeConfig } from '../../../../common/project-setting.model';
import {BrowsePageRowSelection} from '../../models/ui.model';
import {FilterOption} from '../../shared/components/filter-panel/filter-item.model';
import {ExportReportRequest} from '../../../../common/report.model';
import {Department} from '../../../../common/department-setting.model';

export const RetrieveStartcards = createAction(
  '[StartcardSummary] Retrieve startcards'
);

export const IncrementStartcardSummaries = createAction(
  '[StartcardSummary] Retrieve startcardSummaries with offset',
  (pageOffset: number) => ({pageOffset})
);

export const RetrieveStartcardSummarySuccessful = createAction(
  '[StarcardSummary] Retrieve startcardSummaries successful',
  (startcardSummaries: StartcardSummary[], browsePageRowSelection: boolean, total: number) => ({
    startcardSummaries: _.map(startcardSummaries,
      (startcardSummary) => ({...startcardSummary, isSelected: browsePageRowSelection})),
    total
  })
);

export const RetrievedAllStartcardSummaries = createAction(
  '[StarcardSummary] Retrieved all startcardSummaries'
);

export const RetrievedStartcardSummaryNotFoundResponse = createAction(
  '[StarcardSummary] Retrieved Not found response'
);

export const ExcludeInactive = createAction(
  '[StarcardSummary] exclude inactive startcard summaries',
  (filterOption: FilterOption) => ({filterOption})
);

export const IncludeInactive = createAction(
  '[StarcardSummary] include inactive startcard summaries',
  (filterOption: FilterOption) => ({filterOption})
);

export const HideMobileCrew = createAction(
  '[StarcardSummary] exclude mobile crew',
  (filterOption: FilterOption) => ({filterOption})
);

export const IncludeMobileCrew = createAction(
  '[StarcardSummary] include mobile crew',
  (filterOption: FilterOption) => ({filterOption})
);

export const StartcardSummarySearchText = createAction(
  '[StarcardSummary] Update Startcard Search Text',
  (searchText: string) => ({searchText})
);

export const ResetStartcardSummaries = createAction(
  '[StarcardSummary] Reset StartcardSummaries'
);

export const Reset = createAction(
  '[Startcard] Reset StartcardSummary'
);

export const RetrieveAccountCodeConfig = createAction(
  '[StarcardSummary] Retrieve Account Code Config'
);

export const RetrieveAccountCodeConfigSuccessful = createAction(
  '[StarcardSummary] Retrieve Account Code Config Successful',
  (accountCodeConfig: AccountCodeConfig) => ({accountCodeConfig})
);

export const RetrieveAccountCodeConfigFailed = createAction(
  '[StarcardSummary] Retrieve Account Code Config Failed',
  (payload: string) => ({payload})
);

export const InitializeColumnConfigs = createAction(
  '[StarcardSummary] Initialize manage column configs'
);

export const RowSelectStatus = createAction(
  '[StarcardSummary]  Update StartcardSummary row select status',
  (browsePageRowSelection: BrowsePageRowSelection) => ({browsePageRowSelection})
);

export const RowsSelectStatus = createAction(
  '[StarcardSummary]  Update StartcardSummary rows select status',
  (startcardSummaries: StartcardSummaryViewModel[]) => ({startcardSummaries})
);

export const StartcardSummarySelection = createAction(
  '[StartcardSummary] Update Startcard Summary selection',
  (status: boolean) => ({status})
);
export const OpenChangeReportPanel = createAction(
  '[StartcardSummary] Open Change Report Panel',
  (isReportPanelOpened: boolean) => ({isReportPanelOpened})
);
export const OpenMobileInvitePanel = createAction(
  '[StartcardSummary] Open Mobile Invite Panel'
);
export const CloseMobileInvitePanel = createAction(
  '[StartcardSummary] Close Mobile Invite Panel'
);
export const SendMobileInvites = createAction(
  '[StartcardSummary] Send Mobile Invite',
  (mobileCrewInvites: StartcardMobileInviteViewModel[]) => ({mobileCrewInvites})
);
export const MobileInviteStartcardSelection = createAction(
  '[StartcardSummary] Update Mobile Invite startcard list'
);
export const InviteEmail = createAction(
  '[StartcardSummary] Update Invite Email',
  (email: string, startcardId: string) => ({email, startcardId})
);
export const RemoveMobileCrewInvite = createAction(
  '[StartcardSummary] Remove Mobile Invite from list',
  (startcardId: string) => ({startcardId})
);
export const MobileCrewInviteFailed = createAction(
  '[StartcardSummary] Update mobileCrewsToInvite',
  (failedStartcardIds: { id: string }[]) => ({failedStartcardIds})
);

export const StartcardFilters = createAction(
  '[StartcardSummary] Update Startcard Filters',
  (filterOption: FilterOption) => ({filterOption})
);
export const RemoveFilter = createAction(
  '[StartcardSummary] Remove Startcard Filter',
  (filterOption: FilterOption) => ({filterOption})
);
export const ClearStartcardFilters = createAction(
  '[StartcardSUmmary] Clear All Startcard Filters',
  (userDepartments: Department[]) => ({userDepartments})
);
export const Sort = createAction(
  '[StartcardSummary] Sort',
  (sort: string) => ({sort})
);
export const UpdateStartcardStatus = createAction(
  '[StartcardSummary] Update Startcard Status',
  (status: StartcardStatus) => ({status})
);

export const OpenExportReportPanel = createAction(
    '[StartcardSummary] Open Export Report Panel'
);

export const CloseExportReportPanel = createAction(
    '[StartcardSummary] Close Export Report Panel'
);

export const ExportStartcardReport = createAction(
    '[StartcardSummary] Export Startcard Report',
    (exportReportPayload: ExportReportRequest) => ({exportReportPayload})
);
