import * as _ from 'lodash';
import {combineLatest, Observable, of} from 'rxjs';
import {inject} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {catchError, switchMap, take} from 'rxjs/operators';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';

import {AuthService} from './auth.service';
import {ProjectPermissions, ProjectSettings, SelectedProject} from '../actions/auth';
import {ProjectDetailsDataService} from '../../shared/data-services/project-details.data.service';
import {Permission, PermissionMap, PermissionType} from '../../../../common/user-profile.model';
import {State} from '../reducers';
import {selectIsLoggedIn, selectIsUserEnrollmentPending} from '../selectors';
import {getState} from '../../shared/utils';

export const AuthGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  router: Router = inject(Router),
  store: Store<State> = inject(Store),
  authService: AuthService = inject(AuthService),
  projectDetailsService: ProjectDetailsDataService = inject(ProjectDetailsDataService)
): Observable<boolean> => {
  const nextProjectId = next.params['projectId'];
  const { permissionsToCheck } = next?.data;
  return combineLatest(
    authService.retrieveLiteUserProfile(nextProjectId),
    store.pipe(
      select(selectIsLoggedIn),
      take(1)
    ),
    projectDetailsService.RetrieveProjectSettings(nextProjectId, projectSettings => projectSettings))
    .pipe(
      switchMap(([{projectPermissions, projectDetails}, isLoggedIn, projectSettings]) => {
        // dispatch action to persist user permission to Auth store
        store.dispatch(ProjectPermissions(projectPermissions));
        store.dispatch(SelectedProject(projectDetails));
        store.dispatch(ProjectSettings(projectSettings));
        const userPermissionSet: PermissionMap = projectPermissions?.permissions;
        const isUserEnrollmentPending = getState<boolean>(store, selectIsUserEnrollmentPending);

        if (isUserEnrollmentPending) {
          router.navigate([`/projects/${nextProjectId}/project-eula`]);
          return of(false);
        }
        if (!_.isEmpty(permissionsToCheck) && !_isRouteEnabled(permissionsToCheck, userPermissionSet)) {
          return of(false);
        }
        return of(isLoggedIn);
      }),
      catchError(() => {
        console.log(`Redirect to Login`);
        return of(false);
      })
    );
};

const _isRouteEnabled = (permissionsToCheck: PermissionType[], permissionSet: PermissionMap): boolean => {
  if (_.isEmpty(permissionSet)) {
    return false;
  }
  return _.some(permissionsToCheck, (permissionType: PermissionType) => permissionSet[permissionType] !== Permission.NONE);
};
