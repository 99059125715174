import * as _ from 'lodash';
import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {debounceTime, distinctUntilChanged, filter, map, takeWhile} from 'rxjs/operators';
import {NgbModal, NgbModalOptions, NgbModalRef, NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {Subject, Observable, Subscription} from 'rxjs';
import {Router} from '@angular/router';

import {GridColumn} from '../../../shared/components/grid-column-filter/grid-column-filter-view.model';
import {ProjectDetailsDataService} from '../../../shared/data-services/project-details.data.service';
import {ToastTypes} from '../../../models/ui.model';
import {getState, trackByFn} from '../../../shared/utils';
import {ToastService} from '../../../shared/providers/toast.service';
import {selectUserRole} from '../../../auth/selectors';
import * as fromAuthReducers from '../../../auth/reducers/index';
import {NgbModalService} from '../../../shared/providers/ngbModal.service';
import {CloseNgbModal, OpenNgbModal} from '../../../actions/ngbModal.action';
import {CreateProjectModalComponent} from '../../components/create-project-modal/create-project-modal.component';
import {InternalUserRole} from '../../../../../common/timecard.model';
import {NgbModalName} from '../../../shared/ngb-modal-name.enum';
import {ProjectBrowseConstants} from '../../../../../constants/project-browse.constants';
import {ModalConstants} from '../../../../../constants/modal.constants';
import {ColumnGroupConfig, ColumnLocation, ColumnVisibilityMap, Grids, Sections} from '../../../shared/components/manage-column/manage-column.model';
import {
  selectProjectSummary,
  selectAppliedProjectBrowseFilterCount,
  selectProjectBrowseAllGroupConfig,
  selectProjectBrowseSectionVisibility,
  selectProjectBrowseVisibleColumnCount,
  selectPaymanagerBrowseFilters,
  selectPaymasterBrowseFilters,
  selectProjectStatusFilter,
  selectProjectTypeFilter, selectSelectedProjectSort,
  selectStudioFilter, selectSelectedProjectSearchText
} from '../../selectors/project-browse.selector';
import {ProjectBrowseSort, ProjectBrowseToggleColumnVisibility, ProjectSummarySearchText} from '../../actions/project-browse.action';
import {ProjectSummary} from '../../../../../common/project.summary.model';
import {TextFilter} from '../../../shared/components/text-filter/text-filter.model';
import {FilterOption} from '../../../shared/components/filter-panel/filter-item.model';
import {DropdownFilter, FilterConfig, TitleOption} from '../../../shared/components/dropdown-filter/dropdown-filter.model';
import {selectSelectedPaymasterManager} from '../../../paymaster/selectors/paymaster-ui.selector';
import {PaymasterBatchConstants, PaymasterBatchFilterTypes} from '../../../../../constants/paymaster-batch.constants';
import {
  RemoveProjectBrowseFilter,
  StudioFilterSearchText,
  UpdateProjectBrowseFilters
} from '../../actions/project-browse.action';
import {Paymaster} from '../../../../../common/paymaster.model';
import * as fromProjectBrowseActions from '../../actions/project-browse.action';
import {selectPayMasterLookUp} from '../../../selectors/lookup.selector';

@Component({
  selector: 'st-project-browse',
  templateUrl: './project-browse.component.html',
  styleUrls: ['./project-browse.component.scss']
})
export class ProjectBrowseComponent implements OnInit, OnDestroy {
  @ViewChild('iSearch', {static: true}) iSearch: ElementRef;
  @ViewChild('manageColumnModal', {static: true}) public manageColumnModal: any;
  @ViewChild('createProjectModal', {static: true}) public createProjectModal: any;
  @ViewChild('filterPopover') public filterPopover: NgbPopover;
  projectBrowseStatusFilters: Observable<TextFilter> = this.store.pipe(
    select(selectProjectStatusFilter),
    takeWhile(() => this._isAlive),
  );
  projectTypeFilter: Observable<TextFilter> = this.store.pipe(
    select(selectProjectTypeFilter),
    takeWhile(() => this._isAlive),
  );
  studioFilters: Observable<TextFilter> = this.store.pipe(
    select(selectStudioFilter),
    takeWhile(() => this._isAlive),
  );
  timecardBrowseFilters: TextFilter[] = [];
  isPaymasterFilterSelected: boolean;
  paymasterFilters: DropdownFilter[] = [];
  trackByFn = trackByFn;
  columnVisibility = <ColumnVisibilityMap>{};
  grid = Grids;
  columnGroupConfig = new Observable<ColumnGroupConfig[]>();
  visibleColumnCount = new Observable<number>();
  selectedColumnList: GridColumn[] = [...ProjectBrowseConstants.ProjectBrowse.ExternalDefaultColumnList];
  isCreateNewButtonDisabled: boolean;
  isIncludeInactive = false;
  projectSearchTypes = ProjectBrowseConstants.ProjectBrowse.SearchTypes;
  searchText = new Subject<Event>();
  private readonly _createProjectModalName = NgbModalName.CREATE_PROJECT_MODAL;
  createProjectModalConfigs: NgbModalOptions = ModalConstants.Modal.CreateProjectModalConfigs;
  selectedSort: Observable<string> = this.store.pipe(
    select(selectSelectedProjectSort),
    takeWhile(() => this._isAlive)
  );
  searchString: Observable<string> = this.store.pipe(
    select(selectSelectedProjectSearchText),
    takeWhile(() => this._isAlive)
  );
  projects: Observable<ProjectSummary[]> = this.store.pipe(
    select(selectProjectSummary),
    takeWhile(() => this._isAlive),
  );
  dropDownFilterType: TitleOption = PaymasterBatchConstants.PaymasterBatch.DefaultPaymasterBatchFilter;
  appliedFilterCount: number;
  isFilterApplied: boolean;
  private _isAlive = true;
  private _modalRef: NgbModalRef;
  private _modalSubscription: Subscription[] = [];
  constructor(private store: Store<fromAuthReducers.State>,
              private ngbModal: NgbModal,
              private _toastService: ToastService,
              private _projectService: ProjectDetailsDataService,
              private router: Router,
              private _ngbModalService: NgbModalService) {
    this.searchText.pipe(
      debounceTime(200),
      map((event: any) => event.target.value),
      filter(({length}) => length > 1 || length === 0),
      distinctUntilChanged(),
      takeWhile(() => this._isAlive),
    ).subscribe((searchText: string) => {
      this.store.dispatch(ProjectSummarySearchText(searchText));
    });
  }

  ngOnInit() {
    this.store.pipe(
      select(selectAppliedProjectBrowseFilterCount),
      takeWhile(() => this._isAlive)
    ).subscribe(totalFiltersApplied => {
      this.appliedFilterCount = totalFiltersApplied;
      this.isFilterApplied = totalFiltersApplied !== 0;
    });
    this.store.pipe(
      select(selectProjectBrowseSectionVisibility(Sections.all)),
      takeWhile(() => this._isAlive)
    ).subscribe(v => this.columnVisibility = v);

    this.columnGroupConfig = this.store.pipe(
      select(selectProjectBrowseAllGroupConfig),
      takeWhile(() => this._isAlive)
    );
    this.visibleColumnCount = this.store.pipe(
      select(selectProjectBrowseVisibleColumnCount),
      takeWhile(() => this._isAlive)
    );
    this.store.pipe(
      select(selectUserRole),
      takeWhile(() => this._isAlive)
    ).subscribe((userRole) => this.isCreateNewButtonDisabled =
      _.toUpper(userRole) !== InternalUserRole.PROJECT_SETUP && _.toUpper(userRole) !== InternalUserRole.PROJECT_SETUP_MANAGER);

    this._modalSubscription.push(
      this._ngbModalService.addModalListener(this._createProjectModalName, CreateProjectModalComponent, this.createProjectModalConfigs)
    );

    this._ngbModalService.modalComponentInstance<CreateProjectModalComponent>(this._createProjectModalName).pipe(
      filter((modalInstance: CreateProjectModalComponent) => !!modalInstance),
      takeWhile(() => this._isAlive)
    ).subscribe(modalComponent => {
      modalComponent.cancel.pipe(
        takeWhile(() => this._isAlive)
      ).subscribe(() => {
        this.store.dispatch(CloseNgbModal(this._createProjectModalName));
      });
      modalComponent.create.pipe(
        takeWhile(() => this._isAlive)
      ).subscribe((newProject) => {
        this.createNewProject(newProject);
      });
    });
    const selectedPaymasterManager = getState<FilterOption>(this.store, selectSelectedPaymasterManager);
    this.dropDownFilterType = _.isEmpty(selectedPaymasterManager)
      ? PaymasterBatchConstants.PaymasterBatch.DefaultPaymasterBatchFilter
      : PaymasterBatchFilterTypes['paymaster_manager'];
    this.paymasterFilters = _.isEmpty(selectedPaymasterManager)
      ? getState<DropdownFilter[]>(this.store, selectPaymasterBrowseFilters)
      : getState<DropdownFilter[]>(this.store, selectPaymanagerBrowseFilters);
    this.isPaymasterFilterSelected = _.some(this.paymasterFilters, ({selectedItems}) => !_.isEmpty(selectedItems));
  }

  ngOnDestroy() {
    this._isAlive = false;
    this._modalSubscription.forEach(modal => modal.unsubscribe());
  }

  onCreateNewClicked() {
    this.store.dispatch(OpenNgbModal(this._createProjectModalName));
  }

  openModal() {
    this._modalRef = this.ngbModal.open(
      this.manageColumnModal,
      ModalConstants.Modal.ModalConfigs
    );
  }
  closeModal() {
    this._modalRef.close();
  }
  createNewProject(clientId: string): void {
    this._projectService.CreateProject(clientId).subscribe(response => {
      if (response.projectId) {
        this.store.dispatch(CloseNgbModal(this._createProjectModalName));
        this._toastService.flash('createProjectSuccess', ToastTypes.Success, 'Project created successfully');
        this.router.navigate(['/projects', response.projectId, 'settings']);
      }
    },
    () => {
         this._toastService.flash('createProjectFail', ToastTypes.Error, 'Project creation failed');
      }
    );
  }

  columnChanged(selectedColumns) {
    this.selectedColumnList = selectedColumns;
    this.closeModal();
  }

  toggleColumnVisibility(event: ColumnLocation) {
    this.store.dispatch(ProjectBrowseToggleColumnVisibility(event));
  }
  onSortTypeChanged(event: Event): void {
    this.store.dispatch(ProjectBrowseSort((<HTMLInputElement>event.target).value));
  }
  onSearchTextChange(event: Event) {
    this.searchText.next(event);
  }
  closePanel() {
    this.filterPopover.close();
  }
  onClearFilters() {
    this.store.dispatch(fromProjectBrowseActions.ClearAllFilters());
    this.dropDownFilterType = PaymasterBatchConstants.PaymasterBatch.DefaultPaymasterBatchFilter;
    this.paymasterFilters = getState<DropdownFilter[]>(this.store, selectPaymasterBrowseFilters);
    this.isPaymasterFilterSelected = _.some(this.paymasterFilters, ({selectedItems}) => !_.isEmpty(selectedItems));
  }
  onAddFilter(filterOption: FilterOption) {
    this.store.dispatch(UpdateProjectBrowseFilters(filterOption));
  }

  onRemoveFilter(filterOption: FilterOption) {
    this.store.dispatch(RemoveProjectBrowseFilter(filterOption));
  }
  onFilterSearchTextChanged(filterText: string): void {
    this.store.dispatch(StudioFilterSearchText(filterText));
  }
  onFilterTypeChanged(event: TitleOption) {
    this.store.dispatch(fromProjectBrowseActions.ClearPaymasterFilters());
    this.dropDownFilterType = event;
    if (event.value === 'paymaster_managers') {
      this.paymasterFilters = getState<DropdownFilter[]>(this.store, selectPaymanagerBrowseFilters);
      this.isPaymasterFilterSelected = _.some(this.paymasterFilters, ({selectedItems}) => !_.isEmpty(selectedItems));
      return;
    }
    if (event.value === 'paymasters') {
      this.paymasterFilters = getState<DropdownFilter[]>(this.store, selectPaymasterBrowseFilters);
      this.isPaymasterFilterSelected = _.some(this.paymasterFilters, ({selectedItems}) => !_.isEmpty(selectedItems));
    }
  }
  onAddPaymasterFilter({filterOption, filterType}: FilterConfig) {
    if (filterType.value === 'paymaster_managers') {
      this.store.dispatch(fromProjectBrowseActions.AddPaymasterManagerFilter(filterOption));
      this.paymasterFilters = getState<DropdownFilter[]>(this.store, selectPaymanagerBrowseFilters);
      this.isPaymasterFilterSelected = _.some(this.paymasterFilters, ({selectedItems}) => !_.isEmpty(selectedItems));
      return;
    }
    if (filterType.value === 'paymasters') {
      const paymasters = getState<Paymaster[]>(this.store, selectPayMasterLookUp);
      const {id= ''} = _.find(paymasters, (paymaster: Paymaster) => paymaster?.id === _.toNumber(filterOption.id)) ?? {};
      this.store.dispatch(fromProjectBrowseActions.AddPaymasterFilter(filterOption, _.toString(id)));
      this.paymasterFilters = getState<DropdownFilter[]>(this.store, selectPaymasterBrowseFilters);
      this.isPaymasterFilterSelected = _.some(this.paymasterFilters, ({selectedItems}) => !_.isEmpty(selectedItems));
    }
  }

  onRemovePaymasterFilter({filterOption, filterType}: FilterConfig) {
    if (filterType.value === 'paymaster_managers') {
      this.store.dispatch(fromProjectBrowseActions.RemovePaymasterManagerFilter(filterOption));
      const payrollFilters = getState<DropdownFilter[]>(this.store, selectPaymanagerBrowseFilters);
      this.paymasterFilters = _.map(payrollFilters, (paymasterFilter: DropdownFilter) => ({...paymasterFilter, selectedItems: []}));
      this.isPaymasterFilterSelected = _.some(this.paymasterFilters, ({selectedItems}) => !_.isEmpty(selectedItems));
      return;
    }
    if (filterType.value === 'paymasters') {
      const paymasters = getState<Paymaster[]>(this.store, selectPayMasterLookUp);
      const {id = ''} = _.find(paymasters, ({firstName, lastName}: Paymaster) => filterOption.value === `${lastName}, ${firstName}`) ?? {};
      const value = filterOption.type === 'paymasters' ? _.toString(id) : filterOption.value;
      this.store.dispatch(fromProjectBrowseActions.RemovePaymasterFilters({...filterOption, value}));
      this.paymasterFilters = getState<DropdownFilter[]>(this.store, selectPaymasterBrowseFilters);
      this.isPaymasterFilterSelected = _.some(this.paymasterFilters, ({selectedItems}) => !_.isEmpty(selectedItems));
    }
  }
}
