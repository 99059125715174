import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngrx/store';
import {forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {inject} from '@angular/core';

import {Paymaster} from '../../../../common/paymaster.model';
import {ProjectDetailsDataService} from '../../shared/data-services/project-details.data.service';
import {PaymasterLookup, PaymasterManagerLookup} from '../actions/paymaster-lookup.action';
import {getState} from '../../shared/utils';
import {selectUserEmail} from '../../auth/selectors';
import {Filters, SetOffsetToDefaultValue} from '../actions/paymaster-ui.action';
import {PaymasterBatchService} from './paymaster-batch.service';
import {FilterOption} from '../../shared/components/filter-panel/filter-item.model';
import {selectIsBTMSearchChanged} from '../selectors/paymaster-ui.selector';

export const PaymasterLookupResolver: ResolveFn<Paymaster[]> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  store: Store<Paymaster> = inject(Store),
  projectDataService: ProjectDetailsDataService = inject(ProjectDetailsDataService),
  paymasterBatchService: PaymasterBatchService = inject(PaymasterBatchService)
): Observable<Paymaster[]>|Promise<Paymaster[]>|Paymaster[] => {
  return forkJoin(
    paymasterBatchService.RetrievePaymasterManagers(),
    projectDataService.RetrievePaymasters()
  ).pipe(
    map(([paymasterManagers, paymasters]) => {
      // add paymaster to batch filters
      const isBTMSearchChanged = getState<boolean>(store, selectIsBTMSearchChanged);
      if (!isBTMSearchChanged) {
        const paymasterEmail = getState<string>(store, selectUserEmail);
        const updateFilter: FilterOption = {
          id: 'paymasters',
          type: 'paymasters',
          value: paymasterEmail
        };
        store.dispatch(Filters(updateFilter));
      }
      // reset offset to default value: 1
      store.dispatch(SetOffsetToDefaultValue());
      store.dispatch(PaymasterManagerLookup(paymasterManagers));
      store.dispatch(PaymasterLookup(paymasters));
      return paymasters;
    })
  );
};
