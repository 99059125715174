export interface DocumentMetadata {
  documentId: string;
  fileName: string;
  fileType: string;
  documentType: string;
  uploadedBy: string;
  uploadedDate: string;
  isInfected?: boolean;
}

export enum DocumentProxyResponseCode {
  FOUND = 'FOUND',
  NOT_FOUND = 'NOT_FOUND',
  INFECTED = 'INFECTED',
  NO_SCAN_RESULT = 'NO_SCAN_RESULT'
}

export interface ApiDocumentProxyResponse {
  path: string;
  requestId: string;
  responseTime: number;
  error: string;
  message: string;
  code: DocumentProxyResponseCode;
}
