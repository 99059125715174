import {Action, createReducer, on} from '@ngrx/store';

import {PayCode, PayType1, PayType2Map} from '../../../common/pay-code.model';
import {
  RetrieveAllPayCodesSuccessful,
  RetrieveDepartmentsSuccessful,
  RetrieveGlShortcutsSuccessful,
  RetrieveJobCodesSuccessful,
  RetrievePaymanagerSuccessful,
  RetrievePaymasterSuccessful,
  RetrievePayrollDistributionExportReportOptionSuccessful,
  RetrieveStartcardExportReportOptionSuccessful,
  RetrieveTaxJurisdictionSuccessful,
  RetrieveTimecardLookupSuccessful,
  RetrieveTimecardTemplateSuccessful,
  RetrieveUnionFailed,
  RetrieveUnionSuccessful, RetrieveWorkStatusCombinationSuccessful
} from '../actions/lookup.action';
import {WorkLocationViewModel} from '../../../common/start-card.model';
import {Department} from '../../../common/department-setting.model';
import {FullUnion, JobDetails} from '../../../common/contract-rate.model';
import {UpdateDepartmentsSuccessful} from '../settings/actions/project-department.action';
import {MapToPayType1List, MapToPayType2Map} from '../../../common/utilities/pay-code';
import {TimecardTemplate} from '../../../common/template.model';
import {SaveTimecardTemplateSuccessful} from '../template/timecards/actions/timecard-template.action';
import {GLShortcutsViewModel} from '../../../common/project-setting.model';
import {Paymaster} from '../../../common/paymaster.model';
import {IWorkStatusCombinations} from '../../../common/work-status.model';
import {TimecardLookupData, TimecardTypes} from '../../../common/timecard.model';
import {ExportReportOption} from '../../../common/report.model';

export interface TimecardTemplateLookup {
  timecard: TimecardTemplate;
}
export interface TemplateLookup {
  timecardTemplate: TimecardTemplateLookup;
}
export interface LookupState {
  payType2Map: PayType2Map;
  taxJurisdiction: WorkLocationViewModel[];
  departmentList: Department[];
  unions: FullUnion[];
  payType1List: PayType1[];
  payType2List: PayCode[];
  glShortcutList: GLShortcutsViewModel[];
  template: TemplateLookup;
  paymasters: Paymaster[];
  paymasterManagers: Paymaster[];
  workStatusCombinations: IWorkStatusCombinations;
  timecardLookupData: {
    // Redux state will be using the key names of TimecardTypes which is CREW and SAG
    [key in keyof typeof TimecardTypes]?: TimecardLookupData;
  };
  startcardExportReportOptions: ExportReportOption[];
  payrollDistributionExportReportOptions: ExportReportOption[];
  jobDetails: { [contractProfileId: string]: JobDetails[] };
}
export const initialState: LookupState = {
  payType2Map: {},
  taxJurisdiction: [],
  departmentList: [],
  unions: [],
  payType1List: [],
  payType2List: [],
  glShortcutList: [],
  template: {
    timecardTemplate: null
  },
  paymasters: [],
  paymasterManagers: [],
  workStatusCombinations: {},
  timecardLookupData: null,
  startcardExportReportOptions: [],
  payrollDistributionExportReportOptions: [],
  jobDetails: {}
};
const reducer = createReducer(
  initialState,
  on(RetrieveTaxJurisdictionSuccessful, (state, {taxJurisdiction}) => ({
    ...state,
    taxJurisdiction
  })),
  on(RetrieveDepartmentsSuccessful, UpdateDepartmentsSuccessful, (state, {departmentList}) => ({
    ...state,
    departmentList
  })),
  on(RetrieveUnionSuccessful, (state, {unions}) => ({
    ...state,
      unions
  })),
  on(RetrieveUnionFailed, (state) => ({
    ...state,
      unions: []
  })),
  on(RetrievePaymasterSuccessful, (state, {paymasters}) => ({
    ...state,
    paymasters
  })),
  on(RetrievePaymanagerSuccessful, (state, {paymasterManagers}) => ({
    ...state,
    paymasterManagers
  })),
  on(RetrieveGlShortcutsSuccessful, (state, {glShortcutList}) => ({
    ...state,
    glShortcutList
  })),
  on(RetrieveWorkStatusCombinationSuccessful, (state, {workStatusCombinations}) => ({
    ...state,
    workStatusCombinations
  })),
  on(RetrieveAllPayCodesSuccessful, (state, {payCodes}) => ({
    ...state,
    payType2Map: MapToPayType2Map(payCodes),
    payType1List: MapToPayType1List(payCodes),
    payType2List: payCodes
  })),
  on(SaveTimecardTemplateSuccessful, RetrieveTimecardTemplateSuccessful, (state, {timecardTemplate}) => ({
    ...state,
    template: {
      ...state.template,
      timecardTemplate: {
        ...state.template.timecardTemplate,
        timecard: timecardTemplate,
      }
    }
  })),
  on(RetrieveTimecardLookupSuccessful, (state, {payload}) => ({
    ...state,
    timecardLookupData: payload
  })),
  on(RetrieveStartcardExportReportOptionSuccessful, (state, {startcardExportReportOptions}) => ({
    ...state,
    startcardExportReportOptions
  })),
  on(RetrievePayrollDistributionExportReportOptionSuccessful, (state, {payrollDistributionExportReportOptions}) => ({
    ...state,
    payrollDistributionExportReportOptions
  })),
  on(RetrieveJobCodesSuccessful, (state, {jobDetails, contractProfileId}) => ({
    ...state,
    jobDetails: {
      ...state.jobDetails,
      [contractProfileId]: jobDetails
    }
  }))
);
export function LookupReducer(state: LookupState, action: Action) {
  return reducer(state, action);
}
