import {ResolveFn} from '@angular/router';
import { inject } from '@angular/core';
import {catchError, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import { of } from 'rxjs';

import {LookupDataService} from './lookup.data.service';
import {IWorkStatusCombinations} from '../../../common/work-status.model';
import {RetrieveWorkStatusCombinationFailed, RetrieveWorkStatusCombinationSuccessful} from '../actions/lookup.action';

export const WorkStatusResolver: ResolveFn<IWorkStatusCombinations> = () => {
  const store = inject(Store);
  return inject(LookupDataService)
    .RetrieveWorkStatusCombinations()
    .pipe(
      catchError(() => {
        store.dispatch(RetrieveWorkStatusCombinationFailed());
        return of(null);
      }),
      tap((workStatus) => store.dispatch(RetrieveWorkStatusCombinationSuccessful(workStatus)))
    );
};
