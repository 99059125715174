import {createAction} from '@ngrx/store';
import {FilterOption} from '../../shared/components/filter-panel/filter-item.model';
import {FilterParams} from '../../models/query-params.model';

export const Filters = createAction(
  '[Paymaster UI] Filters',
  (filterOption: FilterOption) => ({filterOption})
);

export const RemovePaymasterUiFilters = createAction(
  '[Paymaster UI] Remove filter',
  (filterOption: FilterOption) => ({filterOption})
);
export const Sort = createAction(
  '[Paymaster UI] Sort',
  (sort: string) => ({sort})
);

export const ClearFilters = createAction(
  '[Paymaster UI] Clear Filters'
);

export const ResetUI = createAction(
  '[Paymaster UI] Reset UI to initial State'
);

export const AllLiteBatchesLoaded = createAction(
  '[Paymaster UI] All Lite Batches Loaded',
);

export const SearchType = createAction(
  '[Paymaster UI] Update search type',
  (searchType: string) => ({searchType, operator: '[con]'})
);

export const Search = createAction(
  '[Paymaster UI] Search',
  (searchText: string, operator: string) => ({searchText, operator})
);

export const AddPaymasterFilter = createAction(
  '[Paymaster UI] Paymaster Filter',
  (filterOption: FilterOption, paymasterEmail: string) => ({filterOption, paymasterEmail})
);
export const AddPaymasterManagerFilter = createAction(
  '[Paymaster UI] Paymaster Manager Filter',
  (selectedPaymasterManager: FilterOption) => ({selectedPaymasterManager})
);
export const UpdatePaymastersFilter = createAction(
  '[Paymaster UI] Update Filter Paymaster under Paymanager',
  (filters: FilterParams[]) => ({filters})
);
export const RemovePaymasterManagerFilter = createAction(
  '[Paymaster UI] Remove Paymaster Manager Filter',
  (filterOption: FilterOption) => ({filterOption})
);
export const DisplayLoadingScreen = createAction(
  '[Paymaster UI] Display Loading Screen',
  (loadingText: string) => ({loadingText})
);
export const DismissLoadingScreen = createAction(
  '[Paymaster UI] Dismiss Loading Screen'
);
export const SetOffsetToDefaultValue = createAction(
  '[Paymaster UI] Set Offset to Default Value'
);

export const ClearPaymasterFilters = createAction(
  '[Paymaster UI] Clear paymaster and Paymaster Manager Filters'
);

export const ProjectFilterSearchText = createAction(
  '[Paymaster UI] Project Filter Search Text changed',
  (searchText: string) => ({searchText})
);

export const InvoiceFilterSearchText = createAction(
  '[Paymaster UI] Invoice Filter Search Text changed',
  (searchText: string) => ({searchText})
);
