import * as _ from 'lodash';
import {Injectable, Type} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Action, MemoizedSelector, select, Store} from '@ngrx/store';
import {BehaviorSubject, isObservable, Observable, of, Subscription} from 'rxjs';
import {withLatestFrom} from 'rxjs/operators';

import {UiState} from '../../reducers/ui.reducer';
import {CloseNgbModal} from '../../actions/ngbModal.action';
import {selectIsModalOpened} from '../../selectors/modal.selector';
import {NgbModalName} from '../ngb-modal-name.enum';
import {ModalConstants} from '../../../../constants/modal.constants';

type Selector = (modalName: string) => MemoizedSelector<UiState, boolean>;
type NgbModalActionFactory = (ngbModalName: NgbModalName) => Action;
interface ModalMap { [index: string]: NgbModalRef; }
interface ModalMapBehaviorSubject {[index: string]: BehaviorSubject<any>; }

/**
 * Find this implementation for a modal service that allows us to dispatch actions to open/close a NgbModal
 * NgbModalRef cannot be stored into NGRX Store because it mutates itself during its internal initialization process, therefore, we need a service to hold
 * the ngbModalRef such that we can dispatch action to close it.
 * We have modified this slightly to make it more type safe and update it to work with the latest version of NGRX
 * https://gist.github.com/Kamshak/5f1aafae2af46d42d2804ad03411862f
 * Keep Modal in Sync with Application State.
 * (A bit hacky because ngbootstrap modal wasn't built for ngrx/Store)
 */
@Injectable({providedIn: 'root'})
export class NgbModalService {
  private _defaultOption = of(ModalConstants.Modal.ModalConfigs);
  private _modals: ModalMap = {};
  private _modals$: ModalMapBehaviorSubject = {};
  // If modal is closed by clicking outside of it or hit ESC key, we still need to update the state for that modal.
  private _handleModalClosedExternally(modalName: NgbModalName, closeAction: NgbModalActionFactory) {
    this._modals[modalName].result.then(() => {
      // Modal Close event should be handled by application code and not some default close op.
      if (!!this._modals[modalName]) {
        console.log(`[NgbModalService] - Possible Dev Error: NgbModal is Closed by failsafe code.`);
        delete this._modals[modalName];
        if (!!closeAction) {
          this._store.dispatch(closeAction(modalName));
        }
      }
    }, () => {
      delete this._modals[modalName];
      if (!!closeAction) {
        this._store.dispatch(closeAction(modalName));
      }
    });
  }
  private _closeModal(modalName: NgbModalName) {
    if (this._modals[modalName] && this._modals[modalName].componentInstance) {
      this._modals[modalName].close();
      delete this._modals[modalName];
    }
  }
  private _closeOtherOpenedModals(currentModalId: string, modals: ModalMap): void {
    _
      .chain(modals)
      .pickBy((value: NgbModalRef, key: string) =>
        key !== currentModalId && !!_.get(value, 'componentInstance', null))
      .keys()
      .value()
      .forEach((id: NgbModalName) => this._closeModal(id));
  }

  constructor(
    private _modalService: NgbModal,
    private _store: Store<any>
  ) {}
  modalComponentInstance<T>(modalName: NgbModalName): Observable<T> {
    if (!this._modals$[modalName]) {
      this._modals$[modalName] = new BehaviorSubject<T>(null);
    }
    return this._modals$[modalName].asObservable();
  }
  /*
    // this is how to set Inputs for the component used by Modal
    // here I'm just showing a static setting, if the isMultiDecline is coming from an Observable, then we can use WithLatestFrom
    this._ngbModalService.modalComponentInstance<DeclineTimecardNoteModalComponent>(NgbModalName.TIMECARD_ENTRY_DECLINE_NOTE).pipe(
      takeWhile(() => this._isAlive)
    ).subscribe((modalComponent: DeclineTimecardNoteModalComponent) => {
      if (!!modalComponent) {
        modalComponent.isMultiDecline = of(false);
      }
    });
  */
  /**
   * Add a modal listener with the ability to pass in custom options.
   *
   * @param ngbModalName - Unique NgbModalName.
   * @param component - Component type to instantiate.
   * @param selector (Optional) - Selector selecting a boolean state attribute that determines modal visibility.
   *        Only override this if you want to use a different store slice to store the modal's visibility flag
   *        And you must provide the closeActionFactory that works with that store slice
   * @param closeActionFactory (Optional) - a Factory Function to provide an Action this service could dispatch to
   *        set the show modal flag to false when the user closes the modal without triggering any action.
   *        e.g. user hit ESC to close modal
   * @param lazyModalService (Optional) - NgbModal instance of the lazy loaded module.
   * @param options (Optional) - NgbModalOptions to use. If an observable is passed the latest emitted value is used before each modal open.
   */
  addModalListener(
    ngbModalName: NgbModalName,
    component: Type<any>,
    options: {} | Observable<{}> = this._defaultOption,
    selector: Selector = selectIsModalOpened,
    closeActionFactory: NgbModalActionFactory = (name: NgbModalName) => CloseNgbModal(name),
    lazyModalService: NgbModal = this._modalService
  ): Subscription {
    const optionsObservable: Observable<{}> = (options && isObservable(options)) ? options : of(options);
    return this._store.pipe(
      select(selector(ngbModalName)),
      withLatestFrom(optionsObservable)
    ).subscribe(([showModal, resolvedOptions]) => {
      if (showModal) {
        // prevent multiple modals to be opened at the same time
        this._closeOtherOpenedModals(ngbModalName, this._modals);
        // check if modal is already opened before trying to open the same modal again
        if (!this._modals[ngbModalName] || !this._modals[ngbModalName].componentInstance) {
          this._modals[ngbModalName] = lazyModalService.open(component, resolvedOptions);
          if (!this._modals$[ngbModalName]) {
            this._modals$[ngbModalName] = new BehaviorSubject<NgbModalRef>(this._modals[ngbModalName].componentInstance);
          } else {
            this._modals$[ngbModalName].next(this._modals[ngbModalName].componentInstance);
          }
          // If modal is closed by clicking outside of it we still need to set the correct state.
          this._handleModalClosedExternally(ngbModalName, closeActionFactory);
        }
      } else {
        this._closeModal(ngbModalName);
      }
    });
  }
}
