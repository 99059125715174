export enum ApiRoutes {
  Login = 'auth/login',
  Logout = 'auth/logout',
  Callback = 'auth/callback',
  UserProfile = 'api/userProfile',
  TimecardSummaries = 'api/timecardSummaries',
  ApprovalSummaries = 'api/approvalSummaries',
  StartcardSearch = 'api/startcard/search',
  Startcards = 'api/startcards',
  StartcardSummaries = 'api/startcardSummaries',
  UserManagement = 'api/userManagement',
  Projects = 'api/projects',
  Batches = 'api/batches',
  Timecard = 'api/timecards',
  TimecardOCR = 'api/timecardOCR',
  ContractRate = 'api/contract-rate',
  Cms = 'api/cms',
  Tax = 'api/tax',
  Templates = 'api/templates',
  Payments = 'api/payments',
  PayCodes = 'api/payCodes',
  Notes = 'api/notes',
  DailyTime = 'api/dailyTimes',
  Report = 'api/report',
  Paymaster = 'api/paymaster',
  Settings = 'api/settings',
  UserPreferences = 'api/userPreferences',
  ProcessTimecard = 'api/process-timecard',
  ProcessExternalTimecard = 'api/process-external-timecards',
  InitializeTimecard = 'api/initialize-timecard',
  WorkStatus = `api/workStatus`,
  ConsumerApi = 'consumer-api'
}
export function ServerRoutes(route: ApiRoutes): string {
  return `/${route}`;
}
