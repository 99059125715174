import { createAction } from '@ngrx/store';

import { WorkLocationPickerArea } from '../../../timecard-component/models/work-location-picker.view.model';
import * as fromTimecardTemplateview from '../models/timecard-template-view.model';
import { TimecardLookupData } from '../../../../../common/timecard.model';
import {Splits} from '../../../../../common/split-coding.model';

export const ClearShift = createAction(
  '[Template UI] Clear Shift', (shiftId: string) => ({shiftId})
);
export const CopyShift = createAction(
  '[Template UI] Copy Shift', (shiftId: string) => ({shiftId})
);
export const CopyUptoFifthShift = createAction(
  '[Template UI] Copy shift to all days upto fifth',
  (shiftId: string) => ({shiftId})
);
export const PasteShift = createAction(
  '[Template UI] Paste Shift in Clipboard', (shiftId: string) => ({shiftId})
);
export const ClipboardShift = createAction(
  '[Template UI] Update Clipboard Shift', (shift: fromTimecardTemplateview.Shift) => ({shift})
);
export const ClearSummaryLine = createAction(
  '[Template UI] Clear SummaryLine', (summaryId: string) => ({summaryId})
);
export const CopySummaryLine = createAction(
  '[Template UI] Copy SummaryLine', (summaryId: string) => ({summaryId})
);
export const PasteSummaryLine = createAction(
  '[Template UI] Paste SummaryLine in Clipboard', (summaryId: string) => ({summaryId})
);
export const AddDayType = createAction(
  '[Template UI] Add Day Type', (shiftId: string, shiftDate: string) => ({shiftId, shiftDate})
);
export const RemoveDayType = createAction(
  '[Template UI] Remove Day Type', (shiftId: string, shiftDate: string) => ({shiftId, shiftDate})
);
export const ClipboardSummaryLine = createAction(
  '[Template UI] Update Clipboard SummaryLine',
  (summaryLine: fromTimecardTemplateview.SummaryLine) => ({summaryLine})
);
export const CopyPunchDownSection = createAction(
  '[Template UI] Copy Punch Down Section', ( fromShiftId: string,  toShiftId: string) => ({fromShiftId, toShiftId})
);
export const CopyAccountDownSection = createAction(
  '[Template UI] Copy Account Down Section', ( fromShiftId: string,  toShiftId: string) => ({fromShiftId, toShiftId})
);
export const CopyDetailDownSection = createAction(
  '[Template UI] Copy Detail Down Section', ( fromShiftId: string,  toShiftId: string) => ({fromShiftId, toShiftId})
);
export const CopySummaryWorkDateDownSection = createAction(
  '[Template UI] Copy Summary Work Date Down Section', ( fromSummaryId: string,  toSummaryId: string) => ({fromSummaryId, toSummaryId})
);
export const CopySummaryBasicsDownSection = createAction(
  '[Template UI] Copy Summary Basics Down Section', ( fromSummaryId: string,  toSummaryId: string) => ({fromSummaryId, toSummaryId})
);
export const CopySummaryAccountsDownSection = createAction(
  '[Template UI] Copy Summary Accounts Down Section', ( fromSummaryId: string,  toSummaryId: string) => ({fromSummaryId, toSummaryId})
);
export const CopySummaryPayDetailsDownSection = createAction(
  '[Template UI] Copy Summary Pay Details Down Section',
  ( fromSummaryId: string,  toSummaryId: string) => ({fromSummaryId, toSummaryId})
);
export const DisplayWorkLocationPickerForShift = createAction(
  '[Template UI] Display Work Location Picker For Shift Area',
  ( id: string,  location: WorkLocationPickerArea) => ({id, location})
);
export const CloseWorkLocationPicker = createAction(
  '[Template UI] Close Work Location Picker For Shift Area'
);
export const DisplayWorkLocationPickerForSummary = createAction(
  '[Template UI] Display Work Location Picker For Summary Area',
  ( id: string,  location: WorkLocationPickerArea) => ({id, location})
);
export const RetrieveTimecardLookupData = createAction(
  '[Template UI] Retrieve Timecard Lookup Data'
);

export const RetrieveTimecardLookupDataSuccessful = createAction(
  '[Template UI] Retrieve Timecard Lookup Data Successful',
  (payload: TimecardLookupData) => ({payload})
);
export const RetrieveTimecardLookupDataFailed = createAction(
  '[Template UI] Retrieve Timecard Lookup Data Failed'
);
export const InitializeTemplateSplitCodingDialog = createAction(
  '[Timecard Template UI] Initialize Template Split Coding Dialog',
  ( shiftId: string, splits: Splits) => ({shiftId, splits})
);

export const SetIsPayCodeFocused = createAction(
  '[Timecard Template UI] Should set isPayCodeFocused',
  (isPayCodeFocused: boolean) => ({isPayCodeFocused})
);
