import * as _ from 'lodash';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild, OnDestroy} from '@angular/core';
import {merge, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, takeWhile} from 'rxjs/operators';
import {NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';

import { FilterOption } from '../filter-panel/filter-item.model';
import {trackByFn} from '../../utils';

@Component({
  selector: 'st-text-filter',
  templateUrl: './text-filter.component.html',
  styleUrls: ['./text-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextFilterComponent implements OnDestroy {
  @Input() type: string;
  @Input() title: string;
  @Input() options: FilterOption[] = [];
  @Input() selectedItems: FilterOption[] = [];
  @Input() minSearchStringLength = 2;
  @Input() tabIndex: number;
  @Input() maximumSearchTextLength: number = null;
  @Output() addFilter = new EventEmitter<any>();
  @Output() removeFilter = new EventEmitter<any>();
  @Output() searchInput = new EventEmitter<string>();
  @ViewChild('instance') instance: NgbTypeahead;
  inputFocus = new Subject<string>();
  inputClick = new Subject<string>();
  showClearSearchStringButton = false;
  searchString = '';
  trackByFn = trackByFn;
  private _isAlive = true;

  ngbSearch = (searchString: Observable<string>) => {
    const debouncedSearchString = searchString
      .pipe(
        debounceTime(200),
        filter((query: string) => query.length >= this.minSearchStringLength || query.length === 0),
        distinctUntilChanged());
    const clicksWithClosedPopup = this.inputClick.pipe(filter(() => !this.instance.isPopupOpen()));

    return merge(debouncedSearchString, this.inputFocus, clicksWithClosedPopup).pipe(
      takeWhile(() => this._isAlive),
      map(term => this.options.filter(option => {
        return option.value.toLowerCase().indexOf(term.toLowerCase()) > -1 &&
          this.selectedItems.findIndex((selected) => selected.id === option.id) === -1;
      })),
    );
  }

  formatter = (result: FilterOption) => result.value;

  clearSearchString(): void {
    this.searchString = '';
    this.showClearSearchStringButton = false;
  }

  onAddFilter(event: any) {
    event.preventDefault();
    this.addFilter.emit(event.item);
    this.clearSearchString();
  }

  onRemoveFilter(filterOption: FilterOption) {
    this.removeFilter.emit(filterOption);
  }

  onKeyUp() {
    this.showClearSearchStringButton = _.get(this.searchString, 'length', 0) > 0;
  }

  onInput(inputText: string): void {
    this.searchInput.emit(inputText);
  }

  selectedFilterLabel(item: FilterOption) {
    return item.type === 'batchNameNotAnalyzed' && item.id === 'NONE' ? 'No Batch' : item.value;
  }

  ngOnDestroy(): void {
    this._isAlive = false;
  }
}
