import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {ApiRoutes} from '../../../../common/api-routes';
import {MobileNote, Note, NotesPreview} from '../../../../common/timecard-note.model';
import {StartcardPaymasterNote} from '../../../../common/start-card.model';

@Injectable({
  providedIn: 'root'
})
export class TimecardNotesDataService {
  constructor(private http: HttpClient) {}
  RetrieveLiteTimecardNotes(timecardId: string): Observable<NotesPreview> {
    return this.http.get<NotesPreview>(`${ApiRoutes.Notes}/timecards/${timecardId}/liteNotes`);
  }
  RetrieveTimecardNotes(timecardId: string): Observable<Note[]> {
    return this.http.get<Note[]>(`${ApiRoutes.Notes}/timecards/${timecardId}/notes`);
  }
  CreateNote(note: Partial<Note>): Observable<Note> {
    return this.http.post<Note>(`${ApiRoutes.Notes}/timecards/${note.timecardId}`, note);
  }
  CreateMobileNote(mobileNote: Partial<MobileNote>, projectId: string): Observable<MobileNote> {
    return this.http.post<MobileNote>(`${ApiRoutes.Notes}/project/${projectId}/note`, mobileNote);
  }
  RetrieveMobileNotes(projectId: string, timecardId: string): Observable<Note[]> {
    return this.http.get<Note[]>(`${ApiRoutes.Notes}/project/${projectId}/timecards/${timecardId}/mobileNotes`);
  }
  RetrieveStartcardPaymasterNotes(startcardId: string): Observable<StartcardPaymasterNote> {
    return this.http.get<StartcardPaymasterNote>(`${ApiRoutes.Notes}/startcards/${startcardId}/notes`);
  }
}
