import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {ColumnGroupConfig, ColumnLocation, ColumnVisibilityMap, Grids, Sections} from './manage-column.model';

@Component({
  selector: 'st-manage-column',
  templateUrl: './manage-column.component.html',
  styleUrls: ['./manage-column.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManageColumnComponent {
  @Input() isGroupingVisible = true;
  @Input() columnGroupConfig: ColumnGroupConfig[];
  @Input() visibleColumnCount: number;
  @Input() groupVisibility: ColumnVisibilityMap;
  @Input() grid: Grids;
  @Output() columnVisibilityUpdated = new EventEmitter<ColumnLocation>();
  @Output() groupVisibilityUpdated = new EventEmitter<{section: Sections, grid: Grids}>();

  onColumnVisibilityUpdate(section: Sections, column: string, grid: Grids) {
    this.columnVisibilityUpdated.emit({section, column, grid});
  }

  onGroupVisibilityUpdate(section: Sections, grid: Grids) {
    this.groupVisibilityUpdated.emit({section, grid});
  }

}
