import {NgbDate, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Inject, Injectable, InjectionToken} from '@angular/core';
import * as moment from 'moment';

import {IRegionUiConfig, RegionUIConfigToken} from '../../../constants/region-ui-config.constants';
import {DayOfWeek} from '../../../common/constants';

export const DatePickerUtilToken = new InjectionToken<DayOfWeek>('DatePickerUtilToken');

interface IBaseDatePickerUtil {
  getNgbDateStructFromDate(date: Date): NgbDateStruct;
  getDateStringFromNgbDate(date: NgbDate, format: string): string;
  getYesterdayDate(): Date;
}

export interface IDatePickerUtil extends IBaseDatePickerUtil {
  isWeekEndingDate(date: NgbDateStruct): boolean;
  getLastWeekEnding(): Date;
  getLastWeekEndingNgbDate(): NgbDateStruct;
}

@Injectable()
export class BaseDatePickerUtil {

  getNgbDateStructFromDate(date: Date): NgbDateStruct {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
  }

  getDateStringFromNgbDate(date: NgbDate, format: string): string {
    const jsDate = new Date(date.year, date.month - 1, date.day);
    return moment(jsDate).format(format);
  }

  getYesterdayDate(): Date {
    const d = new Date();
    d.setDate(d.getDate() - 1);
    return d;
  }
}

@Injectable()
export class DatePickerRegionUtil extends BaseDatePickerUtil implements IDatePickerUtil {
  constructor(@Inject(RegionUIConfigToken) public _regionUiConfig: IRegionUiConfig) {
    super();
  }

  // Function to get the weekEndingDate based on start of week
  isWeekEndingDate(date: NgbDateStruct): boolean {
    const weekEndingDay = (this._regionUiConfig.uiConfig.startOfWeek + 6) % 7;
    const d = new Date(date.year, date.month - 1, date.day);
    return d.getDay() === weekEndingDay;
  }

  getLastWeekEnding(): Date {
    const weekEndingDay = this._regionUiConfig.uiConfig.weekEndingDay;
    const d = new Date();
    d.setDate(d.getDate() - ((d.getDay() === weekEndingDay) ? 7 : d.getDay() + 1));
    return d;
  }

  getLastWeekEndingNgbDate(): NgbDateStruct {
    const d = this.getLastWeekEnding();
    return {'year': d.getFullYear(), 'month': d.getMonth() + 1, 'day': d.getDate()};
  }
}
