import {Department, DepartmentStartcards} from './crew-department.model';

export interface Toast {
  id?: string;
  type: ToastTypes;
  message: string;
  page: Pages;
}

export enum ToastTypes {
  Error = 'Error',
  Success = 'Success'
}

export enum Pages {
  BatchBrowse = 'BatchBrowse',
  TimecardBrowse = 'TimecardBrowse',
  BatchDetail = 'BatchDetail',
  TimecardEntry = 'TimecardEntry',
  TimecardTemplate = 'TimecardTemplate',
  ProjectSettings= 'ProjectSettings',
  UserManagementBrowse = 'UserManagementBrowse',
  UserDetails= 'UserDetails',
  ApprovalBrowse = 'ApprovalBrowse',
  DayCostReportPage = 'DayCostReportPage',
  PaymasterBatchDetailsPage = 'PaymasterBatchDetailsPage',
  StartcardBrowse = 'StartcardBrowse',
  StartcardDetails = 'StartcardDetails',
  DailyTime = 'DailyTime'
}

export interface BrowsePageSelectionStatus {
  [key: string]: boolean;
}

export interface BrowsePageRowSelection {
  id: string;
  status: boolean;
}

export interface LoadCrew {
  departmentList: Department[];
  departmentStartcards: DepartmentStartcards;
}

//  We will use loadStartcards store slice for SAG which will be eventually used for both Crew and SAG load startcard.
export interface LoadStartcards {
  departmentList: Department[];
  departmentStartcards: DepartmentStartcards;
  sagDepartmentStartcards: DepartmentStartcards;
}
