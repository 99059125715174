import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApiRoutes} from '../../../../common/api-routes';
import {LastVisited} from '../../../../common/user-preferences.model';



@Injectable({
  providedIn: 'root'
})
export class UserPreferences {
  constructor(private http: HttpClient) {}
  UpdateLastVisited (lastVisitedUrl: string, lastVisitedProjectId: string): Observable<LastVisited> {
    return this.http.put<LastVisited>(`${ApiRoutes.UserPreferences}/lastVisited`, {lastVisitedUrl, lastVisitedProjectId});
  }
}
