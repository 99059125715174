import {inject} from '@angular/core';
import {Store} from '@ngrx/store';
import {selectProjectSettings} from '../auth/selectors';
import {RetrieveVaEnableStatus} from '../auth/actions/auth';
import {ResolveFn} from '@angular/router';
import {ProjectSetting} from '../../../common/project-setting.model';
import {getState} from '../shared/utils';


export const VaEnableStatusResolver: ResolveFn<void> = (): void => {
  const store: Store = inject(Store);
  const {clientId} = getState<ProjectSetting>(store, selectProjectSettings);

  if (clientId) {
    store.dispatch(RetrieveVaEnableStatus(clientId));
  }
};
