import { ActionReducerMap } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromAuth from './auth';
import {AuthReducer} from './auth';

export interface AuthState {
  status: fromAuth.State;
}

export interface State extends fromRoot.State {
  auth: AuthState;
}

export const reducers: ActionReducerMap<AuthState> = {
  status: AuthReducer
};
