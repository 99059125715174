import { Project } from '../../../common/project.model';
import { environment } from '../../environments/environment';
import { User } from '../../../common/user.model';

export const initPendo = (user: User, project: Project) => {
  if (!user || !project) {
    return; // you must have both user and project to identify to pendo
  }
  const newVisitorId = user.email;
  const newAccountId = `SmartTime-Fenix - Project ${project.projectId}`;
  // @ts-ignore - Fix STF-2446, pendo sometimes is not initialized yet causing our page to blowup.
  if (!pendo.getVisitorId || !pendo.getAccountId) {
    return;
  }
  // @ts-ignore // pendo object is not available at compile time; it is generated at runtime by the code snippet in index.html
  if ((pendo.getVisitorId() === newVisitorId) && (pendo.getAccountId() === newAccountId)) {
    return; // if no change in visitor or account; don't update visitor/account information
  }
  try {
    // @ts-ignore // pendo object is not available at compile time
    pendo.identify({
      visitor: {
        id: newVisitorId,
        lastName: user.lastName,
        firstName: user.firstName,
        email: user.email,
        role: project.userRole ?? user.role
      },
      account: {
        id:           newAccountId,
        projectName:  project.projectName,
        projectNumber: project.projectNumber,
        studioName:    project.studioName,
        environment:  environment.production ? 'PRODUCTION' : 'NON-PROD',
        application:  'SmartTime-Fenix'
      }
    });
  } catch (e) {
    console.log('Pendo identification failed. Exception thrown is:', e);
  }
};
