import {CanActivateFn} from '@angular/router';
import {Store} from '@ngrx/store';
import {inject} from '@angular/core';

import {getState} from '../../shared/utils';
import {State} from '../../auth/reducers';
import {selectIsUserEnrollmentPending} from '../../auth/selectors';
import {ApiRoutes, ServerRoutes} from '../../../../common/api-routes';

export const ProjectEulaCanActivateGuard: CanActivateFn = (): boolean => {
  const store: Store<State> = inject(Store);
  const isUserEnrollmentPending = getState<boolean>(store, selectIsUserEnrollmentPending);
  if (!isUserEnrollmentPending) {
    window.location.href = ServerRoutes(ApiRoutes.Login);
    return false;
  }
  return isUserEnrollmentPending;
};
