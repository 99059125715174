import * as _ from 'lodash';
import {Component, Input, OnDestroy} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {filter, takeWhile} from 'rxjs/operators';

import { ApiRoutes } from '../../../../common/api-routes';
import {getState} from '../../shared/utils';
import {selectSelectedProjectId, selectSmartIdLogoutUrl} from '../../auth/selectors';
import {NgbModalName} from '../../shared/ngb-modal-name.enum';
import {NgbModalService} from '../../shared/providers/ngbModal.service';
import {ConfirmActionModalComponent} from '../../shared/modals/confirm-action-modal.component';
import {CloseNgbModal, OpenNgbModal} from '../../actions/ngbModal.action';
@Component({
  selector: 'st-user-menu',
  template: `
    <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
      <button class="btn st-btn-transparent st-font-os-r st-fs-14" id="userAccountFirstName" qaa="userAccountUsername" ngbDropdownToggle>Hi {{firstName}}</button>
      <div class="dropdown-menu" ngbDropdownMenu>
        <button id="logoutBtn" qaa="logoutBtn" class="dropdown-item" (click)="logout()">Log Out</button>
      </div>
    </div>`,
  styles: [`
    .st-btn-transparent {
      background: transparent;
      color: white;
      border-color: transparent;
    }
    #logoutBtn {
      font-size: 14px;
    }
  `],
  providers: [NgbDropdownConfig]
})
export class UserMenuComponent implements OnDestroy {
  @Input() firstName: string;

  private readonly _logoutConfirmationModal = NgbModalName.LOGOUT_CONFIRMATION;
  private _modalSubscription: Subscription[] = [];
  private _isAlive = true;
  constructor(private _http: HttpClient, private _config: NgbDropdownConfig, private _store: Store, private _ngbModalService: NgbModalService) {
    _config.placement = 'bottom-right';
    this._modalSubscription.push(this._ngbModalService.addModalListener(this._logoutConfirmationModal, ConfirmActionModalComponent ));
    this._ngbModalService.modalComponentInstance<ConfirmActionModalComponent>(this._logoutConfirmationModal).pipe(
        filter((modalInstance) => !!modalInstance),
        takeWhile(() => this._isAlive)
    ).subscribe(modalComponent => {
      modalComponent.headerText = 'Sign Out of All EP Apps?';
      modalComponent.bodyText = 'Signing out of this EP app also signs you out of all your open apps.\n' +
          '\nClick CONTINUE to proceed.\n' +
          '\nOr click CANCEL. Then, close your browser tab to exit only this app.';
      modalComponent.primaryBtnText = 'CONTINUE';
      modalComponent.modalCancel.pipe(
          takeWhile(() => this._isAlive)
      ).subscribe(() => {
        this._store.dispatch(CloseNgbModal(this._logoutConfirmationModal));
      });
      modalComponent.modalPrimaryBtn.pipe(
          takeWhile(() => this._isAlive)
      ).subscribe(() => {
        // STF-17854 - On logout clear the last visited Page URL and update the last visited ProjectId
        const lastVisitedProjectId = getState(this._store, selectSelectedProjectId);
        this._http.put(ApiRoutes.Logout, {responseType: 'json', lastVisitedProjectId}).pipe(
            takeWhile(() => this._isAlive)
        ).subscribe(() => {
          window.location.href = getState(this._store, selectSmartIdLogoutUrl);
        });
        this._store.dispatch(CloseNgbModal(this._logoutConfirmationModal));
      });
    });
  }

  logout(): void {
    this._store.dispatch(OpenNgbModal(this._logoutConfirmationModal));
  }

  ngOnDestroy(): void {
    this._isAlive = false;
    _.forEach(this._modalSubscription, (sub: Subscription) => !!sub && sub.unsubscribe());
  }
}
