import { Observable } from 'rxjs';
import {map, takeWhile} from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import {Component, OnDestroy} from '@angular/core';
import {NavigationEnd, Event, NavigationError, NavigationStart, Router, NavigationCancel} from '@angular/router';
import * as _ from 'lodash';

import * as fromAuthReducers from '../../auth/reducers';
import { Project } from '../../../../common/project.model';
import * as fromAuthSelectors from '../../auth/selectors';

import {ResetNgbModals} from '../../actions/ngbModal.action';
import * as fromUIActions from '../../../app/actions/ui.action';
import {getState} from '../../shared/utils';
import { selectSelectedProjectId } from '../../auth/selectors';
import {UserPreferences} from '../../shared/data-services/user-preferences.data.service';
import {LastVisitedUrlChanged} from '../../actions/ui.action';

/**
 * AppComponent
 * The main responsibility of the AppComponent is to handle any code that
 * needs to run immediately after bootstrap since this is the first component of the app.
 *
 * Currently, we will use this component to redirect user to their first project's Timecard Browse feature
 * */

@Component({
  selector: 'st-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  public projects: Observable<Project[]>;
  public selectedProject: Observable<Project>;
  public userFirstName: Observable<string>;
  public isPaymaster: Observable<boolean>;
  public isPayrollTrackingEnabled: Observable<boolean>;
  public isDetailsLoading: boolean;
  private _isAlive = true;
  constructor(private store: Store<fromAuthReducers.State>, private router: Router, private _userPreference: UserPreferences) {
    router.events.pipe(
      takeWhile(() => this._isAlive)
    ).subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        if ((_.includes(event.url, '/timecards/entry/') || _.includes(event.url, '/startcards/')) && ! (_.includes(event.url, '/startcards/new'))) {
          this.store.dispatch(fromUIActions.ShowLoadingScreen());
       }
        // every time we are changing route, we should dismiss any popup
        this.store.dispatch(ResetNgbModals());
      }
      // Update last visited URL and ProjectID when the url is not '/'
      if (event instanceof NavigationEnd && event.url.length > 1) {
        this.store.dispatch(LastVisitedUrlChanged(getState(this.store, selectSelectedProjectId), event.url));
/*
        this._userPreference.UpdateLastVisited(event.url, getState(this.store, selectSelectedProjectId))
          .pipe(take(1))
          .subscribe((results) => console.log(`[LastVisited] - ${JSON.stringify(results)}`));
*/
      }
      if (event instanceof NavigationEnd && (!_.includes(event.url, 'user-management')
        && !(_.includes(event.url, '/timecards/entry/') && _.includes(event.url, 'common?weekEndingDate')))) {
        this.store.dispatch(fromUIActions.HideLoadingScreen());
      }
      if (event instanceof NavigationError || event instanceof NavigationCancel) {
        this.store.dispatch(fromUIActions.HideLoadingScreen());
      }
    });
    this.projects = store.pipe(select(fromAuthSelectors.selectProjects));
    this.selectedProject = store.pipe(select(fromAuthSelectors.selectSelectedProject));
    this.userFirstName = store.pipe(select(fromAuthSelectors.selectUserFirstName));
    this.isPaymaster = store.pipe(
      select(fromAuthSelectors.selectIsPaymaster),
      map((isPaymaster: boolean) => isPaymaster)
    );
    this.isPayrollTrackingEnabled = store.pipe(
      select(fromAuthSelectors.selectIsPayrollTrackingEnabled),
      takeWhile(() => this._isAlive)
    );
  }
  ngOnDestroy(): void {
    this._isAlive = false;
  }

  onSelectedProjectChange(project: Project) {
    if (_.isEmpty(project.landingPage)) {
      return this.router.navigate(['/projects/list']);
    }
    // STF-22259 : To make the component rerender and make stPermissionToDisplay directive to work
    window.location.href = `/projects/${project.projectId}/${project.landingPage}`;
  }
  onSeeMoreProjectsClicked() {
    this.router.navigate(['/projects/list']);
  }
}

