import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgbDropdown} from '@ng-bootstrap/ng-bootstrap';
import { Router} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';

import { Project } from '../../../../common/project.model';
import {selectUrl} from '../selectors';

@Component({
  selector: 'st-project-picker',
  template: `
    <div ngbDropdown>
      <button class="btn st-btn-transparent st-font-os-r st-fs-16 st-project-picker-btn" id="projectPickerBtn" qaa="projectPickerBtn" ngbDropdownToggle>
        <div
          class="st-project-active"
          title="{{title}}">
          {{title}}
        </div>
      </button>
      <div ngbDropdownMenu aria-labelledby="btnProjectSelection" class="st-project-picker">
        <div [ngClass]="{'st-list-wrapper': projects && projects.length > itemsToShowCount}">
          <button class="dropdown-item"
                  *ngFor="let projectOption of projects | slice:0:itemsToShowCount; let i = index" (click)="selectProject(projectOption)">
            <div class="st-list-items st-cursor-pointer" id="ppOption-{{projectOption.projectId}}" attr.qaa="ppOption-{{i}}">
              <div class="st-project-name st-font-os-r st-fs-14" attr.qaa="ppProjectName-{{i}}">{{projectOption.projectName}}</div>
              <div class="st-project-number st-font-os-r st-fs-14" attr.qaa="ppProjectNumber-{{i}}">{{projectOption.projectNumber}}</div>
            </div>
          </button>
        </div>
        <button
          class="st-show-more st-fs-14 st-font-os-b st-cursor-pointer"
          qaa="pbShowMore" id="pbShowMore"
          (click)="onBrowseAllClicked()">
          Browse All
        </button>
        <button class="st-show-more st-fs-14 st-font-os-b st-cursor-pointer payroll-tracking" *ngIf="isPaymaster"
        (click)="onPayrollTrackingClicked()" [disabled]="!isPayrollTrackingEnabled">Payroll Tracking</button>
      </div>
    </div>
  `,
  styles: [
    `
          .st-project-picker-btn.dropdown-toggle::after {
              display: none;
          }

          .st-project-active {
              position: relative;
              min-width: 184px;
              max-width: 350px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              padding-left: 24px;
              text-align: left;
              margin-right: 7px;
              font-size: 1.8rem;
          }

          .st-project-active::before {
              display: inline-block;
              width: 0;
              height: 0;
              position: absolute;
              top: 12px;
              left: 0;
              margin-left: .255em;
              vertical-align: .255em;
              content: "";
              border-top: .3em solid;
              border-right: .3em solid transparent;
              border-bottom: 0;
              border-left: .3em solid transparent;
          }

          .st-project-picker {
              top: 35px;
              padding: 10px 0 0;
              border-radius: 0 0 3px 3px;
              width: 415px;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.29);
          }

          .st-list-wrapper {
              border-bottom: 1px solid #e5e5e5;
          }

          .st-project-picker .dropdown-item {
              padding: 0.25rem 1.6rem;
              margin-bottom: 10px;
          }

          .st-project-picker .dropdown-item.active, .dropdown-item:active {
              color: #006096;
              text-decoration: none;
              background-color: transparent;
          }

          .st-list-items {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 5px 0;
          }

          .st-project-name {
              color: #006096;
              width: 325px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
          }

          .st-project-number {
              color: #838383;
          }

          .st-show-more {
              display: block;
              border: 0;
              background: none;
              color: #006096;
              text-transform: uppercase;
              float: left;
              margin: 1rem 0 1.4rem 1rem;
          }

          .st-btn-transparent {
              background: transparent;
              color: white;
              border-color: transparent;
          }

          .payroll-tracking {
              float: right;
              margin-right: 10px;
          }

          .payroll-tracking:disabled {
              cursor: not-allowed;
              opacity: .5;
          }
    `
  ]
})
export class ProjectPickerComponent implements OnInit {
  @Input() projects: Project[];
  @Input() selectedProject: Observable<Project>;
  @Input() itemsToShowCount: number;
  @Input() isPaymaster: boolean;
  @Input() isPayrollTrackingEnabled: boolean;
  @Output() selectedProjectChanged = new EventEmitter<Project>();
  @Output() seeMoreClicked = new EventEmitter();
  @ViewChild(NgbDropdown) private dropdown: NgbDropdown;
  title = '';

  constructor(private _store: Store<any>, private _router: Router) { }
  ngOnInit() {
    // if user is a paymaster and is in the /paymasters route and there's no selected project, then show Payroll Tracking as Title
    // otherwise, use "Select Project" or the actual name of the selected project.
    combineLatest(
      this._store.pipe(select(selectUrl)),
      this.selectedProject
    ).subscribe(([url, selectedProject]) => {
      const defaultTitle = this.isPaymaster && (url.indexOf('/paymasters') === 0)
        ? 'Payroll Tracking'
        : 'Select Project';
      this.title = selectedProject ? `${selectedProject.projectNumber} - ${selectedProject.projectName}` : defaultTitle;
    });
  }
  selectProject(p: Project) {
    if (p) {
      this.selectedProjectChanged.emit(p);
    }
  }
  onBrowseAllClicked() {
    this.dropdown.close();
    this.seeMoreClicked.emit();
  }
  onPayrollTrackingClicked() {
    this._router.navigate(['paymasters']);
    this.dropdown.close();
  }
}
