import {inject} from '@angular/core';
import {Store} from '@ngrx/store';
import {catchError, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';

import {Paymaster} from '../../../common/paymaster.model';
import {PaymasterBatchService} from '../paymaster/services/paymaster-batch.service';
import {RetrievePaymanagerSuccessful} from '../actions/lookup.action';

export const PaymanagerResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  store: Store = inject(Store),
  paymasterBatchService: PaymasterBatchService = inject(PaymasterBatchService)
) => {
  return paymasterBatchService.RetrievePaymasterManagers().pipe(
    catchError(() => {
      return of();
    }),
    tap((paymasters: Paymaster[]) => store.dispatch(RetrievePaymanagerSuccessful(paymasters)))
  );
};
