import * as _ from 'lodash';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {forkJoin, of} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {HttpEvent, HttpEventType} from '@angular/common/http';

import {
  AcceptedDocument,
  DocumentNotFound,
  DocumentScanStatusNotAvailable,
  DocumentUploadError,
  DocumentUploadSuccessful,
  DownloadDocumentFailed,
  EmptyDocuments,
  InfectedDocumentDeleted,
  PrepareDocuments,
  RejectDuplicateDocument,
  RejectLargeDocument,
  RejectUnsupportedDocument, RetrieveDocumentFailed,
  TrackDocumentUploadProgress,
  UploadDocuments,
  UploadRequestEvent
} from '../actions/document-upload.action';
import {AllowedMimeTypes, DocumentViewModel} from '../models/document.model';
import {DocumentUploadTimout, MaxFileSize} from '../shared/constants';
import {selectValidDocuments} from '../selectors/document-upload.selector';
import {selectProjectId} from '../core/selectors';
import * as FromUIActions from '../actions/ui.action';
import {Pages, ToastTypes} from '../models/ui.model';
import {getState} from '../shared/utils';
import {DocumentProxyResponseCode} from '../../../common/document.model';

@Injectable()
export class DocumentUploadEffects {
  constructor(private actions: Actions, private _store: Store) {}

  prepareDocumentsEffect = createEffect(() => this.actions.pipe(
    ofType(PrepareDocuments),
    switchMap(({files, documentMetadata}) => {
      this._store.dispatch(EmptyDocuments());
      return _.map(files, (file) => {
        if (!_.includes(_.values(AllowedMimeTypes), file.type)) {
          return RejectUnsupportedDocument(file);
        } else if (!_.isEmpty(_.find(documentMetadata, {fileName: file.name}))) {
          return RejectDuplicateDocument(file);
        } else if (file.size > MaxFileSize) {
          return RejectLargeDocument(file);
        }
        return AcceptedDocument(file);
      });
    })
  ));

  uploadDocuments = createEffect(() => this.actions.pipe(
    ofType(UploadDocuments),
    switchMap((action) => {
      const documents = getState<DocumentViewModel[]>(this._store, selectValidDocuments);
      const projectId = getState<string>(this._store, selectProjectId);
      const entityId: string = _.get(action, 'entityId');
      const pageName: Pages = _.get(action, 'pageName');
      const uploadDocumentServiceFunction: any = _.get(action, 'uploadServiceFunction');

      const requests: any[] = _.map(documents, (document) =>
        uploadDocumentServiceFunction(document.file, projectId, entityId, document.category).pipe(
          tap((event: HttpEvent<any>) =>
            this._store.dispatch(UploadRequestEvent(event, document.documentId))
          ),
          catchError((error) => of(DocumentUploadError(document.documentId, error, pageName))),
        )
      );
      // ForkJoin will filter out HttpResponses and returns Actions only. Upload Error actions will dispatch only when forkJoin completes.
      // TODO: Every request should be an independent pipeline
      return forkJoin(requests).pipe(
        switchMap((responses: any[]): Action[] =>
          _.filter(responses, (response) => typeof response.type === 'string'))
      );
    })
  ));

  uploadRequestEvent = createEffect(() => this.actions.pipe(
    ofType(UploadRequestEvent),
    switchMap((action) => {
      const event: HttpEvent<any> = _.get(action, 'event');
      const documentId: string = _.get(action, 'documentId');
      if (event.type === HttpEventType.UploadProgress) {
        const progress = Math.round(100 * event.loaded / event.total);
        return [TrackDocumentUploadProgress(documentId, progress)];
      }
      if (event.type === HttpEventType.Response && event.status === 200) {
        return [DocumentUploadSuccessful(documentId, event.body)];
      }
      return [];
    })
  ));

  uploadErrorEffect = createEffect(() => this.actions.pipe(
    ofType(DocumentUploadError),
    map(({error, pageName}) =>
      FromUIActions.Toast({
        type: ToastTypes.Error,
        page: pageName,
        message: error.status === 408 ? DocumentUploadTimout : error.statusText
      })
    )
  ));

  retrieveDocumentFailed = createEffect(() => this.actions.pipe(
    ofType(RetrieveDocumentFailed),
    map(({documentId, errorResponse: {status, error}}) => {
      if (status === 404 && error.code === DocumentProxyResponseCode.INFECTED) {
        return InfectedDocumentDeleted(documentId);
      }
      if (status === 404 && error.code === DocumentProxyResponseCode.NOT_FOUND) {
        return DocumentNotFound();
      }
      if (status === 404 && error.code === DocumentProxyResponseCode.NO_SCAN_RESULT) {
        return DocumentScanStatusNotAvailable();
      }
      return DownloadDocumentFailed();
    })
  ));
}
