import {Toolbar} from 'ngx-editor';
import * as _ from 'lodash';

import {DayOfWeek} from '../../../common/constants';

export enum RateView {
  location = 'location',
  studio = 'studio'
}

export enum AlertBoxResponse {
  save = 'save',
  cancel = 'cancel',
  dismiss = 'dismiss',
  continue = 'continue',
  yes = 'yes',
  no = 'no'
}

export const BrowseScreensViewPortOffsetHeight = 176; // Height from top to (including) grid header
export const ChangeReportFileName = 'startcard_change_report.xlsx';
export const DailyTimeSortOrder = ['startcard.lastName', 'startcard.firstName'];

export const SagDailyTimeDefaultSortOrder: string[] = ['startcard.characterNumber', 'startcard.characterName', 'startcard.lastName', 'startcard.firstName', 'accountCoding'];
export const DailyTimeUniqComparator = 'startcard.startcardId';
export const DayCostCrewSortOrder = ['lastName', 'firstName'];
export const DayCostCrewUniqComparator = 'id';
export const DefaultBulkInputShiftId = 'temp-bulkInputId';
export const DefaultBulkInputStartCardId = 'temp-startcardId';
export const EasterEggBannerImg = 'stinky-batches.png';
export const EasterEggImg = 'egg.svg';
export const EasterEggPopAudio = 'stinky-batches.mp3';
export const FAQ = 'smarttime/help/FAQ.pdf';
export const HotKeyGuide = 'smarttime/help/Hotkeys and shortcuts PDF.pdf';
export const PayCodeGuide = 'smarttime/help/Paycode List PDF.pdf';
export const MaxGuarDailyHours = 24;
export const MaxGuarWeeklyHours = 168;
export const MaxNoOfStartcardCanSelected = 10;
export const NoteMaxLength = 500;
export const S3BASEURL = 'https://fenix-public-media-dev.s3-us-west-2.amazonaws.com/common/';
export const StandardViewDateFormatString = 'MM/DD/YY';
export const TaxJurisdictionInputLength = 2;
export const TimecardEditReportFileName = 'timecard_edit_report.pdf';
export const DiscrepancyReportFailed = 'Report failed. Please try again.';
export const DiscrepancyReportTimedOut = 'Request has timed out. Please reduce number of selections and try again.';
export const DiscrepancyReportNotFound = 'No discrepancies were found for the selected timecards.';
export const ReportFailedMessage = 'Report failed, please try again';
export const ExportToScenechronizeNotSuccessfulMessage = ' Export to Scenechronize was not successful. Contact your show Admin to ensure you have access to Scenechronize';
export const SelectedTcExportToScenechronizeFailed = 'Selected timecard(s) audit report was not exported to Scenechronize';
export const DocumentUploadTimout = 'This upload has timed out. Please try again';
export const NoProjectAccountCodesWarningMessage = 'Account Coding is not setup correctly in Project Settings, please resolve before creating new entries or contact EP Support';
export const MaxFileSize = 157286400; // 150MB
export const MaximumGLShortcutsAllowed = 150;
export const MaxGLHeaderLength = 50;
export const BatchNoteMaxLength = 1500;
export const MobileNoteMaxLength = 250;
export const EditorToolbarConfig: Toolbar = [
  ['bold', 'italic', 'underline', 'strike'],
  ['ordered_list', 'bullet_list']
];
export const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const DaysOfWeekByPayrollWeekDisplay = (payrollWeekDisplay: DayOfWeek): string[] => {
  const sortedWeekDays = _.clone(daysOfWeek);
  const splicedSortedWeekDays = sortedWeekDays.splice(0, payrollWeekDisplay);
  return _.concat(sortedWeekDays, splicedSortedWeekDays);
};
// Ngrx store action types
export const INIT_ACTION = '@ngrx/store/init';
export const UPDATE_ACTION = '@ngrx/store/update-reducers';
// NgxMask Custom input masks
export const TimeInputMask = '00:00';
export const DateInputMask = '00/00/0000';

export const auditHistoryReportFilenameRegEx = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

export const OverrideReasonMaxLength = 200;

export namespace Constants {

  export class BrowseGridConfig {
    public static get MaxViewPortWidth(): Readonly<number> {
      return 1500;
    }

    public static get ScrollbarWidth(): Readonly<number> {
      return 13;
    }
  }
}
