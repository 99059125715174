import {Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import * as _ from 'lodash';
import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';

import {EmptyProjects, GridColumnConfig, RetrieveProjects} from '../core/actions/project-browse.action';
import {ProjectBrowseConstants} from '../../../constants/project-browse.constants';

export const ProjectBrowseResolver: ResolveFn<boolean> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  store: Store = inject(Store)
): Observable<boolean> => {
  const projectBrowseColConfigs = ProjectBrowseConstants.ProjectBrowse.InternalMasterColumnList;
  store.dispatch(GridColumnConfig({
    all: {
      isShowAllColumns: false,
      groupName: 'Show ALL',
      section: 'all',
      columns: _.keyBy(projectBrowseColConfigs, 'field')
    }
  }));
  store.dispatch(EmptyProjects());
  store.dispatch(RetrieveProjects());
  return of(false);
};
