import {inject} from '@angular/core';
import {Observable} from 'rxjs';
import { Store } from '@ngrx/store';
import {tap} from 'rxjs/operators';
import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';

import {RetrieveDepartmentsSuccessful} from '../actions/lookup.action';
import {ProjectDetailsDataService} from '../shared/data-services/project-details.data.service';
import {getState} from '../shared/utils';
import {selectSelectedProjectId} from '../auth/selectors';
import {Department, DepartmentSetting} from '../../../common/department-setting.model';

export const DepartmentResolver: ResolveFn<Department[]> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  projectSettingService: ProjectDetailsDataService = inject(ProjectDetailsDataService),
  store: Store = inject(Store)
): Observable<Department[]>|Promise<any>|any => {
  const projectId = getState<string>(store, selectSelectedProjectId);
  return projectSettingService.RetrieveDepartmentDetails(projectId).pipe(
    tap(({departments}: DepartmentSetting) => store.dispatch(RetrieveDepartmentsSuccessful(departments)) )
  );
};
