export enum NoteType {
  ALL = 'ALL',
  TIMECARD = 'TIMECARD',
  SHIFT = 'SHIFT',
  DECLINE = 'DECLINE',
  APPROVAL = 'APPROVAL',
  MOBILETIMES = 'MOBILETIMES',
  EDIT = 'EDIT',
  BATCH = 'BATCH',
  STARTCARD = 'STARTCARD',
  PAYMASTER = 'PAYMASTER',
  ALLOWANCE = 'ALLOWANCE'
}

export interface Note {
  timecardId: string;
  shiftId?: string;
  shiftDate?: string;
  message: string;
  noteType: NoteType;
  displayTitle?: string;
  secondaryTitle?: string;
  createdTimestamp?: string;
  createdBy?: string;
}

export interface MobileNote {
  noteId: string;
  shiftDate: string;
  noteText: string;
  noteType: NoteType;
  createdTimestamp?: string;
}

export interface NotesPreview {
  timecardId: string;
  noteCount: number;
  latestNote: string;
}

export interface MobileShiftNoteDetail {
  shiftDate: string;
  mobileTimeId: string;
}
