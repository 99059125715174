import {GLShortcutsViewModel} from '../../../../common/project-setting.model';

export interface ProjectSettingTab {
  name: string;
  path: string;
  qaa: string;
  isDisabled: boolean;
}

export enum ProjectSettingTabs {
  PROJECT_DETAILS = 'PROJECT DETAILS',
  GL_SHORTCUTS = 'GL SHORTCUTS',
  ACCOUNT_CODING = 'ACCOUNT CODING',
  DEPARTMENTS = 'DEPARTMENTS',
  CONTRACTS = 'CONTRACTS',
  APPROVAL_WORKFLOW = 'APPROVAL WORKFLOW',
  SECURITY = 'SECURITY'
}

export interface GlShortcutConfigViewModel {
  projectId: string;
  glShortcuts: GLShortcutsViewModel[];
}
