import {Observable} from 'rxjs';
import * as _ from 'lodash';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {FullUnion, JobDetails} from '../../../../common/contract-rate.model';
import {ApiRoutes} from '../../../../common/api-routes';
import {JobRates} from '../../../../common/start-card.model';


@Injectable({providedIn: 'root'})
export class CMSDataService {
  constructor(private _http: HttpClient) {}
  RetrieveUnions(clientNumber: number): Observable<FullUnion[]> {
    return this._http.get<FullUnion[]>(`${(ApiRoutes.Cms)}/clientNumber/${clientNumber}/unions`);
  }
  RetrievePCCJobsAndSchedules(clientNumber: string, unionId: string, profileId: string, jobCategory?: string, effectiveDate?: string)
    : Observable<JobDetails[]> {
    const options = {
      params: _.pickBy({
        jobCategory,
        effectiveDate
      }, _.identity)};
    return this._http.get<JobDetails[]>(`${ApiRoutes.Cms}/clientNumber/${clientNumber}/unions/${unionId}/profiles/${profileId}/jobsAndSchedules`, options);
  }
  RetrieveCMSRates(clientNumber: string, unionId: string, profileId: string, jobId: string, scheduleId: string, effectiveDate: string): Observable<JobRates> {
    const options = {
      params: _.pickBy({
        effectiveDate
      }, _.identity)};
    return this._http.get<JobRates>(`${ApiRoutes.Cms}/clientNumber/${clientNumber}/unions/${unionId}/profiles/${profileId}/jobs/${jobId}/schedules/${scheduleId}`,
      options);
  }
}

