<cdk-virtual-scroll-viewport class="pb-viewport" #browseScroll itemSize="40" (scrolledIndexChange)="onScrollIndexChange($event)">
  <div class="pb-table">
    <div class="pb-table-header" #header>
      <div class="pb-table-header-cell" *ngFor="let col of columns" [hidden]="!columnVisibility[col.field]">
        <span title="{{col.header}}"
              id="pb-header-{{col.field}}" attr.qaa="pbHeader-{{col.field}}"
              class="st-fs-14 st-font-os-r pb-{{col.field}}">
        {{col.header}}
        </span>
      </div>
    </div>
    <div class="pb-table-body" #container>
      <div *cdkVirtualFor="let projectSummary of projects; templateCacheSize: 0; let rowIndex=index; let odd=odd; trackBy: trackByFn"
           class="pb-table-body-row pb-row"
           id="pb-row-{{projectSummary.projectId}}" attr.qaa="pbRow-{{rowIndex}}">
        <div class="pb-table-body-cell" *ngFor="let col of columns;" [hidden]="!columnVisibility[col.field]">
          <span class="pb-{{col.field}}"
                title="{{projectSummary[col.field]}}"
                id="pb-column-{{col.field}}-{{projectSummary.projectId}}" attr.qaa="pb-{{col.field}}-{{rowIndex}}">
            <st-grid-column-loader [type]="col.field"
                                   [context]="projectSummary"
                                   [config]="columnConfig">
            </st-grid-column-loader>
          </span>
        </div>
      </div>
    </div>
  </div>
</cdk-virtual-scroll-viewport>

<st-create-new-mask
  [isCreateNewMaskEnabled]="(projects|async).length===0"
  [hideCreateButton]="true"
  [message]="'No results found'">
</st-create-new-mask>
