import {createAction, union} from '@ngrx/store';
import {NgbModalName} from '../shared/ngb-modal-name.enum';

export const ResetNgbModals = createAction('[NgbModal] Close All Opened Modals');
export const OpenNgbModal = createAction('[NgbModal] Open Modal',
  (modalName: NgbModalName, payload?: any) => ({modalName, isModalOpen: true, payload}));
export const CloseNgbModal = createAction('[NgbModal] Close Modal',
  (modalName: NgbModalName) => ({modalName, isModalOpen: false, payload: null}));

const actions = union({
  OpenNgbModal,
  CloseNgbModal,
  ResetNgbModals
});
export type NgbModalActions = typeof actions;
