import * as uuid from 'uuid';
import * as _ from 'lodash';
import {Action, createReducer, on} from '@ngrx/store';
import {DocumentErrorTypes, DocumentViewModel} from '../models/document.model';
import {
  AcceptedDocument,
  ChangeDocumentCategory, DocumentUploadError, DocumentUploadSuccessful, EmptyDocuments, InfectedDocumentDeleted,
  RejectDuplicateDocument,
  RejectLargeDocument,
  RejectUnsupportedDocument, RemoveDocument, RetrieveUploadedDocumentSuccessful, TrackDocumentUploadProgress, UpdateDocumentMetadataSuccessful
} from '../actions/document-upload.action';
import {DocumentMetadata} from '../../../common/document.model';

export interface DocumentUploadState {
  documentsInQueue: DocumentViewModel[];
  documentsUploaded: DocumentMetadata[];
}

export const initialState: DocumentUploadState = {
  documentsInQueue: [],
  documentsUploaded: []
};

export const reducer = createReducer(
  initialState,
  on(RejectUnsupportedDocument, (state, {file, errorType, category, status}) => ({
    ...state,
    documentsInQueue: [
      ...state.documentsInQueue,
      {file, errorType, category, status, documentId: uuid.v4()}
    ]
  })),
  on(RejectDuplicateDocument, (state, {file, errorType, category, status}) => ({
    ...state,
    documentsInQueue: [
      ...state.documentsInQueue,
      {file, errorType, category, status, documentId: uuid.v4()}
    ]
  })),
  on(RejectLargeDocument, (state, {file, errorType, category, status}) => ({
    ...state,
    documentsInQueue: [
      ...state.documentsInQueue,
      {file, errorType, category, status, documentId: uuid.v4()}
    ]
  })),
  on(AcceptedDocument, (state, {file, category, status}) => ({
    ...state,
    documentsInQueue: [
      ...state.documentsInQueue,
      {file, category, status, documentId: uuid.v4()}
    ]
  })),
  on(ChangeDocumentCategory, (state, {documentId, category}) => ({
    ...state,
    documentsInQueue: _.map(state.documentsInQueue, (document) =>
      document.documentId === documentId ? {...document, category} : document )
  })),
  on(TrackDocumentUploadProgress, (state, {documentId, progress: uploadProgress}) => ({
    ...state,
    documentsInQueue: _.map(state.documentsInQueue, (document) =>
      document.documentId === documentId ? {...document, uploadProgress} : document )
  })),
  on(DocumentUploadSuccessful, (state, {documentId, documentMetadata}) => ({
    ...state,
    documentsInQueue: _.filter(state.documentsInQueue, (document) => document.documentId !== documentId ) ?? [],
    documentsUploaded: _.sortBy([documentMetadata, ...(state.documentsUploaded ?? [])], 'fileName')
  })),
  on(DocumentUploadError, (state, {documentId}) => ({
    ...state,
    documentsInQueue: _.map(state.documentsInQueue, (document) =>
      document.documentId === documentId ? {...document, uploadProgress: 0, errorType: DocumentErrorTypes.UPLOAD_ERROR} : document )
  })),
  on(EmptyDocuments, (state) => ({
    ...state,
    documentsInQueue: []
  })),
  on(RemoveDocument, (state, {documentId}) => ({
    ...state,
    documentsInQueue: _.filter(state.documentsInQueue, (document) => document.documentId !== documentId)
  })),
  on(RetrieveUploadedDocumentSuccessful,
    UpdateDocumentMetadataSuccessful,
    (state, {documentsUploaded}) => ({
    ...state,
    documentsUploaded
  })),
  on(InfectedDocumentDeleted, (state, {documentId}) => ({
    ...state,
    documentsUploaded: _.map(state.documentsUploaded, (documentMetadata) =>
      documentMetadata.documentId === documentId
        ? {...documentMetadata, isInfected: true}
        : documentMetadata)
  }))
);

export function documentStateReducer(state = initialState, action: Action): DocumentUploadState {
  return reducer(state, action);
}
