import * as _ from 'lodash';
import {Project} from './project.model';
import {User} from './user.model';
import {ApiTimecardCreation} from './user-summary-model';

export interface UserProfile {
  user: User;
  projects: Project[];
}
export enum Permission {
  NONE = 'NONE',
  READ = 'READ',
  FULL = 'FULL'
}
export enum PermissionType {
  // GENERAL
  USER_CREATION = 'USER_CREATION',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  UNMASK_TAX_IDS = 'UNMASK_TAX_IDS',
  RATES_AND_FINANCIAL_DATA = 'RATES_AND_FINANCIAL_DATA',
  REPORTS = 'REPORTS',
  // PROJECT SETTINGS
  PROJECT_CREATION = 'PROJECT_CREATION',
  PROJECT_DETAILS = 'PROJECT_DETAILS',
  PROJECT_OPTIONS = 'PROJECT_OPTIONS',
  ACCOUNT_CODING = 'ACCOUNT_CODING',
  DEPARTMENT_MANAGEMENT = 'DEPARTMENT_MANAGEMENT',
  CONTRACTS = 'CONTRACTS',
  APPROVAL_MANAGEMENT = 'APPROVAL_MANAGEMENT',
  ROUTE_TO_APPROVERS = 'ROUTE_TO_APPROVERS',
  // START CARD
  START_CARD = 'START_CARD',
  // TIMECARD
  DAILY_TIME_SHEET = 'DAILY_TIME_SHEET',
  TIMECARD_INTERNAL = 'TIMECARD_INTERNAL',
  TIMECARD_EXTERNAL = 'TIMECARD_EXTERNAL',
  TIMECARD = 'TIMECARD',
  TIMECARD_APPROVALS = 'TIMECARD_APPROVALS',
  // BATCH
  BATCH_MANAGEMENT = 'BATCH_MANAGEMENT',
  BATCH_MANAGEMENT_INTERNAL = 'BATCH_MANAGEMENT_INTERNAL',
  BATCH_MANAGEMENT_EXTERNAL = 'BATCH_MANAGEMENT_EXTERNAL',
  SUBMIT_BATCH = 'SUBMIT_BATCH',
  SUBMIT_BATCH_INTERNAL = 'SUBMIT_BATCH_INTERNAL',
  SUBMIT_BATCH_EXTERNAL = 'SUBMIT_BATCH_EXTERNAL',
  // MFA
  MFA_MANAGEMENT = 'MFA_MANAGEMENT',
  // PENSION OVERRIDE
  PENSION_OVERRIDE_RELEASE = 'PENSION_OVERRIDE_RELEASE',
  // IMPORT STARTCARD FLAG
  IMPORT_STARTCARD = 'IMPORT_STARTCARD',
  IMPORT_STARTCARD_BULK = 'IMPORT_STARTCARD_BULK',
}

export enum MobilePermissionType {
  CREATE_TIMES = 'CREATE_TIMES',
  APPROVE_TIMES = 'APPROVE_TIMES',
  DAILY_TIMES = 'DAILY_TIMES'
}

export type PermissionMap = { [key in PermissionType]?: Permission};
export type MobilePermissionMap = { [key in MobilePermissionType]?: Permission};
/* e.g.
const test: PermissionMap = {
  [PermissionType.ACCOUNT_CODING]: Permission.READ,
  [PermissionType.USER_MANAGEMENT]: Permission.READ
};
*/

export interface BasePermissions {
  projectId: string;
  firstName: string;
  lastName: string;
  displayName: string;
  jobTitle: string;
  email: string;
  role: string;
  roleId: string;
  permissionTemplateName: string;
  lastLogin: string;
  lastModified: string;
  status: string;
  customPermissions: boolean;
  workflowRoleIds: string[];
  isInternalUser?: boolean;
}

export interface ProjectPermissions extends BasePermissions {
  permissions: PermissionMap;
  assignedDepartmentIds?: string[];
  userEnrollmentConditions: UserEnrollmentCondition[];
}

export interface MobileUserPermissions extends BasePermissions {
  permissions: MobilePermissionMap;
  assignedDepartmentIds: string[];
  timecardCreation?: ApiTimecardCreation;
}

export interface UserEnrollmentCondition {
  conditionId: string;
  condition: string;
  conditionAccepted: boolean;
  conditionAcceptedOn?: string;
}

export interface LiteUserProfile {
  projectDetails: Project;
  projectPermissions: ProjectPermissions;
}

export type ValidatePermission = (projectPermissions: ProjectPermissions) => boolean;
const _normalizeRoleString = (role: string): string  => role?.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();

export const UserIsInRole = (role: string, projectPermissions: ProjectPermissions): boolean =>
  _normalizeRoleString(projectPermissions?.role) === _normalizeRoleString(role);

export const UserHasPermission = (permissionType: PermissionType, permission: Permission, projectPermissions: ProjectPermissions): boolean =>
  projectPermissions.permissions[permissionType] === permission;
export const UserHasPermissions = (permissionType: PermissionType, permissions: Permission[], projectPermissions: ProjectPermissions): boolean =>
  _.some(permissions, (permission: Permission) => permission === projectPermissions.permissions[permissionType]);
