import * as _ from 'lodash';
import { Injectable, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { UiState } from '../../reducers/ui.reducer';
import { ToastTypes } from '../../models/ui.model';
import { DeleteToast } from '../../actions/ui.action';
import { takeWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ToastService implements OnDestroy {
  private _isAlive = true;

  constructor(private _store: Store<UiState>, private _toastr: ToastrService) {
    this._toastr.toastrConfig = _.merge(this._toastr.toastrConfig, {
      maxOpened: 5,
      autoDismiss: true,
      preventDuplicates: true,
      closeButton: true
    });
  }
  ngOnDestroy() {
    this._isAlive = false;
  }
  flash(toastId: string, type: ToastTypes, message: string) {
    switch (type) {
       case ToastTypes.Success: {
         return this._toastr.success(message, null)
           .onShown
           .pipe(takeWhile(() => this._isAlive))
           .subscribe(() => this._dispatchDeleteToast(toastId));
       }
      case ToastTypes.Error: {
        return this._toastr.error(message, null)
          .onShown
          .pipe(takeWhile(() => this._isAlive))
          .subscribe(() => this._dispatchDeleteToast(toastId));
      }
      default: {
        this._toastr.show(message);
      }
     }
  }
  private _dispatchDeleteToast(id: string) {
    this._store.dispatch(DeleteToast(id));
  }
}
