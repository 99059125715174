import {createAction} from '@ngrx/store';

import {Paymaster} from '../../../../common/paymaster.model';
import {ProjectSummary} from '../../../../common/project.summary.model';

export const PaymasterLookup = createAction(
  '[Paymaster Lookup] Paymaster Lookup',
  (paymasters: Paymaster[]) => ({paymasters})
);

export const PaymasterManagerLookup = createAction(
  '[Paymaster Lookup] Paymaster Manager Lookup',
  (paymasterManagers: Paymaster[]) => ({paymasterManagers})
);

export const InvoiceLookup = createAction(
  '[Paymaster Lookup] Invoice Lookup',
  (invoices: string[]) => ({invoices})
);

export const ProjectLookup = createAction(
  '[Paymaster Lookup] Project Lookup',
  (projects: ProjectSummary[]) => ({projects})
);

export const ClearProjectAndInvoiceLookup = createAction(
  '[Paymaster Lookup] Clear Project and Invoice Lookup'
);
