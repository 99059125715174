import {InjectionToken} from '@angular/core';

import {DayOfWeek, Region} from '../common/constants';

export const RegionUIConfigToken = new InjectionToken<DayOfWeek>('RegionUIConfigToken');

export declare interface IRegionUiConfig {
  uiConfig: {
    dateFormat: string;
    startOfWeek: DayOfWeek;
    weekEndingDay: DayOfWeek;
  };
}

export const RegionUiConfig: { [region: string]: IRegionUiConfig } = {
  [Region.GBR]: {
    uiConfig: {
      dateFormat: 'DD/MM/YY',
      startOfWeek: DayOfWeek.Monday,
      weekEndingDay: DayOfWeek.Sunday
    }
  },
  [Region.USA]: {
    uiConfig: {
      dateFormat: 'MM/DD/YY',
      startOfWeek: DayOfWeek.Sunday,
      weekEndingDay: DayOfWeek.Saturday
    }
  }
};
