import * as _ from 'lodash';

const epTitleCaseWithSpecialChar = (specialString: string) => {
   return _.replace(specialString, /\w+/g, _.capitalize);
};
declare module 'lodash' {
  interface LoDashStatic {
    epTitleCaseWithSpecialChar<TValue>(specialString: TValue): string;
  }

  interface LoDashExplicitWrapper<TValue> {
    epTitleCaseWithSpecialChar(specialString: TValue): LoDashExplicitWrapper<string>;
  }
}

_.mixin({epTitleCaseWithSpecialChar: epTitleCaseWithSpecialChar});
