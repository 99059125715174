import {createAction} from '@ngrx/store';

import { WorkLocationViewModel } from '../../../../../common/start-card.model';
import { ShiftUpdate } from '../models/timecard-template-view.model';
import * as fromTimecard from '../../../../../common/timecard.model';
import {Splits} from '../../../../../common/split-coding.model';
import {DayOfWeek} from '../../../../../common/constants';
import { PayCode } from '../../../../../common/pay-code.model';
import {
  AccountCodeValueUpdate,
  GridValueUpdate,
  SummaryAccountCodeValueUpdate,
  SummaryGridValueUpdate,
  SummaryLineUpdate
} from '../../../timecard/models/timecard-entry.view.model';
import {TemplateShifts, TimecardTemplate} from '../../../../../common/template.model';
import {AccountCoding} from '../../../../../common/timecard.model';
import {GLShortcutsViewModel} from '../../../../../common/project-setting.model';

export const PayPunch = createAction(
  '[Timecard Template] Update a Pay Punch',
  (payload: fromTimecard.Punch) => ({payload})
);

export const AccountCode = createAction(
  '[Timecard Template] Update shift AccountCode',
  (payload: AccountCodeValueUpdate) => ({payload})
);
export const UpdateTemplateShiftGLShortcut = createAction(
  '[Timecard Template] Update Template GL Shortcut',
  (shiftId: string, accountCode: AccountCoding) => ({shiftId, accountCode})
);
export const ApplyGLShortcutToTemplateAccountCodes = createAction(
  '[ Timecard Template] Apply GL Shortcut to Template Account Codes',
  (shiftId: string, glShortcut: GLShortcutsViewModel) => ({shiftId, glShortcut})
);

export const Detail = createAction(
  '[Timecard Template] Update work details',
  (payload: GridValueUpdate) => ({payload})
);

export const SummaryDates = createAction(
    '[Timecard Template] Update Summary dates',
    (payload: SummaryGridValueUpdate) => ({payload})
);

export const SummaryBasics = createAction(
  '[Timecard Template] Update Summary Basics',
  (payload: SummaryGridValueUpdate) => ({payload})
);

export const SummaryBasicTaxJurisdiction = createAction(
  '[Timecard Template] Update Summary Line Tax Jurisdiction',
  (payload: SummaryGridValueUpdate) => ({payload})
);

export const Shift = createAction(
  '[Timecard Template] Update Shift',
  (payload: ShiftUpdate) => ({payload})
);

export const EmptyShift = createAction(
  '[Timecard Template] Update shift to EmptyShift with undefined AccountCodes',
  (shiftId: string, defaultAccountCodeSegments: fromTimecard.ValueError<undefined|string>[]) => ({shiftId, defaultAccountCodeSegments})
);

export const UpdateSummaryLine = createAction(
  '[Timecard Template] Update SummaryLine',
  (payload: SummaryLineUpdate) => ({payload})
);

export const DetailWorkLocation = createAction(
  '[Timecard Template] Update shift details tax jurisdiction',
  (payload: GridValueUpdate) => ({payload})
);

export const SelectWorkLocation = createAction(
  '[Timecard Template] Select WorkLocation',
  (workLocation: WorkLocationViewModel) => ({workLocation})
);

export const ShiftWorkLocation = createAction(
  '[Timecard Template] Shift WorkLocation update',
  (shiftId: string, workLocation: WorkLocationViewModel) => ({shiftId, workLocation})
);

export const SummaryWorkLocation = createAction(
  '[Timecard Template] Summary WorkLocation update',
  (summaryId: string, workLocation: WorkLocationViewModel) => ({summaryId, workLocation})
);

export const SummaryAccountCode = createAction(
  '[Timecard Template] Update summary Account Code',
  (payload: SummaryAccountCodeValueUpdate) => ({payload})
);

export const SummaryPayDetails = createAction(
  '[Timecard Template] Update summary Pay Details',
  (payload: SummaryGridValueUpdate) => ({payload})
);

export const AppendSummaryLine = createAction(
  '[Timecard Template] Add new line in summary area'
);

export const SummaryLine = createAction(
  '[Timecard Template] Update summaryItems with new line',
  (summaryId: string, defaultAccountCodeSegments: fromTimecard.ValueError<undefined|string>[]) => ({summaryId, defaultAccountCodeSegments})
);

export const EmptySummaryLine = createAction(
  '[Timecard Template] update summary with empty summaryLine',
  (summaryId: string, defaultAccountCodeSegments: fromTimecard.ValueError<undefined|string>[]) => ({summaryId, defaultAccountCodeSegments})
);

export const DefaultWorkLocationFromLocationFinder = createAction(
  '[Timecard Template] Default WorkLocation update',
  (workLocation: WorkLocationViewModel) => ({workLocation})
);

export const RetrieveNewTimecardTemplate = createAction(
  '[Timecard Template] Retrieve New Timecard Template'
);

export const RetrieveNewTimecardTemplateSuccesful = createAction(
  '[Timecard Template] Retrieve New Timecard Template Successful',
  (timecardTemplate: TimecardTemplate) => ({timecardTemplate})
);

export const RetrieveTimecardTemplate = createAction(
  '[Timecard Template] Retrieve Timecard Template'
);

export const RetrieveTimecardTemplateSuccessful = createAction(
  '[Timecard Template] Retrieve Timecard Template successful',
  (timecardTemplate: TimecardTemplate) => ({timecardTemplate})
);

export const SaveTimecardTemplate = createAction(
  '[Timecard Template] Save Timecard Template'
);

export const SaveTimecardTemplateSuccessful = createAction(
  '[Timecard Template] Save Timecard Template Successful',
  (timecardTemplate: TimecardTemplate) => ({timecardTemplate})
);

export const CopyDownPunches =  createAction(
  '[Timecard Template] Copy Down Punches',
  (punch: fromTimecard.Punch, toShiftId: string) => ({punch, toShiftId})
);

export const CopyDownAccount = createAction(
  '[Timecard Template] Copy Down Account',
  (accountCode: fromTimecard.AccountCoding, toShiftId: string) => ({accountCode, toShiftId})
);

export const CopyDownDetails = createAction(
  '[Timecard Template] Copy Down Details',
  (detail: fromTimecard.CrewDetail, toShiftId: string) => ({detail, toShiftId})
);

export const CopyDownSummaryDate = createAction(
  '[Timecard Template] Copy Down Summary Date',
  (summaryDate: fromTimecard.SummaryDate, toSummaryId: string) => ({summaryDate, toSummaryId})
);

export const CopyDownSummaryBasics = createAction(
  '[Timecard Template] Copy Down Summary Basics',
  (summaryBasics: fromTimecard.SummaryBasics, toSummaryId: string) => ({summaryBasics, toSummaryId})
);

export const CopyDownSummaryAccountCode = createAction(
  '[Timecard Template] Copy Down Summary Account Code',
  (summaryAccountCode: fromTimecard.SummaryAccountCode, toSummaryId: string) => ({summaryAccountCode, toSummaryId})
);

export const CopyDownSummaryPayDetail = createAction(
  '[Timecard Template] Copy Down Summary Pay Detail',
  (summaryPayDetail: fromTimecard.SummaryPayDetail, toSummaryId: string) => ({summaryPayDetail, toSummaryId})
);

export const ToggleLayoffTimecard = createAction(
  '[Timecard Template] Toggle Layoff Timecard'
);

export const ApplySplitCoding = createAction(
  '[Timecard Template] Apply Split Coding',
  (shiftId: string, splits: Splits) => ({shiftId, splits})
);

export const RemoveSplitCoding = createAction(
  '[Timecard Template] Remove Split Coding',
  (shiftId: string) => ({shiftId})
);

export const ResetSplitToCode = createAction(
  '[Timecard Template] Reset Split Coding to Account Code',
  (shiftId: string, defaultAccountCode: any) => ({shiftId, defaultAccountCode})
);

export const ProducerWorkWeek = createAction(
  '[Timecard Template] Producer work week update',
  (producerWorkWeek: DayOfWeek) => ({producerWorkWeek})
);

export const RetrieveSummaryPayCode = createAction(
  '[Timecard Template] Retrieve Summary Pay Code',
  (payload: {summaryId: string, payCode: string}) => ({payload})
);

export const RetrieveSummaryPayCodeSuccessful = createAction(
  '[Timecard Template] Retrieve Summary Pay Code Successful',
  (summaryId: string, payload: PayCode) => ({summaryId, payload})
);

export const RetrieveSummaryPayCodeFailed = createAction(
  '[Timecard Template] Retrieve Summary Pay Code Failed',
  (payload: {summaryId: string, payCode: string, errorMessage: string}) => ({payload})
);

export const SummaryPayType1 = createAction(
  '[Timecard Template] Summary Pay Type 1 updated',
  (payload: {summaryId: string, payType1: string}) => ({payload})
);

export const SummaryPayType2 = createAction(
  '[Timecard Template] Summary Pay Type 2 updated',
  (payload: {summaryId: string, payCode: PayCode}) => ({payload})
);
export const CheckComment = createAction(
  '[Timecard Template] Check Comment',
  (checkComment: string) => ({checkComment})
);

export const UpdateShiftUptoFifth = createAction(
  '[Timecard Template] Update shift upto fifth',
  (shifts: any) => ({shifts})
);

export const UpdateShifts = createAction(
  '[Timecard Template] Update Shifts',
  (shifts: TemplateShifts) => ({shifts})
);
