import {Observable} from 'rxjs';
import { Store } from '@ngrx/store';
import {tap} from 'rxjs/operators';
import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';

import {RetrieveGlShortcutsSuccessful} from '../actions/lookup.action';
import {ProjectDetailsDataService} from '../shared/data-services/project-details.data.service';
import {getState} from '../shared/utils';
import {selectSelectedProjectId} from '../auth/selectors';
import {GLShortcutsViewModel} from '../../../common/project-setting.model';
import {GlShortcutConfigViewModel} from '../settings/models/project-settings-view.model';

export const GlShortcutsResolver: ResolveFn<GLShortcutsViewModel[]> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  projectSettingService: ProjectDetailsDataService = inject(ProjectDetailsDataService),
  store: Store = inject(Store)
): Observable<GLShortcutsViewModel[]>|Promise<any>|any => {
  const projectId = getState<string>(store, selectSelectedProjectId);
  return projectSettingService.RetrieveGlShortcutDetails(projectId).pipe(
    tap(({glShortcuts}: GlShortcutConfigViewModel) => store.dispatch(RetrieveGlShortcutsSuccessful(glShortcuts)))
  );
};
