import {inject} from '@angular/core';
import {Observable, of} from 'rxjs';
import { Store } from '@ngrx/store';
import {catchError, tap} from 'rxjs/operators';

import {RetrieveAllPayCodesFailed, RetrieveAllPayCodesSuccessful} from '../actions/lookup.action';
import {PayCode, PayType2Map} from '../../../common/pay-code.model';
import {LookupDataService} from './lookup.data.service';
import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';

export const PayCodeResolver: ResolveFn<PayType2Map> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  store: Store = inject(Store),
  lookupService: LookupDataService = inject(LookupDataService)
): Observable<PayType2Map>|Promise<any>|any => {
  return lookupService.RetrieveAllPayCodes().pipe(
    catchError(() => {
      store.dispatch(RetrieveAllPayCodesFailed());
      return of();
    }),
    tap((payCodes: PayCode[]) => store.dispatch(RetrieveAllPayCodesSuccessful(payCodes)))
  );
};
