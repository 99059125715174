import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {takeWhile} from 'rxjs/operators';

import {selectIsShowLoadingMask} from '../../../selectors/ui.selectors';
import {UiState} from '../../../reducers/ui.reducer';

@Component({
  selector: 'st-loader',
  template: `
    <div *ngIf="isLoading | async" class="loader-overlay">
      <div class="loader-content loader-center">
        <img src="" class="loader-center" alt=""/>
        <div class="loader-center loader-text">
          <mat-spinner matSuffix diameter="80"></mat-spinner>
        </div>
      </div>
    </div>`,
  styles: []
})
export class LoaderComponent implements OnInit, OnDestroy {
  private _isAlive = true;
  isLoading: Observable<boolean>;
  constructor(private _store: Store<UiState>) {
  }
  ngOnInit(): void {
    this.isLoading = this._store.pipe(
      select(selectIsShowLoadingMask),
      takeWhile(() => this._isAlive)
    );
  }
  ngOnDestroy(): void {
    this._isAlive = false;
  }
}
