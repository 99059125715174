import {InvoiceStatus} from '../../../../common/paymaster-batch.model';

export enum PaymasterSearchTypeModel {
  'clientNumberTxt' = 'clientNumberTxt',
  'projectNameNotAnalyzed' = 'projectNameNotAnalyzed',
  'batchNumbers' = 'batchNos',
  'invoiceNos' = 'invoiceNos'
}
export interface BatchInvoiceDetailsView {
  batchId: string;
  invoiceNumber: string;
  invoiceNumberId: string;
  batchNumber: string;
  invoiceStatus: string;
  isSelected: boolean;
}
export interface BatchInvoiceModalConfig {
  title: string;
  dialogText: string;
  primaryBtnText: string;
  warningMessage: string;
  invoicesToDisable: InvoiceStatus[];
}
