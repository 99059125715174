import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'st-create-new-mask',
  template: `
    <div class="crate-new-mask" *ngIf="isCreateNewMaskEnabled">
      <div class="crate-new-mask-content">
        <div class="text-center st-fs-20">
          <h1><strong>{{message}}</strong></h1>
            <p *ngIf="subMessage">{{subMessage}}</p>
          <button [hidden]="hideCreateButton" class="btn btn-create-new" [disabled]="!isCreateNewBtnEnabled" (click)="onCreateNewClicked()">Create New</button>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .crate-new-mask {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ffffff9c;
      z-index: 2;
    }

    .crate-new-mask-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }

    .btn-create-new {
      font-size: 20px;
      color: #006096;
      background-color: #fff;
    }
    .btn-create-new:disabled {
        color: #7E8080;
    }
  `]
})

export class CreateNewMaskComponent {
  @Input() isCreateNewMaskEnabled: boolean;
  @Input() message: string;
  @Input() hideCreateButton: boolean;
  @Input() isCreateNewBtnEnabled = true;
  @Input() subMessage: string;
  @Output() createNew: EventEmitter<boolean> = new EventEmitter<boolean>();
  onCreateNewClicked() {
    this.createNew.emit(this.isCreateNewMaskEnabled);
  }
}
