import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { GridColumnComponent } from './base-grid-column-component';

@Component({
  selector: 'st-link-button-column',
  template: `<button class="btn btn-link" [routerLink]="url">{{name}}</button>`
})
export class LinkButtonColumnComponent extends GridColumnComponent implements OnInit {
  name = '';
  url = [];
  ngOnInit() {
    this.name = _.get(this.context, this.configPayload.name, '');
    this.url = _.get(this.context, this.configPayload.url, '');
  }
}
