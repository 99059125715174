import { Component } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Permission, ProjectPermissions, ValidatePermission} from '../../../../../common/user-profile.model';

@Component({
  selector: 'st-advanced-reports',
  templateUrl: './advanced-reports.component.html',
  styleUrls: ['./advanced-reports.component.scss']
})
export class AdvancedReportsComponent {
  constructor(private _router: Router, private _route: ActivatedRoute) { }

  onDayCostReportBtnClicked() {
    this._router.navigate(['reports/dayCostReport'], {relativeTo: this._route});
  }

  public permissionToEnableReportsMenu: ValidatePermission = (projectPermissions: ProjectPermissions) => {
    if (!projectPermissions?.permissions) {
      return false;
    }
    return projectPermissions.permissions.REPORTS === Permission.FULL;
  }

}
