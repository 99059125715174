import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {ApiRoutes} from '../../../common/api-routes';
import {PayType2Map, PayCode} from '../../../common/pay-code.model';
import {IWorkStatusCombinations} from '../../../common/work-status.model';
import {ExportReportOption} from '../../../common/report.model';

@Injectable()
export class LookupDataService {
  constructor(private _http: HttpClient) {}

  RetrievePayType2Map(): Observable<PayType2Map> {
    return this._http.get<PayType2Map>(`${ApiRoutes.PayCodes}/payType2`);
  }
  RetrieveAllPayCodes(): Observable<PayCode[]> {
    return this._http.get<PayCode[]>(`${ApiRoutes.PayCodes}/all`);
  }
  RetrieveStudios(searchText: string): Observable<string[]> {
    return this._http.get<string[]>(`${ApiRoutes.Projects}/studios/search?search=${searchText}`);
  }
  RetrieveWorkStatusCombinations(): Observable<IWorkStatusCombinations> {
    return this._http.get<IWorkStatusCombinations>(`${ApiRoutes.WorkStatus}/combinations`);
  }
  RetrieveStartcardExportReportOptions(): Observable<ExportReportOption[]> {
    return this._http.get<ExportReportOption[]>(`${ApiRoutes.Report}/startcard/exportTemplate`);
  }
  RetrievePayrollDistributionExportReportOptions(): Observable<ExportReportOption[]> {
    return this._http.get<ExportReportOption[]>(`${ApiRoutes.Report}/payrollDistribution/exportTemplate`);
  }
}
