import {Observable} from 'rxjs';
import {AccountCodeConfig, GLShortcutsViewModel, ProjectSetting} from '../../../../common/project-setting.model';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { ApiRoutes } from '../../../../common/api-routes';
import {catchError, map, tap} from 'rxjs/operators';
import {ContractDetails} from '../../../../common/contract-rate.model';
import {Paymaster, ProjectPaymasters} from '../../../../common/paymaster.model';
import {Department, DepartmentSetting} from '../../../../common/department-setting.model';
import {ApprovalCondition, ApprovalWorkflowConfig} from '../../../../common/approval-workflow-configs.model';
import {UserRole} from '../../../../common/user-role.model';
import {UserSummary} from '../../../../common/user-summary-model';
import {Project, ProjectSearch, ProjectSummaryResponse} from '../../../../common/project.model';
import {GlShortcutConfigViewModel} from '../../settings/models/project-settings-view.model';
import {LiteBrowseQueryParams} from '../../models/query-params.model';
import {convertFiltersToElasticQuery} from '../utils';

@Injectable({
  providedIn: 'root'
})
export class ProjectDetailsDataService {
  constructor(private http: HttpClient) {}

  RetrieveProjectSettings<T>(projectId: string, mapper: (projectSettings: ProjectSetting) => T): Observable<T> {
    return this.http.get<ProjectSetting>(`${ApiRoutes.Projects}/${projectId}/settings`).pipe(map(mapper));
  }
  RetrieveProjectAccountCodeConfig(projectId: string): Observable<AccountCodeConfig> {
    return this.RetrieveProjectSettings<AccountCodeConfig>(projectId, (projectSettings: ProjectSetting) => {
      return projectSettings.accountCodeConfig;
    });
  }
  PersistProjectSettings<T>(projectId: string, payload: ProjectSetting, resultsMapper: (projectSettings: ProjectSetting) => T): Observable<T> {
    return this.http.put<ProjectSetting>(`${ApiRoutes.Projects}/${projectId}/settings`, payload).pipe(
      catchError((error: any) => {
        throw error;
      }),
      map(resultsMapper)
    );
  }
  RetrieveContractDetails(projectId: string): Observable<ContractDetails[]> {
    return this.http.get<ContractDetails[]>(`${ApiRoutes.Cms}/projects/${projectId}/contracts`);
  }
  RetrieveDepartmentDetails(projectId: string): Observable<DepartmentSetting> {
    return this.http.get<DepartmentSetting>(`${ApiRoutes.Projects}/${projectId}/settings/departments`);
  }
  RetrieveUserDepartmentDetails(projectId: string): Observable<DepartmentSetting> {
    return this.http.get<DepartmentSetting>(`${ApiRoutes.Projects}/${projectId}/userDepartments`);
  }
  SaveDepartment(projectId: string, payload: Department[]): Observable<Department[]> {
    return this.http.put<Department[]>(`${ApiRoutes.Projects}/${projectId}/settings/departments`, payload);
  }
  RetrievePaymasters(): Observable<Paymaster[]> {
    return this.http.get<Paymaster[]>(`${ApiRoutes.Projects}/paymasters`)
      .pipe(
        tap((retrievedPaymasters: Paymaster[]) => {
          sessionStorage.setItem('paymasters', JSON.stringify(retrievedPaymasters));
          return retrievedPaymasters;
        })
      );
  }
  RetrieveProjectPaymasters(): Observable<ProjectPaymasters> {
    return this.http.get<ProjectPaymasters>(`${ApiRoutes.Projects}/projectPaymasters`)
      .pipe(
        tap((projectPaymasters: ProjectPaymasters) => {
          sessionStorage.setItem('paymasters', JSON.stringify(projectPaymasters.paymasters));
        })
      );
  }
  PersistAccountCodeConfig(projectId: string, payload: AccountCodeConfig): Observable<AccountCodeConfig> {
    return this.http.put<AccountCodeConfig>(`${ApiRoutes.Projects}/${projectId}/settings/accountCoding`, payload).pipe(
      catchError((error: any) => {
        throw error;
      })
    );
  }
  DeleteAccountCodeConfig(payload: AccountCodeConfig): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: payload
    };
    return this.http.delete(`${ApiRoutes.Projects}/:projectId/settings/accountCoding`, options).pipe(
      catchError((error: any) => {
        throw error;
      })
    );
  }
  CreateProject(clientId: string): Observable<{projectId: string}> {
    return this.http.post<any>(`${ApiRoutes.Projects}/new`, {clientId});
  }

  DeleteDepartments(projectId: string, payload: Department[]): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: payload
    };
    return this.http.delete(`${ApiRoutes.Projects}/${projectId}/settings/departments`, options).pipe(
      catchError((error: any) => {
        throw error;
      })
    );
  }

  RetrieveApprovalWorkflowConfigs(projectId: string): Observable<ApprovalWorkflowConfig[]> {
    return this.http.get<ApprovalWorkflowConfig[]>(`${ApiRoutes.ApprovalSummaries}/projects/${projectId}/configs`);
  }

  RetrieveApprovalUsers(projectId: string): Observable<UserSummary[]> {
    return this.http.get<UserSummary[]>(`${ApiRoutes.ApprovalSummaries}/projects/${projectId}/approvalUsers`);
  }

  RetrieveApprovalRoles(projectId: string): Observable<UserRole[]> {
    return this.http.get<UserRole[]>(`${ApiRoutes.ApprovalSummaries}/projects/${projectId}/approvalRoles`);
  }
  RetrieveApprovalConditions(projectId: string): Observable<ApprovalCondition[]> {
    return this.http.get<ApprovalCondition[]>(`${ApiRoutes.ApprovalSummaries}/projects/${projectId}/approvalConditions`);
  }

  RetrieveProjectByClientId(clientId: string): Observable<ProjectSetting> {
    return this.http.get<ProjectSetting>(`${ApiRoutes.Projects}/clients/${clientId}`);
  }

  CopyDepartments(projectId: string, sourceProjectId): Observable<Department[]> {
    return this.http.put<Department[]>(`${ApiRoutes.Projects}/${projectId}/settings/departments/${sourceProjectId}/copyDepartments`, {});
  }
  SearchProjectByClientId(clientId: string): Observable<ProjectSearch> {
    return this.http.get<ProjectSearch>(`${ApiRoutes.Projects}/clients/${clientId}/search`);
  }
  RefreshProjectDetails(projectId: string, clientId: string): Observable<{projectSetting: ProjectSetting, projects: Project[]}> {
    return this.http.post<{projectSetting: ProjectSetting, projects: Project[]}>(`${ApiRoutes.Projects}/${projectId}/settings/refreshDetails`, {clientId});
  }

  RetrieveGlShortcutDetails(projectId: string): Observable<GlShortcutConfigViewModel> {
    return this.http.get<GlShortcutConfigViewModel>(`${ApiRoutes.Projects}/${projectId}/settings/glshortcuts`);
  }
  updateGlShortcut(projectId: string, payload: GLShortcutsViewModel[]): Observable<GlShortcutConfigViewModel> {
    return this.http.put<GlShortcutConfigViewModel>(`${ApiRoutes.Projects}/${projectId}/settings/glshortcuts`, payload);
  }
  RetrieveAccountCodingUpdateStatus(projectId: string): Observable<{glUpdateInprogress: boolean}> {
    return this.http.get<{glUpdateInprogress: boolean}>(`${ApiRoutes.Projects}/${projectId}/accountCodingUpdateStatus`);
  }
  UpdateStartcardAccountCoding(projectId: string, updateWithDefaultValue: boolean, payload: AccountCodeConfig): Observable<AccountCodeConfig> {
    return this.http.put<AccountCodeConfig>(`${ApiRoutes.Projects}/${projectId}/settings/startcardAccountCoding?updateWithDefaultValue=${updateWithDefaultValue}`, payload);
  }
  RetrieveProjectSummary(queryParams: LiteBrowseQueryParams): Observable<ProjectSummaryResponse> {
    const elasticQuery = convertFiltersToElasticQuery(queryParams);
    return this.http.get<ProjectSummaryResponse>(`${ApiRoutes.Projects}/search?${elasticQuery}`);
  }
  RetrieveUnprocessedTimecardCount(projectId: string, departmentId: string): Observable<{unprocessedTimecardCount: number}> {
    return this.http.get<{unprocessedTimecardCount: number}>(`${ApiRoutes.Timecard}/projects/${projectId}/departments/${departmentId}/timecardCount`);
  }
}
