import {inject} from '@angular/core';
import {forkJoin, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {ResolveFn} from '@angular/router';

import {TimecardLookupData, TimecardTypes} from '../../../common/timecard.model';
import {TimecardLookupService} from '../shared/providers/timecard-lookup.service';
import {RetrieveTimecardLookupSuccessful} from '../actions/lookup.action';

export const TimecardLookupDataResolver: ResolveFn<{ [key in keyof typeof TimecardTypes]?: TimecardLookupData }> = (
): Observable<{ [key in keyof typeof TimecardTypes]?: TimecardLookupData }> => {
  const timecardLookupService: TimecardLookupService = inject(TimecardLookupService);
  const store: Store = inject(Store);
  return forkJoin([
    timecardLookupService.retrieveTimecardLookupData(),
    timecardLookupService.retrieveSagTimecardLookupData()
  ]).pipe(
    map(([crewLookup, sagLookup]): { [key in keyof typeof TimecardTypes]?: TimecardLookupData } => ({
        CREW: crewLookup,
        SAG: sagLookup
    })),
    tap((timecardLookup: { [key in keyof typeof TimecardTypes]?: TimecardLookupData }) => {
      store.dispatch(RetrieveTimecardLookupSuccessful(timecardLookup));
    })
  );
};
