import {inject} from '@angular/core';
import {Store} from '@ngrx/store';
import {catchError, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';

import {Paymaster} from '../../../common/paymaster.model';
import {RetrievePaymasterSuccessful} from '../actions/lookup.action';
import {ProjectDetailsDataService} from '../shared/data-services/project-details.data.service';

export const PaymasterResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  store: Store = inject(Store),
  projectDataService: ProjectDetailsDataService = inject(ProjectDetailsDataService)
): Observable<any> => {
  return projectDataService.RetrievePaymasters().pipe(
    catchError(() => {
      return of();
    }),
    tap((paymasters: Paymaster[]) => store.dispatch(RetrievePaymasterSuccessful(paymasters)))
  );
};
