import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FilterPanelService } from '../../providers/filter-panel.service';

@Component({
  selector: 'st-filter-panel',
  providers: [FilterPanelService],
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterPanelComponent {
  @Output() clearFilters = new EventEmitter();
  @Output() panelClosed = new EventEmitter();

  onClearFilters() {
    this.clearFilters.emit();
    this.closePanel();
  }
  closePanel() {
    this.panelClosed.emit();
  }
}

