import {
  AccountCode,
  AccountCoding,
  CrewDetail,
  Punch, SagDetail,
  ShiftDate,
  ShiftRate,
  SummaryAccountCode,
  SummaryBasics,
  SummaryDate,
  SummaryJobDetail,
  SummaryPayDetail,
  ValueError,
  WorkStatus
} from '../../../../common/timecard.model';
import {Split} from '../../../../common/split-coding.model';
import {Note} from '../../../../common/timecard-note.model';

export interface GridValueUpdate {
  shiftId: string;
  [fieldName: string]: string|number|boolean|ValueError<string>|ValueError<number>;
}
export interface SummaryGridValueUpdate {
  summaryId: string;
  [fieldName: string]: string|number|ValueError<string>|ValueError<number|null>;
}

export interface AccountCodeValueUpdate {
  shiftId: string;
  segmentIndex: number;
  value: ValueError<string>;
}

export interface GlshortcutValueUpdate {
  shiftId: string;
  accountCode: AccountCode;
}

export interface AccountSplitUpdate {
  shiftId: string;
  splits: Split[];
}

export interface SummaryAccountCodeValueUpdate {
  summaryId: string;
  segmentIndex: number;
  value: ValueError<string>;
}

export interface Clipboard {
  shift?: Shift;
  summaryLine?: SummaryLine;
}
export interface ShiftUpdate {
  shiftId: string;
  shift: Shift;
}
export interface SummaryLineUpdate {
  summaryId: string;
  summaryLine: SummaryLine;
}
export interface Shift {
  shiftId: string;
  shiftDate?: ShiftDate;
  shiftRate?: ShiftRate;
  workDetail?: CrewDetail|SagDetail;
  accountCode?: AccountCoding;
  payPunch?: Punch;
  prPunch?: Punch;
  mobilePunch?: Punch;
  workStatus?: WorkStatus;
}

export interface SummaryLine {
  summaryId: string;
  date?: SummaryDate;
  basics?: SummaryBasics;
  accountCode?: SummaryAccountCode;
  jobDetail?: SummaryJobDetail;
  payDetail?: SummaryPayDetail;
}

export interface WorkDetailLocation {
  countryCode: string;
  stateCode: string;
  countyCode: string;
  cityCode: string;
}

export enum TCQuickActionResponse {
  Copy = 1,
  Paste = 2,
  Clear = 3
}

export enum PunchType {
  DailyTimePunches = 'Daily Time Punches',
  MobileTimePunches = 'Mobile Time Punches'
}

export enum PunchTab {
  Pay_Times = 'PAY TIMES',
  Daily_Times = 'DAILY TIMES',
  Mobile_Times = 'MOBILE TIMES'
}

export interface TcSummary {
  batchName: string;
  status: string;
  isSubmitted: boolean;
}

export interface WorkLocationUpdate {
  [fieldName: string]: string|ValueError<string>;
}

export interface AutoApplyTimecardTemplate {
  templateName: string;
  isAutoApply: boolean;
}

export interface TimecardNote {
  count: number;
  latestNotes: string;
  allNotes: Note[];
}

export interface Discrepancy {
  punch: Punch;
  workDetail: CrewDetail;
  date: string;
  splitShiftOrdinal?: number;
}

export interface DiscrepancyViewModel {
  [shiftDate: string]: Discrepancy[];
}
