import {Region} from '../common/constants';
import {GridColumn} from '../client/app/shared/components/grid-column-filter/grid-column-filter-view.model';
import {PaginationParams} from '../client/app/models/query-params.model';
import {BatchStatus} from '../common/batch.model';
import {FilterOption} from '../client/app/shared/components/filter-panel/filter-item.model';
import {BatchSummaryQueryParams} from '../client/app/batch/models/batch-query-params.model';
import {BatchWTCReport} from '../common/report.model';
import {BatchBrowseQueryParams} from '../client/app/batch/reducers/batch-summary.reducer';

export namespace BatchBrowseConstants {
  export const BatchBrowseUiColumns: { [region: string]: Array<{field: string; header: string}> } = {
    [Region.GBR]: [
        {field: 'batchName', header: 'Batch Name'},
        {field: 'payPeriodEnding', header: 'Pay Period Ending'},
        {field: 'timecards', header: 'Timecards'},
        {field: 'issues', header: 'Issues'},
        {field: 'approved', header: 'Approved'},
        {field: 'invoice', header: 'Invoice'},
        {field: 'status', header: 'Status'},
        {field: 'createdBy', header: 'Created By'}
    ],
    [Region.USA]: [
      {field: 'batchName', header: 'Batch Name'},
      {field: 'payPeriodEnding', header: 'Pay Period Ending'},
      {field: 'timecards', header: 'Timecards'},
      {field: 'issues', header: 'Issues'},
      {field: 'approved', header: 'Approved'},
      {field: 'invoice', header: 'Invoice'},
      {field: 'status', header: 'Status'},
      {field: 'received', header: 'Received'},
      {field: 'deliveryMethod', header: 'Delivery Method'},
      {field: 'createdBy', header: 'Created By'}
    ]
  };

  export class BatchBrowse {
    // TODO This should change when implement manage column functionality https://jira.ep.com/browse/STF-477
    public static get DefaultColumnList(): ReadonlyArray<GridColumn> {
      return [
        {field: 'batchName', header: 'Batch Name'},
        {field: 'payPeriodEnding', header: 'Pay Period Ending'},
        {field: 'timecards', header: 'Timecards'},
        {field: 'issues', header: 'Issues'},
        {field: 'approved', header: 'Approved'},
        {field: 'invoice', header: 'Invoice'},
        {field: 'status', header: 'Status'},
        {field: 'received', header: 'Received'},
        {field: 'deliveryMethod', header: 'Delivery Method'},
        {field: 'createdBy', header: 'Created By'}
      ];
    }

    public static get DefaultBatchSummaryPagination(): PaginationParams {
      return {
        offset: 1,
        pageSize: 40
      };
    }

    public static get DefaultBatchSummaryQueryParams(): BatchBrowseQueryParams {
      return {
        ...this.DefaultBatchSummaryPagination,
        miscFilters: [],
        filters: [ {filterType: 'batchStatus', operator: '[eq]', value: [BatchStatus.UNSUBMITTED]} ],
        sort: 'weekEnding[desc];batchNameNotAnalyzed[asc]',
        searchText: ''
      };
    }

    public static get DefaultBatchStatusFilterOption(): FilterOption[] {
      return [
        {id: '0', value: BatchStatus.SUBMITTED, type: 'batchStatus'},
        {id: '1', value: BatchStatus.UNSUBMITTED, type: 'batchStatus'},
        {id: '2', value: BatchStatus.PROCESSED, type: 'batchStatus'}
      ];
    }

    public static get BatchSummaryQueryParamsRetrieveAll(): BatchSummaryQueryParams {
      return {
        ...this.DefaultBatchSummaryPagination,
        offset: -1,
        pageSize: -1,
        includeSubmitted: false,
        includeIntExt: false,
        filters: [],
        sort: '',
        searchText: ''
      };
    }

    public  static get DefaultBatchWtcReportOptions(): BatchWTCReport {
      return {
        projectId: '',
        showAddressInfo: false,
        showDeductions: true,
        showEmployeeFringes: false,
        showEmployerFringes: false,
        showForms: false,
        showIssues: false,
        showNotesAndTotals: true,
        showSignatures: true,
        showStartcardNotes: false,
        showSummaryGrid: true,
        batchIds: [],
        sortBy: 'name',
        showMobileNotes: true
      };
    }

    public static get BatchSummaryFieldsWidthInPx(): Readonly<any> {
      return {
        batchName: 300,
        payPeriodEnding: 170,
        timecards: 110,
        issues: 85,
        approved: 105,
        invoice: 135,
        status: 176,
        received: 170,
        deliveryMethod: 150,
        createdBy: 330
      };
    }

    public static get BatchBrowseMiscFilters(): Readonly<any> {
      return [
        {id: 0, name: 'Approval: Fully Approved', type: 'tcFullyApproved'},
        {id: 1, name: 'Approval: Not Fully Approved', type: 'tcNotFullyApproved'},
        {id: 2, name: 'Mobile: Approved', type: 'mobileApproved'},
        {id: 3, name: 'Mobile: Unapproved', type: 'mobileUnapproved'}
      ];
    }

    public static get BatchBrowseMiscSubFilter(): Readonly<any> {
      return {
        tcFullyApproved: [{ filterType: 'hasPendingApprovalTasks', operator: '[eq]', value: ['No'] }],
        tcNotFullyApproved: [{ filterType: 'hasPendingApprovalTasks', operator: '[eq]', value: ['Yes'] }],
        mobileApproved: [{ filterType: 'mobileApproved', operator: '[eq]', value: ['Yes'] }],
        mobileUnapproved: [{ filterType: 'mobileApproved', operator: '[eq]', value: ['No'] }]
      };
    }
  }
}
