import * as _ from 'lodash';
import * as  moment from 'moment';
import 'moment-timezone';
import {Injectable} from '@angular/core';
import {RouterStateSerializer} from '@ngrx/router-store';
import {Params, RouterStateSnapshot} from '@angular/router';
import {ChangeEvent} from 'ngx-virtual-scroller';
import {select, Store} from '@ngrx/store';
import {take} from 'rxjs/operators';
import {Plugin} from 'prosemirror-state';


import {SectionColumnConfig} from './components/manage-column/manage-column.model';
import {initPendo} from './pendo.util';
import {Constants} from './constants';
import {selectSelectedProject, selectUser} from '../auth/selectors';
import {User} from '../../../common/user.model';
import {Project} from '../../../common/project.model';
import {ApprovalsBrowseQueryParams, LiteBrowseQueryParams, SearchNextPrevQueryParams} from '../models/query-params.model';
import {maxDateYear, militaryTimeFormat, militaryTimeRegex, minDateYear, payrollDateFormat, utcTimestampFormat} from '../../../common/constants';

/**
 * The RouterStateSerializer takes the current RouterStateSnapshot
 * and returns any pertinent information needed. The snapshot contains
 * all information about the state of the router at the given point in time.
 * The entire snapshot is complex and not always needed. In this case, you only
 * need the URL and query parameters from the snapshot in the store. Other items could be
 * returned such as route parameters and static route data.
 */

export interface RouterStateUrl {
  url: string;
  projectId: string;
  timecardId: string;
  params: Params;
  queryParams: Params;
}
@Injectable({
  providedIn: 'root'
})
export class CustomRouterStateSerializer
  implements RouterStateSerializer<RouterStateUrl> {
  constructor(private _store: Store<any>) {}
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    const { url, root: { queryParams } } = routerState;
    const { params } = route;
    const projectId = _.get(route, 'root.firstChild.paramMap.params.projectId', '');
    const timecardId = _.get(route, 'params.timecardId', '');
    const user: User = getState(this._store, selectUser);
    const currentProject: Project = getState<Project>(this._store, selectSelectedProject);
    initPendo(user, currentProject);
    return { url, projectId, timecardId, params, queryParams };
  }
}

export const trackByFn = index => index;

export const getPaginationOffset = (data: any[], pageSize: number) => {
  const incerementPageBy = 1;
  return Math.ceil(data.length / pageSize) + incerementPageBy;
};

export const isVirtualScrollEnd = (event: ChangeEvent, arraySize) => {
  return event.endIndex + 1 !== arraySize || event.startIndex === -1;
};

export const getColsTotalWidth = (gridColWidth): number => _.sum(Object.values(gridColWidth));
export const getBrowseGridWidthAndOffset = (screenWidth, gridColWidth) => {
  const maxViewPortWidth = Constants.BrowseGridConfig.MaxViewPortWidth;
  const scrollbarWith = Constants.BrowseGridConfig.ScrollbarWidth;
  const colsTotalWidth = getColsTotalWidth(gridColWidth);
  let offsetWidth = screenWidth > maxViewPortWidth ? (colsTotalWidth > maxViewPortWidth ? 0 : maxViewPortWidth - colsTotalWidth) : 0;
  if (offsetWidth) {
    offsetWidth -= scrollbarWith;
  }
  const gridColTotalWidth = screenWidth > maxViewPortWidth ? maxViewPortWidth : screenWidth;
  return { gridColTotalWidth, offsetWidth };
};

export const getState = <T>(store: Store<any>, selectorFn: any, props?: any): T => {
  let _val: T = null;
  store.pipe(
    take(1),
    select(selectorFn, props)
  ).subscribe((value: T) => _val = value);
  return _val;
};
export const applyIsDirty = <T>(isDirtyPropName: string, previousState: T, newState: T): T  => {
  // if there's no isDirty property in the state, return w/o adding isDirty value.
  if (!newState.hasOwnProperty(isDirtyPropName)) {
    return newState;
  }
  if (!_.isEqual(previousState, newState)) { // is Dirty
    return {
      ...newState,
      [isDirtyPropName]: true
    };
  }
  return newState;
};
export const initializeSessionPOJO = <T>(key: string, defaultValue: T): T => {
  // check if item exists, if item does not exists already, initialize it with devalue value
  const item = sessionStorage.getItem(key);
  if (item === null || typeof(item) === 'undefined') {
    setSessionPOJO(key, defaultValue);
    return defaultValue;
  }
  return getSessionPOJO(key);
};
export const setSessionPOJO = <T>(key: string, obj: T): void => {
  sessionStorage.setItem(key, JSON.stringify(obj));
};
export const getSessionPOJO = <T>(key: string): T => {
  const value = sessionStorage.getItem(key);
  if (value === null || typeof(value) === 'undefined') {
    return null;
  }
  const obj: T = <T>_.attempt(JSON.parse, value);
  if (_.isError(obj)) {
    return null;
  }
  return obj;
};
export const hasDeepProperty = <T>(obj: T, prop: string): boolean =>  JSON.stringify(obj).search(`"${prop}":`) > -1;

export const convertQueryParamsToElasticQuery = (queryParam: LiteBrowseQueryParams | SearchNextPrevQueryParams): string => _
  .chain(queryParam)
  .keys()
  .map(key => {
    if (_.isObject(queryParam[key])) {
      return `${key}=${_.map(queryParam[key], (value, filterKey) =>
        encodeURIComponent(`${filterKey}[eq]${value}`)
      ).join(';')}`;
    }
    return `${encodeURIComponent(key)}=${encodeURIComponent(queryParam[key])}`;
  })
  .join('&')
  .value();

export const convertFiltersToElasticQuery = (queryParam: LiteBrowseQueryParams | SearchNextPrevQueryParams | ApprovalsBrowseQueryParams): string => _
  .chain(queryParam)
  .keys()
  .map(key => {
    if (_.isObject(queryParam[key])) {
      return `${key}=${_.map(queryParam[key], (filter, filterKey) =>
        encodeURIComponent(`${filter.filterType}${filter.operator}${filter.value}`)
      ).join(';')}`;
    }
    return `${encodeURIComponent(key)}=${encodeURIComponent(queryParam[key])}`;
  })
  .join('&')
  .value();

export const convertFiltersToFilterQueryParam = (queryParam: LiteBrowseQueryParams): string => {
  const elasticQueryParams = convertFiltersToElasticQuery(queryParam);
  const searchParams = new URLSearchParams(elasticQueryParams);
  return searchParams.get('filters') || '';
};

export const fixDateFormat = (date: string, outputFormat: string, expectedInputFormats: string[]): string => {
  const correctInputFormat = _
    .chain(expectedInputFormats)
    .map((inputFormat) => moment(date, inputFormat).format(inputFormat) === date
      ? inputFormat
      : ''
    )
    .filter((value) => !_.isEmpty(value))
    .head() // there should only be one.
    .value();
  if (_.isEmpty(correctInputFormat)) { // for some reason, we cannot find the correct input format (something unexpected)
    return moment(date).format(outputFormat); // let moment figure out what format it is, and return that
  }
  return moment(date, correctInputFormat).format(outputFormat);
};

export const formatBytes = (bytes: number): string => {
  if (bytes === 0) {
    return '0 BYTES';
  }
  const k = 1024;
  const sizes = ['BYTES', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

export const convertHtmlToText = (htmlText: string): string => {
  const oParser = new DOMParser();
  const oDOM = oParser.parseFromString(htmlText, 'text/html');
  return oDOM.body.textContent;
};

export const editorMaxLengthPlugin = (maxLength: number) => { // solution reference : https://github.com/ueberdosis/tiptap/issues/629
  return  new Plugin({
    appendTransaction: (transaction, oldState, newState) => {
      const oldLength = oldState.doc.content.size;
      const newLength = newState.doc.content.size;
      if (newLength > maxLength && newLength > oldLength) {
        const newTransaction = newState.tr;
        newTransaction.insertText('', maxLength + 1, newLength);
        return newTransaction;
      }
    }
  });
};

export const isValidPayrollDate = (date: string): boolean => {
  const val = moment(date, payrollDateFormat, true);
  return val.isValid() && _.inRange(val.year(), minDateYear, maxDateYear);
};

export const isValidMilitaryTime = (time: string): boolean => {
  return new RegExp(`^${militaryTimeRegex}$`).test(time);
};

export const convertLocalTimeToUTC = (localTime: string): string => {
  if (_.isEmpty(localTime)) {
    return '';
  }
  return moment(localTime, `${payrollDateFormat} ${militaryTimeFormat}`).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).utc().format(utcTimestampFormat);
};

export const getDateTimeFromUTC = (time: string, format: string): string => {
  if (_.isEmpty(time)) {
    return '';
  }
  const value = moment(time).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(format);
  return value === '00:00' ? '' : value;
};

export const convertContentToDocument = (displayName, fileType, content) => {
  const byteString = window.atob(content);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const downloadLink = document.createElement('a');
  const blob = new Blob([int8Array], { type: fileType });
  downloadLink.href = window.URL.createObjectURL(blob);
  downloadLink.setAttribute('download', `${displayName}`);
  document.body.appendChild(downloadLink);
  downloadLink.click();
};

export const isDate1BeforeDate2 = (date1: string, date2: string, dateFormat: string): boolean => {
  return moment(date1, dateFormat).isBefore(moment(date2, dateFormat));
};

export const getLastOrdinal = (section: SectionColumnConfig): number => {
  return _
    .chain(section)
    .flatMap()
    .reduce((acc, curr) => (acc.ordinal > curr.ordinal ? acc : curr))
    .value()
    .ordinal;
};
// ToDo : keyCode in KeyboardEvent is deprecated, Need to fix
export const AllowNumericKeyPresses = ($event: KeyboardEvent) => {
  const keyCode = $event.keyCode;
  // Accept only numeric values and filter out other values
  return ( keyCode >= 48 && keyCode <= 57 && (<number>_.get($event, 'target.value.length', 0)) === 6); // Changing the digit count to 6 for Stunt Adj
};

export const convertTimecardFiltersToElasticQuery = (queryParam: LiteBrowseQueryParams | SearchNextPrevQueryParams | ApprovalsBrowseQueryParams): string => _
  .chain(queryParam)
  .keys()
  .map(key => {
    if (_.isObject(queryParam[key])) {
      return `${key}=${_.map(queryParam[key], (filter) => {
          return encodeURIComponent(`${filter.filterType}${filter.operator}${filter?.value?.join('|')}`);
        }
      ).join(';')}`;
    }
    return `${encodeURIComponent(key)}=${encodeURIComponent(queryParam[key])}`;
  })
  .join('&')
  .value();
