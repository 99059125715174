import * as _ from 'lodash';
import {
  Logout,
  Paymaster,
  ProjectPermissions,
  ProjectSettings,
  RetrieveClientConfigSuccessful,
  RetrieveProjects,
  RetrieveProjectsFailed,
  RetrieveProjectsSuccessful,
  RetrieveUser,
  RetrieveUserDepartmentsSuccessful,
  RetrieveUserFailed,
  RetrieveUserSuccessful,
  RetrieveVaEnableStatusSuccess,
  SelectedProject
} from '../actions/auth';
import {User} from '../../../../common/user.model';
import {Project} from '../../../../common/project.model';
import {ProjectPermissions as UserProjectPermission} from '../../../../common/user-profile.model';
import {ProjectSetting} from '../../../../common/project-setting.model';
import {Action, createReducer, on} from '@ngrx/store';
import {ClientConfig} from '../../../../common/client-config.model';

export interface State {
  user: User | null;
  projects: Project[] | null;
  projectPermissions: UserProjectPermission;
  projectSettings: ProjectSetting;
  config: ClientConfig;
  selectedProject: Project;
  isVaEnabled: boolean; // To check the current project is VA Enabled
}

export const initialState: State = {
  user: null,
  projects: null,
  projectPermissions: null,
  projectSettings: null,
  config: null,
  selectedProject: null,
  isVaEnabled: false
};

export const reducer = createReducer(
  initialState,
  on(RetrieveUser, (state) => ({
    ...state
  })),
  on(RetrieveUserSuccessful, (state, {payload}) => ({
    ...state,
    user: payload.user,
    projects: payload.projects
  })),
  on(RetrieveUserFailed, (state) => ({
    ...state,
    user: null
  })),
  on(Logout, () => {
    return initialState;
  }),
  on(RetrieveProjects, (state) => ({
    ...state
  })),
  on(RetrieveProjectsSuccessful, (state, {projects}) => ({
    ...state,
    projects: _.sortBy(projects, 'projectname')
  })),
  on(RetrieveProjectsFailed, (state) => ({
    ...state
  })),
  on(ProjectPermissions, (state, {projectPermissions}) => ({
    ...state,
    projectPermissions
  })),
  on(ProjectSettings, (state, {projectSettings}) => ({
    ...state,
    projectSettings
  })),
  on(Paymaster, (state, {paymaster}) => ({
    ...state,
    projectSettings: {
      ...state.projectSettings,
      paymaster
    }
  })),
  on(RetrieveClientConfigSuccessful, (state, {clientConfig}) => ({
    ...state,
    config: {...clientConfig}
  })),
  on(SelectedProject, (state, {selectedProject}) => ({
    ...state,
    selectedProject
  })),
  on(RetrieveUserDepartmentsSuccessful, (state, {departments}) => ({
    ...state,
    user: {
      ...state.user,
      departments
    }
  })),
  on(RetrieveVaEnableStatusSuccess, (state, {responseData}) => ({
    ...state,
    isVaEnabled: responseData
  }))
);

export function AuthReducer(state = initialState, action: Action) {
  return reducer(state, action);
}
