import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';

import {TaxJurisdictionLookupService} from '../shared/providers/tax-jurisdiction-lookup.service';

export const TaxJurisdictionLookupResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  taxJurisdictionLookupService: TaxJurisdictionLookupService = inject(TaxJurisdictionLookupService)
): void => {
  taxJurisdictionLookupService.RetrieveTaxJurisdiction().subscribe();
};
