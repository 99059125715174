import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {FilterOption} from '../filter-panel/filter-item.model';
import {FilterConfig, TitleOption} from './dropdown-filter.model';
import {NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import {trackByFn} from '../../utils';
import {merge, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, takeWhile} from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'st-dropdown-filter',
  templateUrl: './dropdown-filter.component.html',
  styleUrls: ['./dropdown-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownFilterComponent implements OnDestroy {
  @Input() filterType: TitleOption;
  @Input() titleOptions: TitleOption[] = [];
  @Input() options: FilterOption[] = [];
  @Input() selectedItems: FilterOption[] = [];
  @Input() minSearchStringLength = 2;
  @Input() tabIndex: number;
  @Input() disableInput = false;
  @Output() filterTypeChange = new EventEmitter<TitleOption>();
  @Output() addFilter = new EventEmitter<FilterConfig>();
  @Output() removeFilter = new EventEmitter<FilterConfig>();
  @ViewChild('instance') instance: NgbTypeahead;
  inputFocus = new Subject<string>();
  inputClick = new Subject<string>();
  showClearSearchStringButton = false;
  searchString = '';
  trackByFn = trackByFn;
  private _isAlive = true;

  ngbSearch = (searchString: Observable<string>) => {
    const debouncedSearchString = searchString
      .pipe(
        debounceTime(200),
        filter((query: string) => query.length >= this.minSearchStringLength  || query.length === 0),
        distinctUntilChanged());
    const clicksWithClosedPopup = this.inputClick.pipe(filter(() => !this.instance.isPopupOpen()));

    return merge(debouncedSearchString, this.inputFocus, clicksWithClosedPopup).pipe(
      takeWhile(() => this._isAlive),
      map(term => this.options.filter(option => {
        return option.value.toLowerCase().indexOf(term.toLowerCase()) > -1 &&
          this.selectedItems.findIndex((selected) => selected.id === option.id) === -1;
      })),
    );
  }

  formatter = (result: FilterOption) => result.value;

  clearSearchString(): void {
    this.searchString = '';
    this.showClearSearchStringButton = false;
  }

  onAddFilter(event: any) {
    event.preventDefault();
    this.addFilter.emit({filterOption: event.item, filterType: this.filterType});
    this.clearSearchString();
  }

  onRemoveFilter(filterOption: FilterOption) {
    this.removeFilter.emit({filterOption, filterType: this.filterType});
  }

  onKeyUp() {
    this.showClearSearchStringButton = _.get(this.searchString, 'length', 0) > 0;
  }

  ngOnDestroy(): void {
    this._isAlive = false;
  }

  onFilterTypeChanged(type: string) {
    this.filterType = _.find(this.titleOptions, _.matchesProperty('value', type));
    this.clearSearchString();
    this.filterTypeChange.emit(this.filterType);
  }
}
