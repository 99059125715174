import {createAction} from '@ngrx/store';
import * as _ from 'lodash';

import {ApprovalSummary} from '../../../../common/approval-summary.model';
import * as fromUIModel from '../../models/ui.model';
import {ApprovalTaskLite, TimecardBulkActionResponse} from '../../../../common/timecard.model';
import {FilterParams} from '../../models/query-params.model';
import {NgbModalName} from '../../shared/ngb-modal-name.enum';
import {FilterOption} from '../../shared/components/filter-panel/filter-item.model';
import {ApprovalsBrowseViewModel} from '../models/approvals-browse-view.model';
import {ApprovalBrowseConstants} from '../../../../constants/approval-browse.constants';

const _getDefaultApprovalBrowseColumnConfig = () => {
  return ({
    crew: {
      ...ApprovalBrowseConstants.ApprovalBrowse.CrewColumnConfigs,
    },
    details: {
      ...ApprovalBrowseConstants.ApprovalBrowse.DetailsColumnConfigs,
    },
    summary: {
      ...ApprovalBrowseConstants.ApprovalBrowse.SummaryColumnConfigs,
    }
  });
};
export const InitializeManageColumnConfigs = createAction(
  '[Approval Browse] Initialize manage column configs',
  () => ({approvalBrowseColumnConfig: _getDefaultApprovalBrowseColumnConfig()}));

export const ApprovalBrowseDeclineTimecards = createAction(
  '[Approval Browse] Decline Timecards',
  (projectId: string, approvalTasks: ApprovalTaskLite[], declineNote: string, modalName: NgbModalName) =>
    ({projectId, approvalTasks, declineNote, modalName})
);
export const ApprovalBrowseDeclineTimecardsSuccessful = createAction(
  '[Approval Browse] Decline Timecards Successful'
);
export const ApprovalBrowseDeclineTimecardsFailed = createAction(
  '[Approval Browse] Decline Timecards Failed',
  (error: any) => ({error})
);

export const ApprovalBrowseApproveTimecards = createAction(
  '[Approval Browse] Approve Timecards',
  (projectId: string, approvalTasks: ApprovalTaskLite[]) =>
    ({projectId, approvalTasks})
);
export const ApprovalBrowseApproveTimecardsSuccessful = createAction(
  '[Approve Browse] Approve TImecards Successful'
);
export const ApprovalBrowseApproveTimecardsFailed = createAction(
  '[Approval Browse] Decline Timecards Failed',
  (error: any) => ({error})
);

export const RetrieveApprovalSummarySuccessful = createAction(
  '[Approval Browse]  Retrieve approvalSummaries successful',
  (approvalSummaries: ApprovalSummary[], total?: number) => ({approvalSummaries, total})
);

export const RetrieveApprovalSummaries = createAction(
  '[Approval Browse] Retrieve approvalSummaries',
  (offset: number) => ({offset})
);

export const ApprovalSummarySearchText = createAction(
  '[Approval Browse]  Update Approval Search Text',
  (searchText: string) => ({searchText})
);

export const TimecardSelected = createAction(
  '[Approval Browse] Update Approval Browse row select status',
  (timecard: fromUIModel.BrowsePageRowSelection) => ({timecard})
);

export const RetrievedApprovalSummaryNotFoundResponse = createAction(
  '[Approval Browse]  Retrieved Not found response'
);

export const ReceivedApprovalSummaryEmptyResponse = createAction(
  '[Approval Browse] Received Empty Response'
);

export const ResetApprovalSummaries = createAction(
  '[Approval Browse]  Reset ApprovalSummaries'
);

export const ResetExceptFilters = createAction(
  '[Approval Browse] Reset except filter'
);

export const ApprovalFilters = createAction(
  '[Approval Browse] Approval Filters',
  (filterOption: FilterOption) => ({filterOption})
);

export const RemoveApprovalFilter = createAction(
  '[Approval Browse] Remove Approval Filter',
  (filterOption: FilterOption) => ({filterOption})
);

export const RetrieveApprovalTimecards = createAction(
  '[Approval Browse] Default Approval Filters',
  (filterParams: FilterParams) => ({filterParams})
);

export const ClearFilters = createAction(
  '[Approval Browse] Clear Filters',
  (filters: FilterParams[]) => ({filters})
);

export const Sort = createAction(
  '[Approval Browse] Sort',
  (sort: string) => ({sort})
);

export const RetrieveApprovalBatches = createAction(
  '[Approval Browse] Retrieve Lite Batches',
  (filters: FilterParams) => ({filters})
);

export const RetrieveApprovalBatchesSuccessful = createAction(
  '[Approval Browse] Retrieve Lite Batches Successful',
  (batches: string[]) => ({batches})
);

export const RetrieveApprovalBatchesFailed = createAction(
  '[Approval Browse] Retrieve Lite Batches Failed'
);

export const AddMiscFilters = createAction(
  '[Approval Browse] Approval Misc Filters',
  (filterOption: FilterOption) => ({filterOption})
);

export const RemoveMiscFilters = createAction(
  '[Approval Browse] Remove Approval Misc Filters',
  (filterOption: FilterOption) => ({filterOption})
);

export const Reset = createAction(
  '[Approval Browse] Reset Approval browse'
);

export const ShowApprovalSummarySelectAllInfo = createAction(
  '[Approval Browse] Show Approval Summary Select All Info'
);

export const HideApprovalSummarySelectAllInfo = createAction(
  '[Approval Browse] Hide Approval Summary Select All Info'
);

export const ClearAllApprovalSummarySelections = createAction(
  '[Approval Browse] Clear All Approval Summary Selections',
  () => ({status: false})
);

export const ShowApprovalSummarySelectEverything = createAction(
  '[Approval Browse] Show Approval Summary Select Everything'
);

export const ApprovalSummaryRowGroupSelection = createAction(
  '[Approval Browse] Update Approval Summary Rows Group Selection',
  (status: boolean) => ({status})
);

export const BeforeApprovalSummaryRowGroupSelection = createAction(
  '[Approval Browse] Before Approval Summary Rows Group Selection'
);

export const ApprovalSummaryRowSelectStatus = createAction(
  '[Approval Browse] Update Approval Summary Rows isSelected Status',
  (approvalSummaries: ApprovalsBrowseViewModel[]) => ({approvalSummaries})
);

export const ApprovalSummariesWithUpdatedSelection = createAction(
  '[Approval Browse] Update Approval Summaries With isSelected Status',
  (approvalSummaries: ApprovalsBrowseViewModel[], total: number, browsePageRowSelection: boolean) => ({
    approvalSummaries: _.map(approvalSummaries, approvalSummary => ({...approvalSummary, isSelected: browsePageRowSelection})),
    total
  })
);

export const InitiateApprovalBrowseApprove = createAction(
  '[Approval Browse] Initiate Approval Browse Approval'
);

export const ApprovalBrowseBulkAction = createAction(
  '[Approval Browse] Approval Browse Bulk Action',
  (isApprove: boolean, modalName?: NgbModalName, declineNote?: string) => ({isApprove, modalName, declineNote})
);

export const ApprovalBrowseBulkApprove = createAction(
  '[Approval Browse] Approval Browse Bulk Approve',
  (response: TimecardBulkActionResponse, totalTimecards: number) => ({response, totalTimecards})
);

export const ApprovalBrowseBulkApproveSuccessful = createAction(
  '[Approval Browse] Approval Browse Bulk Approval Successful'
);

export const ApprovalBrowseBulkApprovePartiallySuccessful = createAction(
  '[Approval Browse] Approval Browse Bulk Approval Partially Successful'
);

export const ApprovalBrowseBulkApproveFailed = createAction(
  '[Approval Browse] Approval Browse Bulk Approval Failed'
);

export const InitiateApprovalBrowseDecline = createAction(
  '[Approval Browse] Initiate Approval Browse Decline',
  (declineNote: string, modalName: NgbModalName) => ({declineNote, modalName})
);

export const ApprovalBrowseBulkDecline = createAction(
  '[Approval Browse] Approval Browse Bulk Decline',
  (response: TimecardBulkActionResponse, totalTimecards: number) => ({response, totalTimecards})
);

export const ApprovalBrowseBulkDeclineSuccessful = createAction(
  '[Approval Browse] Approval Browse Bulk Decline Successful'
);

export const ApprovalBrowseBulkDeclinePartiallySuccessful = createAction(
  '[Approval Browse] Approval Browse Bulk Decline Partially Successful'
);

export const ApprovalBrowseBulkDeclineFailed = createAction(
  '[Approval Browse] Approval Browse Bulk Decline Failed'
);

export const RetrieveApprovalBrowseAccountCodeConfig = createAction(
  '[Approval Browse] Retrieve Approval Browse Account Code Config'
);
