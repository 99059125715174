import {createAction} from '@ngrx/store';
import * as _ from 'lodash';

import * as fromTimecardSummary from '../../../../common/timecard-summary.model';
import {TimecardSummaryResponse} from '../../../../common/timecard-summary.model';
import * as fromBatch from '../../../../common/batch.model';
import * as fromUIModel from '../../models/ui.model';
import * as fromTimecardSummaryView from '../models/timecard-summary-view.model';
import {TimecardSummaryViewModel} from '../models/timecard-summary-view.model';
import {FilterParams, PaginationParams} from '../../models/query-params.model';
import {FilterOption} from '../../shared/components/filter-panel/filter-item.model';
import {TCBrowseQueryParams} from '../reducers/timecard-summary.reducer';
import {TimecardTypes} from '../../../../common/timecard.model';

export const TimecardSummary = createAction(
  '[TC Browse] Update Timecard Summary',
  (timecardSummaries: fromTimecardSummary.TimecardSummary[], browsePageRowSelection: boolean, total: number) => ({
    timecardSummaries: _.map(timecardSummaries,
      (timecardSummary) => ({...timecardSummary, isSelected: browsePageRowSelection})),
    total
  })
);
export const RetrieveTimecardSummaryPage = createAction(
  '[TC Browse] Retrieve Timecard'
);
export const RetrieveTimecardSummarySuccessful = createAction(
  '[TC Browse] Retrieve Timecard Successful',
  (payload: TimecardSummaryResponse) => ({payload})
);
export const TimecardSummaryPageOffset = createAction(
  '[TC Browse] TimecardOptions Updated',
  (payload: TCBrowseQueryParams) => ({payload})
);
export const RetrieveTimecardSummaryFailed = createAction(
  '[TC Browse] Retrieve Timecard Failed'
);
export const Reset = createAction(
  '[TC Browse] TimecardSummary State Reset'
);
export const ShowCreateNewBatch = createAction(
  '[TC Browse] Show create new batch'
);
export const HideCreateNewBatch = createAction(
  '[TC Browse] Hide create new batch'
);
export const CreateBatch = createAction(
  '[TC Browse] Create new batch',
  (selectedTimecards: fromTimecardSummaryView.TimecardSummaryViewModel[], batch: fromBatch.Batch) =>
    ({selectedTimecards, batch})
);
export const ResetBatchOverlay = createAction(
  '[TC Browse] Reset BatchSummary overlay state'
);
export const ReceivedTimecardSummaryEmptyResponse = createAction(
  '[TC Browse] Received Empty Response'
);
export const IncrementTimecardBrowsePage = createAction(
  '[TC Browse] IncrementTimecardBrowsePage',
  (payload: PaginationParams) => ({payload})
);
export const TimecardSummaryNotFoundResponse = createAction(
  '[TC Browse] InitializeTimecardBrowsePage'
);
export const DeleteTimecard = createAction(
  '[TC Browse] Delete Timecards',
  (selectedTimecards: TimecardSummaryViewModel[]) => ({selectedTimecards})
);
export const CancelDeleteTimecard = createAction(
  '[TC Browse] Cancel Delete Timecards'
);
export const AssignTimecardsToBatch = createAction(
  '[TC Browse] Assign Timecards To Batch',
  (selectedTimecards: fromTimecardSummaryView.TimecardSummaryViewModel[], batchId: string) =>
    ({selectedTimecards, batchId})
);
export const CancelAssignTimecardsToBatch = createAction(
  '[TC Browse] Cancel Assign Timecards To Batch'
);
export const DeleteTimecardSuccessful = createAction(
  '[TC Browse] Delete Timecards Successful'
);
export const DeleteTimecardFailed = createAction(
  '[TC Browse] Delete Timecards Failed'
);
export const IncrementBatchSummaryPage = createAction(
  '[TC Browse] Increment batch summary page',
  (payload: any) => ({payload})
);
export const BatchSummaryPageOffset = createAction(
  '[TC Browse] Update batch summary offset',
  (batchSummaryParams: PaginationParams) => ({batchSummaryParams})
);
export const RowSelectStatus = createAction(
  '[TC Browse] Update TC Browse row select status',
  (timecard: fromUIModel.BrowsePageRowSelection) => ({timecard})
);
export const RowGroupSelection = createAction(
  '[TC Browse] Update Timecard Summary rows group selection',
  (status: boolean) => ({status})
);
export const BeforeRowsSelectStatus = createAction(
  '[TC Browse] Before update Timecard summary rows select status'
);
export const RowsSelectStatus = createAction(
  '[TC Browse] Update Timecard summary rows select status',
  (timecardSummary: fromTimecardSummaryView.TimecardSummaryViewModel[]) => ({timecardSummary})
);
export const TimecardSelected = createAction(
  '[TC Browse] Timecard Selected',
  (selectedTimecards: TimecardSummaryViewModel[]) => ({selectedTimecards})
);
export const SelectAnySubmittedTimecard = createAction(
  '[TC Browse] Selected One Or More Submitted timecard'
);
export const SelectUnsubmittedTimecards = createAction(
  '[TC Browse] Selected Unsubmitted timecards'
);
export const InitializeColumnConfigs = createAction(
  '[TC Browse] Initialize manage column configs'
);
export const OpenWtcReportPanel = createAction(
  '[TC Browse] Open report panel',
  () => ({status: true})
);
export const CloseWtcReportPanel = createAction(
  '[TC Browse] Close report panel',
  () => ({status: false})
);
export const DisplayLoadingScreen = createAction(
  '[Timecard Browse] Display Loading Screen',
  (loadingText: string) => ({loadingText})
);
export const DismissLoadingScreen = createAction(
  '[Timecard Browse] Dismiss Loading Screen'
);
export const SelectListOfTimecards = createAction(
  '[TC Browse] Select list of Timecards',
  (selectedTcIds: string[]) => ({selectedTcIds})
);
export const TimecardFilters = createAction(
  '[TC Browse] Update Timecard Filters',
  (filters: FilterParams[]) => ({filters})
);
export const RemoveTimecardFilter = createAction(
  '[TC Browse] Remove Timecard Filter',
  (filterOption: FilterOption) => ({filterOption})
);
export const TimecardMiscFilter = createAction(
  '[TC Browse] Update Timecard Miscellaneous Filter',
  (filterOption: FilterOption) => ({filterOption})
);
export const RemoveTimecardMiscFilter = createAction(
  '[TC Browse] Remove Timecard Miscellaneous Filter',
  (filterOption: FilterOption) => ({filterOption})
);
export const ClearAllTimecardFilters = createAction(
  '[TC Browse] Clear Timecard Filters',
  (filters: FilterParams[]) => ({filters})
);
export const Sort = createAction(
  '[TC Browse] Update Timecard Sort',
  (sort: string) => ({sort})
);
export const Search = createAction(
  '[TC Browse] Search Timecards',
  (searchText: string) => ({searchText})
);
export const ResetTimecards = createAction(
  '[TC Browse] Reset Timecards'
);
export const RouteToApprover = createAction(
  '[TC Browse] Route For Approver',
  (selectedTimecards: TimecardSummaryViewModel[]) => ({selectedTimecards})
);
export const RouteToApproverSuccesful = createAction(
  '[TC Browse] Route For Approver successful'
);
export const ClearTimecardSelection = createAction(
  '[TC Browse] Clear Timecard Selection'
);
export const ShowSelectAllTcInfoBox = createAction(
  `[TC Browse] Show Select all Timecards grey info box`
);

export const ShowSelectEverythingInfoBox = createAction(
  `[TC Browse] Show Select Everything grey info box`,
);

export const ClearSelectEverythingBoxStatus = createAction(
  `[TC Browse] Reset select everything feature`
);
export const InitiateDeleteTimecard = createAction(
  `[TC Browse] Initiate Delete Timecard`
);
export const BulkDeleteTimecard = createAction(
  `[TC Browse] Bulk Delete Timecard`
);
export const BulkDeleteTimecardFailed = createAction(
  `[TC Browse] Bulk Delete Timecard Failed`
);
export const BulkDeleteTimecardSuccessful = createAction(
  `[TC Browse] Bulk Delete Timecard Successful`
);
export const BulkDeleteTimecardPartiallySuccessful = createAction(
  `[TC Browse] Bulk Delete Timecard Partially Successful`
);
export const InitiateRouteToApprover = createAction(
  `[TC Browse] Initiate Route To Approver`
);
export const BulkRouteToApprover = createAction(
  `[TC Browse] Bulk Route To Approver`
);
export const BulkRouteToApproverFailed = createAction(
  `[TC Browse] Bulk Route To Approver Failed`
);
export const BulkRouteToApproverSuccessful = createAction(
  `[TC Browse] Bulk Route To Approver Successful`
);
export const BulkRouteToApproverPartiallySuccessful = createAction(
  `[TC Browse] Bulk Route To Approver Partially Successful`
);
export const InitiateAssignTimecardsToBatch = createAction(
  `[TC Browse] Initiate Assign Timecards To Batch`,
  (batchId: string) => ({batchId})
);
export const BulkAssignTimecardsToBatch = createAction(
  `[TC Browse] Bulk Assign Timecards To Batch`,
  (batchId: string) => ({batchId})
);
export const BulkAssignTimecardsToBatchFailed = createAction(
  `[TC Browse] Bulk Assign Timecards To Batch Failed`
);
export const BulkAssignTimecardsToBatchSuccessful = createAction(
  `[TC Browse] Bulk Assign Timecards To Batch Successful`
);
export const BulkAssignTimecardsToBatchPartiallySuccessful = createAction(
  `[TC Browse] Bulk Assign Timecards To Batch Partially Successful`
);
export const RetrieveTimecardFilterBatches = createAction(
  '[TC Browse] Retrieve Lite Batches',
  (projectId: string) => ({projectId})
);
export const RetrieveTimecardFilterBatchesSuccessful = createAction(
  '[TC Browse] Retrieve Lite Batches Successful',
  (batches: string[]) => ({batches})
);
export const RetrieveTimecardFilterBatchesFailed = createAction(
  '[TC Browse] Retrieve Lite Batches Failed'
);
export const ReplaceSummaryQueryParams = createAction(
  `[TC Browse] Replace summary Query params with Browse Query params`,
  (projectId: string) => ({projectId})
);
export const UpdateTcBrowseQueryParams = createAction(
  `[TC Browse] Update or replace Query Params`,
  (timecardSummaryQueryParams: TCBrowseQueryParams) => ({timecardSummaryQueryParams})
);
export const ClearSelectEverything = createAction(
  '[TC Browse] Clear Select Everything'
);
export const CheckAndProceedWithTimecardRouting = createAction(
  '[TC Browse] Check and proceed with timecard routing'
);
export const RetrieveTCBrowseAccountCodeConfig = createAction(
  '[TC Browse] Retrieve TC Browse Account Code Config',
  () => ({timecardType: TimecardTypes.CREW})
);
export const NewSagTimecard = createAction(
  '[TC Browse] New Sag Timecard'
);
export const OpenAuditHistoryReportPanel = createAction(
  '[TC Browse] Open Audit History Report panel',
  () => ({isAuditHistoryReportPanelOpened: true})
);
export const CloseAuditHistoryReportPanel = createAction(
  '[TC Browse] Close Audit History Report panel',
  () => ({isAuditHistoryReportPanelOpened: false})
);
