import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as _ from 'lodash';

import {ProjectBrowseQueryParams, ProjectBrowseUI, ProjectSummaryState} from '../reducers/project.reducer';
import {ColumnGroupConfig, Sections} from '../../shared/components/manage-column/manage-column.model';
import {ProjectBrowseGrid} from '../models/project-browse.model';
import {_selectSectionVisibility, _selectVisibleColumnCount} from '../../selectors/ui.selectors';
import {ProjectSummary} from '../../../../common/project.summary.model';
import {PaymasterBatchConstants} from '../../../../constants/paymaster-batch.constants';
import {selectPayManagerLookUp, selectPayMasterLookUp} from '../../selectors/lookup.selector';
import {FilterParams, LiteBrowseQueryParams} from '../../models/query-params.model';
import {ProjectBrowseConstants} from '../../../../constants/project-browse.constants';
import {TextFilter} from '../../shared/components/text-filter/text-filter.model';
import {DropdownFilter} from '../../shared/components/dropdown-filter/dropdown-filter.model';
import {FilterOption} from '../../shared/components/filter-panel/filter-item.model';
import {Paymaster} from '../../../../common/paymaster.model';
import {ProjectFeatureState} from '../reducers';

export const selectProjectState = createFeatureSelector<ProjectFeatureState>('project');

const _selectProjectUi = (state: ProjectSummaryState) => {
  return state && state.ui;
};

const _selectColumnConfigs = (state: ProjectBrowseUI) => {
  return state && state.columnConfig;
};
export const selectProjectBrowseState = createSelector(
  selectProjectState,
  (state: ProjectFeatureState): ProjectSummaryState => state.projectBrowse
);
export const selectProjectUIState = createSelector(
  selectProjectBrowseState,
  _selectProjectUi
);
export const selectColumnConfigs = createSelector(
  selectProjectUIState,
  _selectColumnConfigs
);

export const _getProjectBrowseGroupConfigSelector = createSelector(
  selectColumnConfigs,
  (dtGridConfig: ProjectBrowseGrid) => {
    return _selectProjectColumnGroupConfig(dtGridConfig);
  }
);
export const _selectProjectColumnGroupConfig = (gridSectionConfig): ColumnGroupConfig =>
  _.thru(gridSectionConfig?.all, (sectionConfig) => ({
    groupName: sectionConfig.groupName,
    section: sectionConfig.section,
    isSelected: sectionConfig.isVisible,
    isShowAllColumns: sectionConfig.isShowAllColumns,
    columns: _.map(sectionConfig.columns, columnConfig => columnConfig)})
  );

export const selectProjectBrowseAllGroupConfig = createSelector(
  _getProjectBrowseGroupConfigSelector,
  (config: ColumnGroupConfig): ColumnGroupConfig[] => ([config])
);
export const selectProjectBrowseVisibleColumnCount = createSelector(
  selectColumnConfigs,
  _selectVisibleColumnCount
);

export const selectProjectBrowseSectionVisibility = (section: Sections) => createSelector(
  selectColumnConfigs,
  columnConfig => _selectSectionVisibility(columnConfig, section)
);
export const selectProjectBrowseQueryParams = createSelector(
  selectProjectBrowseState,
  (uiState: ProjectSummaryState): LiteBrowseQueryParams => uiState && uiState.projectSummaryQueryParams
);
export const selectSelectedProjectSort = createSelector(
  selectProjectBrowseQueryParams,
  (queryParams: LiteBrowseQueryParams): string => queryParams.sort
);
export const selectSelectedProjectSearchText = createSelector(
  selectProjectBrowseQueryParams,
  (queryParams: LiteBrowseQueryParams): string => queryParams.searchText
);
export const selectProjectSummary = createSelector(
  selectProjectBrowseState,
  (state: ProjectSummaryState): ProjectSummary[] => state && state.projects
);
export const selectIsAllProjectsLoaded = createSelector(
  selectProjectBrowseState,
  (state: ProjectSummaryState): boolean => state && state.isLastProjectLoaded
);
export const selectStudios = createSelector(
  selectProjectUIState,
  (uiState: ProjectBrowseUI): string[] => uiState && uiState.studios ? uiState.studios : []
);
export const selectProjectBrowseSummaryState = createSelector(
  selectProjectBrowseState,
  (state: ProjectSummaryState) => state && state.projectSummaryQueryParams
);
export const selectProjectBrowseFilter = createSelector(
  selectProjectBrowseSummaryState,
  (state: ProjectBrowseQueryParams) => state && state.filters
);
export const selectSelectedPaymasterManager = createSelector(
  selectProjectBrowseState,
  (state: ProjectSummaryState) => state && state.selectedPaymasterManager
);
export const selectSelectedProjectStatus = createSelector(
  selectProjectBrowseFilter,
  (filters: FilterParams[]): FilterOption[] => {
    const statusFilter = _.filter(filters, {filterType: 'active'});
    return _.map(_.head(statusFilter)?.value, (status) => ({
      id: _.find(ProjectBrowseConstants.ProjectBrowse.projectBrowseStatusFilters, ['value', status])?.id,
      value: status,
      type: 'active'
    }));
  }
);

export const selectProjectStatusFilter = createSelector(
  selectSelectedProjectStatus,
  (statusFilter: FilterOption[]): TextFilter => {
    return {
      title: 'Status',
      options: ProjectBrowseConstants.ProjectBrowse.projectBrowseStatusFilters,
      selectedItems: statusFilter
    };
  }
);

export const selectSelectedProjectType = createSelector(
  selectProjectBrowseFilter,
  (filters: FilterParams[]): FilterOption[] => {
    const statusFilter = _.filter(filters, {filterType: 'projectType'});
    return _.map(_.head(statusFilter)?.value, (status) => ({
      id: _.find(ProjectBrowseConstants.ProjectBrowse.projectBrowseIntExtFilters, ['value', status])?.id,
      value: status,
      type: 'projectType'
    }));
  }
);
export const selectProjectTypeFilter = createSelector(
  selectSelectedProjectType,
  (statusFilter: FilterOption[]): TextFilter => {
    return {
      title: 'Int/Ext',
      options: ProjectBrowseConstants.ProjectBrowse.projectBrowseIntExtFilters,
      selectedItems: statusFilter
    };
  }
);

export const selectStudioWithDefaultOption = createSelector(
  selectStudios,
  (studios: string[]): string[] => {
    return _.concat(['No Studio'], studios);
  }
);
export const selectSelectedStudios = createSelector(
  selectProjectBrowseFilter,
  selectStudioWithDefaultOption,
  (filters: FilterParams[], studios: string[]): FilterOption[] => {
    const studioFilter = _.filter(filters, {filterType: 'studioNameNotAnalyzed'});
    return _.map(_.head(studioFilter)?.value, (studioName) => ({
      id: _.find(studios, (studio) => studio === studioName),
      value: studioName,
      type: 'studioNameNotAnalyzed'
    }));
  }
);
export const selectStudioFilter = createSelector(
  selectSelectedStudios,
  selectStudioWithDefaultOption,
  (statusFilter: FilterOption[], studios: string[]): TextFilter => {
    return {
      title: 'Studio',
      options:  _.map(studios, (studio: string) => ({
        id: studio,
        value: studio === 'No Studio' ? 'No Studio' : studio,
        type: 'studioNameNotAnalyzed'
      })),
      selectedItems: statusFilter
    };
  }
);
export const selectPaymasterLookupFilter = createSelector(
  selectPayMasterLookUp,
  (paymasters) => ({
    paymasters : _.map(paymasters, ({id, firstName, lastName}) => ({
      id: _.toString(id),
      value: `${lastName}, ${firstName}`,
      type: 'paymasterInfo.paymasterId'
    }))
  })
);

export const selectPaymasterManagerLookupFilter = createSelector(
  selectPayManagerLookUp,
  (paymasters) => ({
    paymasters : _.map(paymasters, ({id, firstName, lastName}) => ({
      id: _.toString(id),
      value: `${lastName}, ${firstName}`,
      type: 'paymasterInfo.paymasterId'
    }))
  })
);

export const selectSelectedPaymasters = createSelector(
  selectProjectBrowseFilter,
  (filters: FilterParams[]) => filters && _.find(filters, {'filterType': 'paymasterInfo.paymasterId'})?.value || []
);
export const selectSelectedPaymasterFilters = createSelector(
  selectPayMasterLookUp,
  selectSelectedPaymasters,
  (paymasters: Paymaster[], selectedPaymasters = []) => ({
    paymasters: _.map(selectedPaymasters, (paymasterId) => {
      const {firstName = '', lastName = '', id= ''} = _.find(paymasters, paymaster => _.toString(paymaster.id) === paymasterId) ?? {};
      return {
        id: _.toString(id),
        value: `${lastName}, ${firstName}`,
        type: 'paymasterInfo.paymasterId'
      };
    })
  })
);
export const selectPaymasterBrowseFilters = createSelector(
  selectPaymasterLookupFilter,
  selectSelectedPaymasterFilters,
  (lookUpData, filters): DropdownFilter[] => {
    return [{
      titleOptions: PaymasterBatchConstants.PaymasterBatch.PaymasterBatchFilterTypes,
      options: lookUpData.paymasters,
      selectedItems: _.filter(filters.paymasters, ({value}) => value !== ', ')
    }];
  }
);
export const selectPaymanagerBrowseFilters = createSelector(
  selectPaymasterManagerLookupFilter,
  selectSelectedPaymasterManager,
  (lookUpData, selectedPaymasterManager): DropdownFilter[] => {
    return [{
      titleOptions: PaymasterBatchConstants.PaymasterBatch.PaymasterBatchFilterTypes,
      options: lookUpData.paymasters,
      selectedItems: selectedPaymasterManager ? [selectedPaymasterManager] : []
    }];
  }
);
export const selectAppliedProjectBrowseFilterCount = createSelector(
  selectProjectStatusFilter,
  selectProjectTypeFilter,
  selectStudioFilter,
  selectSelectedPaymasters,
  selectSelectedPaymasterManager,
  (
    projectStatusFilter: TextFilter,
    projectTypeFilter: TextFilter,
    studioFilter: TextFilter,
    selectedPaymasters: string[],
    selectedPaymanager: FilterOption ): number => {
    const selectedPaymasterCount = _.isEmpty(selectedPaymanager) ? _.size(selectedPaymasters) : 1;
    return _.size(_.concat(projectStatusFilter.selectedItems, projectTypeFilter.selectedItems, studioFilter.selectedItems)) + selectedPaymasterCount;
  }
);
export const selectProjectBrowsePageOffset = createSelector(
  selectProjectBrowseQueryParams,
  (queryParams: LiteBrowseQueryParams): number => queryParams && queryParams.offset
);
