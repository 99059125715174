import {Grids, Sections} from '../shared/components/manage-column/manage-column.model';
import {TaxJurisdictionInputLength} from '../shared/constants';
import {ForceCallEnum, MealPenaltyEnum, WorkConditionEnum} from '../../../common/timecard.model';

export interface DailyTimeDropdownOptions {
  name: string;
  value: string | boolean;
  hoverText: string;
  infoHoverText?: string;
}

export interface IDailyTimeFieldConfig {
  fieldSlug: string;
  label: string;
  sectionName: Sections;
  componentName: DailyTimeComponents;
  valuePath: string;
  isBulkInput?: boolean;
  dailyTimesClassNames?: string[];
  bulkInputClassNames?: string[];
  dailyTimeFieldId: string;
  bulkInputFieldId: string;
  width?: string;
  // Text input
  mask?: string;
  maxLength?: number;
  characterCount?: number;
  // Dropdown
  options?: DailyTimeDropdownOptions[];
  // checkbox
  title?: string;
  ellipsis?: number;
}
export interface IDailyTimeSectionConfig {
  sectionSlug: Sections;
  grid: Grids;
  label: string;
  ordinal: number;
  componentName: DailyTimeComponents;
  fields: IDailyTimeFieldConfig[];
}

export enum DailyTimeComponents {
  dTSectionComponent = 'DailyTimeSectionComponent',
  dTBulkInputStartcardInfoComponent = 'DailyTimeBulkInputStartcardInfoComponent',
  dTTextInputComponent = 'DailyTimeTextInputComponent',
  dTAccountCodingTextInputComponent = 'DailyTimeAccountCodingTextInputComponent',
  dTDropdownComponent = 'DailyTimeDropdownComponent',
  dTErrorValueDropdownComponent = 'DailyTimeErrorValueDropdownComponent',
  dTCustomDropdownComponent = 'DailyTimeCustomDropdownComponent',
  dTRerateInputComponent = 'DailyTimeRerateInputComponent',
  dTJobCodeDropdownComponent = 'DailyTimeJobCodeDropdownComponent',
  dTScheduleCodeDropdownComponent = 'DailyTimeScheduleCodeDropdownComponent',
  dTCheckboxComponent = 'DailyTimeCheckboxComponent',
  dTAllowanceButtonComponent = 'DailyTimeAllowanceButtonComponent',
  dTSplitGlButtonComponent = 'DailyTimeSplitGlButtonComponent',
  dTLocFinderButtonComponent = 'LocationFinderComponent',
  glShortcutSelectorComponent = 'GlShortcutSelectorComponent',
  workStatusSelectorComponent = 'WorkStatusSelectorComponent',
  dtLocationTextInputComponent = 'LocationTextInputComponent',
  dtStuntAdjustmentTextInputComponent = 'DailyTimeStuntAdjustmentComponent',
  dTPayTypeDropdownComponent = 'DailyTimePayTypeDropdownComponent',
  dTDayTypeDropdownComponent = 'DailyTimeDayTypeDropdownComponent',
  dtStartcardInfoTextInputComponent = 'DailyTimeStartcardInfoTextInputComponent'
}

export const SagDailyTimeUiConfig: {[key: string]: IDailyTimeSectionConfig} = {
  [Sections.startcardInfo]: {
    sectionSlug: Sections.startcardInfo,
    grid: Grids.sagDailyTimeGrid,
    label: 'Performer',
    ordinal: 1,
    componentName: DailyTimeComponents.dTBulkInputStartcardInfoComponent,
    fields: [
      // name field is static
      {
        fieldSlug: 'characterName',
        label: 'Character',
        componentName: DailyTimeComponents.dtStartcardInfoTextInputComponent,
        valuePath: 'characterName',
        sectionName: Sections.startcardInfo,
        dailyTimeFieldId: 'sagDtRowField-characterName',
        bulkInputFieldId: 'sagDtBulkInput-characterName',
        dailyTimesClassNames: ['dts-col dts-col-character', 'dts-performer-txt-box'],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-col-character', ''],
        maxLength: 25
      },
      {
        fieldSlug: 'characterNumber',
        label: 'Char #',
        componentName: DailyTimeComponents.dtStartcardInfoTextInputComponent,
        valuePath: 'characterNumber',
        sectionName: Sections.startcardInfo,
        dailyTimeFieldId: 'sagDtRowField-characterNumber',
        bulkInputFieldId: 'sagDtBulkInput-characterNumber',
        dailyTimesClassNames: ['dts-col dts-col-character-number', 'dts-performer-txt-box'],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-col-character-number', ''],
        maxLength: 5
      },
      {
        fieldSlug: 'ssn',
        label: 'SSN',
        componentName: DailyTimeComponents.dtStartcardInfoTextInputComponent,
        valuePath: 'ssn',
        sectionName: Sections.startcardInfo,
        dailyTimeFieldId: 'sagDtRowField-ssn',
        bulkInputFieldId: 'sagDtBulkInput-ssn',
        dailyTimesClassNames: ['dts-col dts-col-ssn', 'dts-txt-box dts-disabled'],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-col-ssn', '']
      },
      {
        fieldSlug: 'fein',
        label: 'FEIN',
        componentName: DailyTimeComponents.dtStartcardInfoTextInputComponent,
        valuePath: 'fedEmpIdNum',
        sectionName: Sections.startcardInfo,
        dailyTimeFieldId: 'sagDtRowField-fein',
        bulkInputFieldId: 'sagDtBulkInput-fein',
        dailyTimesClassNames: ['dts-col dts-col-sm', 'dts-txt-box dts-disabled'],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-col-sm', '']
      },
      {
        fieldSlug: 'jobTitle',
        label: 'Job Title',
        componentName: DailyTimeComponents.dtStartcardInfoTextInputComponent,
        valuePath: 'preferredJobTitle',
        sectionName: Sections.startcardInfo,
        dailyTimeFieldId: 'sagDtRowField-jobTitle',
        bulkInputFieldId: 'sagDtBulkInput-jobTitle',
        dailyTimesClassNames: ['dts-col dts-col-job-title', 'dts-performer-txt-box dts-disabled'],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-col-job-title', ''],
        ellipsis: 30
      },
      {
        fieldSlug: 'department',
        label: 'Department',
        componentName: DailyTimeComponents.dtStartcardInfoTextInputComponent,
        valuePath: 'department',
        sectionName: Sections.startcardInfo,
        dailyTimeFieldId: 'sagDtRowField-department',
        bulkInputFieldId: 'sagDtBulkInput-department',
        dailyTimesClassNames: ['dts-col dts-col-lg', 'w-100 dts-performer-txt-box dts-disabled'],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-col-lg', ''],
        ellipsis: 30
      }
    ]
  },
  [Sections.dailyTimes]: {
    sectionSlug: Sections.dailyTimes,
    grid: Grids.sagDailyTimeGrid,
    label: 'Daily Times',
    ordinal: 2,
    componentName: DailyTimeComponents.dTSectionComponent,
    fields: [
      {
        fieldSlug: 'workStatus',
        label: 'Work Status',
        componentName: DailyTimeComponents.workStatusSelectorComponent,
        valuePath: 'workStatus',
        sectionName: Sections.dailyTimes,
        dailyTimesClassNames: ['dts-col dts-ws-col', ''],
        bulkInputClassNames: ['dts-ws-cell-item header-sub-title dts-ws-col', ''],
        dailyTimeFieldId: 'sagDtRowField-workStatus-',
        bulkInputFieldId: 'sagDtBulkInput-workStatus',
        width: '158px'
      },
      {
        fieldSlug: 'makeupWardrobeIn',
        label: 'Mk/Wd In',
        componentName: DailyTimeComponents.dTTextInputComponent,
        valuePath: 'makeupWardrobeIn',
        sectionName: Sections.dailyTimes,
        mask: '99.0',
        dailyTimesClassNames: ['dts-col dts-sag-col-punch', 'dts-txt-box'],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-sag-col-punch', 'w-100 text-center dts-bulk-input'],
        dailyTimeFieldId: 'sagDtRowField-mkpWrdIn-',
        bulkInputFieldId: 'sagDtBulkInput-mkpWrdIn',
        width: '68px'
      },
      {
        fieldSlug: 'onSet',
        label: 'On Set',
        componentName: DailyTimeComponents.dTTextInputComponent,
        valuePath: 'onSet',
        sectionName: Sections.dailyTimes,
        mask: '99.0',
        dailyTimesClassNames: ['dts-col', 'dts-txt-box'],
        bulkInputClassNames: ['dts-cell-item header-sub-title', 'w-100 text-center dts-bulk-input'],
        dailyTimeFieldId: 'sagDtRowField-onSet-',
        bulkInputFieldId: 'sagDtBulkInput-onSet',
        width: '52px'
      },
      {
        fieldSlug: 'dismiss',
        label: 'Dismiss',
        componentName: DailyTimeComponents.dTTextInputComponent,
        valuePath: 'dismiss',
        sectionName: Sections.dailyTimes,
        mask: '99.0',
        dailyTimesClassNames: ['dts-col', 'dts-txt-box'],
        bulkInputClassNames: ['dts-cell-item header-sub-title', 'w-100 text-center dts-bulk-input'],
        dailyTimeFieldId: 'sagDtRowField-dismiss-',
        bulkInputFieldId: 'sagDtBulkInput-dismiss',
        width: '58px'
      },
      {
        fieldSlug: 'makeupWardrobeOut',
        label: 'Mk/Wd Out',
        componentName: DailyTimeComponents.dTTextInputComponent,
        valuePath: 'makeupWardrobeOut',
        sectionName: Sections.dailyTimes,
        mask: '99.0',
        dailyTimesClassNames: ['dts-col dts-sag-col-punch', 'dts-txt-box'],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-sag-col-punch', 'w-100 text-center dts-bulk-input'],
        dailyTimeFieldId: 'sagDtRowField-mkpWrdOut-',
        bulkInputFieldId: 'sagDtBulkInput-mkpWrdOut',
        width: '79px'
      },
      {
        fieldSlug: 'ndb1In',
        label: 'NDB In',
        componentName: DailyTimeComponents.dTTextInputComponent,
        valuePath: 'ndb1In',
        sectionName: Sections.dailyTimes,
        mask: '99.0',
        dailyTimesClassNames: ['dts-col', 'dts-txt-box'],
        bulkInputClassNames: ['dts-cell-item header-sub-title', 'w-100 text-center dts-bulk-input'],
        dailyTimeFieldId: 'sagDtRowField-ndbIn-',
        bulkInputFieldId: 'sagDtBulkInput-ndbIn',
        width: '54px'
      },
      {
        fieldSlug: 'ndb1Out',
        label: 'NDB Out',
        componentName: DailyTimeComponents.dTTextInputComponent,
        valuePath: 'ndb1Out',
        sectionName: Sections.dailyTimes,
        mask: '99.0',
        dailyTimesClassNames: ['dts-col', 'dts-txt-box'],
        bulkInputClassNames: ['dts-cell-item header-sub-title', 'w-100 text-center dts-bulk-input'],
        dailyTimeFieldId: 'sagDtRowField-ndbOut-',
        bulkInputFieldId: 'dtBulkInput-ndbOut',
        width: '65px'
      },
      {
        fieldSlug: 'meal1Out',
        label: 'M1 Start',
        componentName: DailyTimeComponents.dTTextInputComponent,
        valuePath: 'meal1Out',
        sectionName: Sections.dailyTimes,
        mask: '99.0',
        dailyTimesClassNames: ['dts-col', 'dts-txt-box'],
        bulkInputClassNames: ['dts-cell-item header-sub-title', 'w-100 text-center dts-bulk-input'],
        dailyTimeFieldId: 'sagDtRowField-meal1Start-',
        bulkInputFieldId: 'sagDtBulkInput-meal1Start',
        width: '62px'
      },
      {
        fieldSlug: 'meal1In',
        label: 'M1 Finish',
        componentName: DailyTimeComponents.dTTextInputComponent,
        valuePath: 'meal1In',
        sectionName: Sections.dailyTimes,
        mask: '99.0',
        dailyTimesClassNames: ['dts-col', 'dts-txt-box'],
        bulkInputClassNames: ['dts-cell-item header-sub-title', 'w-100 text-center dts-bulk-input'],
        dailyTimeFieldId: 'sagDtRowField-meal1Finish-',
        bulkInputFieldId: 'sagDtBulkInput-meal1Finish',
        width: '68px'
      },
      {
        fieldSlug: 'meal2Out',
        label: 'M2 Start',
        componentName: DailyTimeComponents.dTTextInputComponent,
        valuePath: 'meal2Out',
        sectionName: Sections.dailyTimes,
        mask: '99.0',
        dailyTimesClassNames: ['dts-col', 'dts-txt-box'],
        bulkInputClassNames: ['dts-cell-item header-sub-title', 'w-100 text-center dts-bulk-input'],
        dailyTimeFieldId: 'sagDtRowField-meal2Start-',
        bulkInputFieldId: 'sagDtBulkInput-meal2Start',
        width: '62px'
      },
      {
        fieldSlug: 'meal2In',
        label: 'M2 Finish',
        componentName: DailyTimeComponents.dTTextInputComponent,
        valuePath: 'meal2In',
        sectionName: Sections.dailyTimes,
        mask: '99.0',
        dailyTimesClassNames: ['dts-col', 'dts-txt-box'],
        bulkInputClassNames: ['dts-cell-item header-sub-title', 'w-100 text-center dts-bulk-input'],
        dailyTimeFieldId: 'sagDtRowField-meal2Finish-',
        bulkInputFieldId: 'sagDtBulkInput-meal2Finish',
        width: '68px'
      },
      {
        fieldSlug: 'leaveForLocation',
        label: 'Leave For Loc',
        componentName: DailyTimeComponents.dTTextInputComponent,
        valuePath: 'leaveForLocation',
        sectionName: Sections.dailyTimes,
        mask: '99.0',
        dailyTimesClassNames: ['dts-col dts-sag-col-punch', 'dts-txt-box'],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-sag-col-punch', 'w-100 text-center dts-bulk-input'],
        dailyTimeFieldId: 'sagDtRowField-leaveForLoc-',
        bulkInputFieldId: 'sagDtBulkInput-leaveForLoc',
        width: '90px'
      },
      {
        fieldSlug: 'arriveOnLocation',
        label: 'Arr on Loc',
        componentName: DailyTimeComponents.dTTextInputComponent,
        valuePath: 'arriveOnLocation',
        sectionName: Sections.dailyTimes,
        mask: '99.0',
        dailyTimesClassNames: ['dts-col dts-sag-col-punch', 'dts-txt-box'],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-sag-col-punch', 'w-100 text-center dts-bulk-input'],
        dailyTimeFieldId: 'sagDtRowField-arriveOnLoc-',
        bulkInputFieldId: 'sagDtBulkInput-arriveOnLoc',
        width: '73px'
      },
      {
        fieldSlug: 'leaveLocation',
        label: 'Leave Loc',
        componentName: DailyTimeComponents.dTTextInputComponent,
        valuePath: 'leaveLocation',
        sectionName: Sections.dailyTimes,
        mask: '99.0',
        dailyTimesClassNames: ['dts-col', 'dts-txt-box'],
        bulkInputClassNames: ['dts-cell-item header-sub-title', 'w-100 text-center dts-bulk-input'],
        dailyTimeFieldId: 'sagDtRowField-leaveLoc-',
        bulkInputFieldId: 'dtBulkInput-leaveLoc',
        width: '69px'
      },
      {
        fieldSlug: 'arriveStudio',
        label: 'Arr Studio',
        componentName: DailyTimeComponents.dTTextInputComponent,
        valuePath: 'arriveStudio',
        sectionName: Sections.dailyTimes,
        mask: '99.0',
        dailyTimesClassNames: ['dts-col dts-sag-col-punch', 'dts-txt-box'],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-sag-col-punch', 'w-100 text-center dts-bulk-input'],
        dailyTimeFieldId: 'sagDtRowField-arriveStudio-',
        bulkInputFieldId: 'sagDtBulkInput-arriveStudio',
        width: '71px'
      },
      {
        fieldSlug: 'stuntAdjustment',
        label: 'Stunt Adj',
        componentName: DailyTimeComponents.dtStuntAdjustmentTextInputComponent,
        valuePath: 'stuntAdjustment',
        sectionName: Sections.dailyTimes,
        mask: '900000', // NOT USED - We are hard coding to use imask, we may refactor this later. but this config approach is very restrictive
        // as we can see, we can't even change a mask easily.  Therefore, we should refactor away from it.
        dailyTimesClassNames: ['dts-col', 'dts-txt-box'],
        bulkInputClassNames: ['dts-cell-item header-sub-title', 'w-100 text-center dts-bulk-input'],
        dailyTimeFieldId: 'sagDtRowField-stuntAdj-',
        bulkInputFieldId: 'sagDtBulkInput-stuntAdj',
        width: '65px'
      },
    ]
  },
  [Sections.reRate]: {
    sectionSlug: Sections.reRate,
    grid: Grids.sagDailyTimeGrid,
    label: 'Rerate',
    ordinal: 3,
    componentName: DailyTimeComponents.dTSectionComponent,
    fields: [
      {
        fieldSlug: 'jobCode',
        label: 'Code',
        componentName: DailyTimeComponents.dTJobCodeDropdownComponent,
        valuePath: 'rerateJobCode',
        sectionName: Sections.reRate,
        dailyTimesClassNames: ['dts-col dts-col-sm', ''],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-col-sm', ''],
        dailyTimeFieldId: 'sagDtRowField-jobCode-',
        bulkInputFieldId: 'sagDtBulkInput-jobCode',
        width: '58px'
      },
      {
        fieldSlug: 'scheduleCode',
        label: 'Sched',
        componentName: DailyTimeComponents.dTScheduleCodeDropdownComponent,
        valuePath: 'rerateScheduleCode',
        sectionName: Sections.reRate,
        dailyTimesClassNames: ['dts-col dts-col-sm', ''],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-col-sm', ''],
        dailyTimeFieldId: 'sagDtRowField-scheduleCode-',
        bulkInputFieldId: 'sagDtBulkInput-scheduleCode',
        width: '58px'
      },
      {
        fieldSlug: 'rerateAmount',
        label: 'Rate',
        componentName: DailyTimeComponents.dTRerateInputComponent,
        valuePath: 'rerateAmount',
        sectionName: Sections.reRate,
        dailyTimesClassNames: ['dts-col dts-col-sm', 'dts-txt-box'],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-col-sm', ''],
        dailyTimeFieldId: 'sagDtRowField-rerateAmount-',
        bulkInputFieldId: 'sagDtBulkInput-rerateAmount',
        width: '80px'
      }
    ]
  },
  [Sections.accountCoding]: {
    sectionSlug: Sections.accountCoding,
    grid: Grids.sagDailyTimeGrid,
    label: 'Coding',
    ordinal: 4,
    componentName: DailyTimeComponents.dTSectionComponent,
    fields: [
      {
        fieldSlug: 'GLShortcuts',
        label: 'GL SHORTCUTS',
        componentName: DailyTimeComponents.glShortcutSelectorComponent,
        valuePath: 'details.GLShortcuts',
        sectionName: Sections.accountCoding,
        dailyTimesClassNames: ['dts-cell-item header-sub-title gl-shortcuts', ''],
        bulkInputClassNames: ['dts-cell-item header-sub-title gl-shortcuts', ''],
        dailyTimeFieldId: 'sagDtRowField-GLShortcuts-',
        bulkInputFieldId: 'sagDtBulkInput-GLShortcuts',
        width: '103px'
      },
    ]
  },
  [Sections.details]: {
    sectionSlug: Sections.details,
    grid: Grids.sagDailyTimeGrid,
    label: 'Details',
    ordinal: 5,
    componentName: DailyTimeComponents.dTSectionComponent,
    fields: [
      {
        fieldSlug: 'allowances',
        label: 'Allowances',
        sectionName: Sections.details,
        componentName: DailyTimeComponents.dTAllowanceButtonComponent,
        valuePath: 'allowances',
        dailyTimesClassNames: ['dts-col position-relative dts-col-sm', ''],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-col-sm', 'dts-cell-item dts-col-sm modify-icons-col'],
        dailyTimeFieldId: 'sagDtRowField-allowances-',
        bulkInputFieldId: 'sagDtBulkInput-allowances',
        width: '80px'
      },
      {
        fieldSlug: 'mealPenalty1',
        label: 'MP1',
        sectionName: Sections.details,
        componentName: DailyTimeComponents.dTDropdownComponent,
        valuePath: 'mealPenalty1',
        options: [
          {name: 'Yes', value: MealPenaltyEnum.Yes, hoverText: null},
          {name: 'No', value: MealPenaltyEnum.No, hoverText: null},
          {name: 'Grace', value: MealPenaltyEnum.Grace, hoverText: null}
        ],
        dailyTimesClassNames: ['dts-col dts-col-sm', 'w-100 dt-grid-dropdown'],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-col-sm', 'w-100 dt-grid-dropdown'],
        dailyTimeFieldId: 'sagDtRowField-mealPenalty1-',
        bulkInputFieldId: 'sagDtBulkInput-mealPenalty1',
        width: '80px'
      },
      {
        fieldSlug: 'mealPenalty2',
        label: 'MP2',
        sectionName: Sections.details,
        componentName: DailyTimeComponents.dTDropdownComponent,
        valuePath: 'mealPenalty2',
        options: [
          {name: 'Yes', value: MealPenaltyEnum.Yes, hoverText: null},
          {name: 'No', value: MealPenaltyEnum.No, hoverText: null},
          {name: 'Grace', value: MealPenaltyEnum.Grace, hoverText: null}
        ],
        dailyTimesClassNames: ['dts-col dts-col-sm', 'w-100 dt-grid-dropdown'],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-col-sm', 'w-100 dt-grid-dropdown'],
        dailyTimeFieldId: 'sagDtRowField-mealPenalty2-',
        bulkInputFieldId: 'sagDtBulkInput-mealPenalty2',
        width: '80px'
      },
      {
        fieldSlug: 'forceCall',
        label: 'FC',
        sectionName: Sections.details,
        componentName: DailyTimeComponents.dTCustomDropdownComponent,
        valuePath: 'forceCall',
        options: [
          {name: 'Yes', value: ForceCallEnum.Yes, hoverText: null},
          {name: 'No', value: ForceCallEnum.No, hoverText: null},
          {name: 'Reduced', value: ForceCallEnum.Reduced, hoverText: 'Reduced Rest Period', infoHoverText: 'Applies a reduced rest period for Studio and Overnight conditions, per contract. For more information, refer to your Paymaster or Studio Labor Relations'}
        ],
        dailyTimesClassNames: ['dts-col dts-col-sm', 'w-100 dt-grid-dropdown'],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-col-sm', 'w-100 dt-grid-dropdown'],
        dailyTimeFieldId: 'sagDtRowField-forceCall-',
        bulkInputFieldId: 'sagDtBulkInput-forceCall',
        width: '97px'
      },
      {
        fieldSlug: 'nightPremium',
        label: 'NP',
        sectionName: Sections.details,
        componentName: DailyTimeComponents.dTDropdownComponent,
        valuePath: 'nightPremium',
        options: [
          {name: 'Yes', value: true, hoverText: null},
          {name: 'No', value: false, hoverText: null}
        ],
        dailyTimesClassNames: ['dts-col dts-col-sm', 'w-100 dt-grid-dropdown'],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-col-sm', 'w-100 dt-grid-dropdown'],
        dailyTimeFieldId: 'sagDtRowField-nightPremium-',
        bulkInputFieldId: 'sagDtBulkInput-nightPremium',
        width: '68px'
      },
      {
        fieldSlug: 'notPhotographed',
        label: 'Not Photog',
        sectionName: Sections.details,
        componentName: DailyTimeComponents.dTCheckboxComponent,
        valuePath: 'notPhotographed',
        title: 'Not Photographed',
        dailyTimesClassNames: ['dts-col dts-col-sm st-cust-tc-checkbox', 'dt-grid-checkbox form-check-input'],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-col-sm st-cust-tc-checkbox', 'dt-grid-checkbox form-check-input'],
        dailyTimeFieldId: 'sagDtRowField-notPhotographed-',
        bulkInputFieldId: 'sagDtBulkInput-notPhotographed',
        width: '81px'
      },
      {
        fieldSlug: 'workCondition',
        label: 'Condition',
        sectionName: Sections.details,
        componentName: DailyTimeComponents.dTErrorValueDropdownComponent,
        valuePath: 'workCondition',
        options: [
          {name: 'Studio', value: WorkConditionEnum.Studio, hoverText: 'Any place which is within the 30 mile Studio Zone'},
          {name: 'Overnight', value: WorkConditionEnum.Overnight, hoverText: 'Less than 24 hours travel time'},
          {name: 'Distant', value: WorkConditionEnum.Distant, hoverText: 'More than 24 hours travel time'},
        ],
        dailyTimesClassNames: ['dts-col dts-col-sm', 'w-100 dt-grid-dropdown'],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-col-sm', 'w-100 dt-grid-dropdown'],
        dailyTimeFieldId: 'sagDtRowField-workCondition-',
        bulkInputFieldId: 'sagDtBulkInput-workCondition',
        width: '102px'
      },
      {
        fieldSlug: 'payType',
        label: 'Pay Type',
        sectionName: Sections.details,
        componentName: DailyTimeComponents.dTPayTypeDropdownComponent,
        valuePath: 'payType',
        dailyTimesClassNames: ['dts-col dts-col-sm', 'w-100 dt-grid-dropdown'],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-col-sm', 'w-100 dt-grid-dropdown'],
        dailyTimeFieldId: 'sagDtRowField-payType-',
        bulkInputFieldId: 'sagDtBulkInput-payType',
        width: '73px'
      },
      {
        fieldSlug: 'dayType2',
        label: 'Day Type',
        sectionName: Sections.details,
        componentName: DailyTimeComponents.dTDayTypeDropdownComponent,
        valuePath: 'dayType2',
        dailyTimesClassNames: ['dts-col dts-col-sm', 'w-100 dt-grid-dropdown'],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-col-sm', 'w-100 dt-grid-dropdown'],
        dailyTimeFieldId: 'sagDtRowField-dayType2-',
        bulkInputFieldId: 'sagDtBulkInput-dayType2',
        width: '90px'
      },
      {
        fieldSlug: 'stateCode',
        label: 'State',
        sectionName: Sections.details,
        componentName: DailyTimeComponents.dtLocationTextInputComponent,
        valuePath: 'stateCode',
        maxLength: TaxJurisdictionInputLength,
        dailyTimesClassNames: ['dts-col dts-col-sm', 'dts-txt-box text-uppercase'],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-col-sm', 'w-100 text-uppercase text-center dts-bulk-input'],
        dailyTimeFieldId: 'sagDtRowField-stateCode-',
        bulkInputFieldId: 'sagDtBulkInput-stateCode',
        width: '45px'
      },
      {
        fieldSlug: 'countyCode',
        label: 'County',
        sectionName: Sections.details,
        componentName: DailyTimeComponents.dtLocationTextInputComponent,
        valuePath: 'countyCode',
        maxLength: TaxJurisdictionInputLength,
        dailyTimesClassNames: ['dts-col dts-col-sm', 'dts-txt-box text-uppercase'],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-col-sm', 'w-100 text-uppercase text-center dts-bulk-input'],
        dailyTimeFieldId: 'sagDtRowField-countyCode-',
        bulkInputFieldId: 'sagDtBulkInput-countyCode',
        width: '55px'
      },
      {
        fieldSlug: 'cityCode',
        label: 'City',
        sectionName: Sections.details,
        componentName: DailyTimeComponents.dtLocationTextInputComponent,
        valuePath: 'cityCode',
        maxLength: TaxJurisdictionInputLength,
        dailyTimesClassNames: ['dts-col dts-col-sm', 'dts-txt-box text-uppercase'],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-col-sm', 'w-100 text-uppercase text-center dts-bulk-input'],
        dailyTimeFieldId: 'sagDtRowField-cityCode-',
        bulkInputFieldId: 'sagDtBulkInput-cityCode',
        width: '45px'
      },
      {
        fieldSlug: 'countryCode',
        label: 'Country',
        sectionName: Sections.details,
        componentName: DailyTimeComponents.dtLocationTextInputComponent,
        valuePath: 'countryCode',
        maxLength: 3,
        dailyTimesClassNames: ['dts-col dts-col-sm', 'dts-txt-box text-uppercase'],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-col-sm', 'w-100 text-uppercase text-center dts-bulk-input'],
        dailyTimeFieldId: 'sagDtRowField-countryCode-',
        bulkInputFieldId: 'sagDtBulkInput-countryCode',
        width: '60px'
      },
      {
        fieldSlug: 'locFinder',
        label: 'Loc Finder',
        sectionName: Sections.details,
        componentName: DailyTimeComponents.dTLocFinderButtonComponent,
        valuePath: 'workDetails.locFinder',
        dailyTimesClassNames: ['dts-col dts-col-sm', 'fas fa-search'],
        bulkInputClassNames: ['dts-cell-item header-sub-title dts-col-sm', 'fas fa-search'],
        dailyTimeFieldId: 'sagDtRowField-locFinder',
        bulkInputFieldId: 'sagDtBulkInput-locFinder',
        width: '74px'
      }
    ]
  }
};
