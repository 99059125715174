import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as _ from 'lodash';
import { RouterStateUrl } from '../../shared/utils';

const _selectTimecardId = (routeState: RouterReducerState<RouterStateUrl>): string => _.get(routeState, 'state.timecardId', '');
const _selectBatchId = (routeState: RouterReducerState<RouterStateUrl>): string => _.get(routeState, 'state.params.batchId', '');
const _selectIsSavedTimecard = (timecardId: string): boolean => timecardId ? _.indexOf(timecardId, '_') < 0 : null;
const _selectRouteStateStatus = (timecardId: string): boolean => timecardId ? timecardId !== 'new' : null;

export const selectRouterState = createFeatureSelector<RouterReducerState<RouterStateUrl>>('router');

export const selectTimecardId = createSelector(
  selectRouterState,
  _selectTimecardId
);
export const selectIsSavedTimecard = createSelector(
  selectTimecardId,
  _selectIsSavedTimecard
);
export const selectRouteStatus = createSelector(
  selectTimecardId,
  _selectRouteStateStatus
);
export const selectBatchId = createSelector(
  selectRouterState,
  _selectBatchId
);
export const selectProjectId = createSelector(
  selectRouterState,
  (routerState: RouterReducerState<RouterStateUrl>): string => _.get(routerState, 'state.projectId')
);
export const selectUrl = createSelector(
  selectRouterState,
  (routeState: RouterReducerState<RouterStateUrl>) => _.get(routeState, 'state.url', '')
);
