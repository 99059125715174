import {inject} from '@angular/core';
import {Observable} from 'rxjs';
import { Store } from '@ngrx/store';
import {tap} from 'rxjs/operators';
import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';

import {RetrieveTimecardTemplateSuccessful} from '../actions/lookup.action';
import {getState} from '../shared/utils';
import {selectSelectedProjectId} from '../auth/selectors';
import { TimecardTemplateService } from '../template/timecards/services/timecard-template.service';
import {TimecardTemplate} from '../../../common/template.model';

export const TemplateResolver: ResolveFn<TimecardTemplate> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  timecardTemplateService: TimecardTemplateService = inject(TimecardTemplateService),
  store: Store = inject(Store)
): Observable<TimecardTemplate> => {
  const projectId = getState<string>(store, selectSelectedProjectId);
  return timecardTemplateService.retrieveTemplate(projectId).pipe(
    tap((timecardTemplate: TimecardTemplate) => store.dispatch(RetrieveTimecardTemplateSuccessful(timecardTemplate)))
  );
};
