<div class="popover-content filter-panel-container">
  <div class="header">
    <h2 class="popover-list-title">Filters</h2>
    <button type="button" class="btn btn-link" qaa="closePanel" (click)="closePanel()">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="content">

    <ng-content select="[projectBrowseType]"></ng-content>
    <ng-content select="[projectBrowseStudio]"></ng-content>
    <ng-content select="st-dropdown-filter"></ng-content>
    <ng-content select="[projectBrowseStatus]"></ng-content>
    <ng-content select="st-date-picker-component"></ng-content>
    <ng-content select="st-date-picker-region-component"></ng-content>
    <ng-content select="st-text-filter"></ng-content>
    <ng-content select="st-misc-filter"></ng-content>
  </div>

  <div class="footer">
    <button type="button" class="btn btn-link st-fs-16" (click)="onClearFilters()" qaa="clearFilters">CLEAR FILTERS</button>
  </div>
</div>
