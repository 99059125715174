import {createAction} from '@ngrx/store';

import {PayCode} from '../../../common/pay-code.model';
import {WorkLocationViewModel} from '../../../common/start-card.model';
import {Department} from '../../../common/department-setting.model';
import {FullUnion, JobDetails} from '../../../common/contract-rate.model';
import {TimecardTemplate} from '../../../common/template.model';
import {GLShortcutsViewModel} from '../../../common/project-setting.model';
import {Paymaster} from '../../../common/paymaster.model';
import {IWorkStatusCombinations} from '../../../common/work-status.model';
import {TimecardLookupData, TimecardTypes} from '../../../common/timecard.model';
import {ExportReportOption} from '../../../common/report.model';

export const RetrieveTaxJurisdictionSuccessful = createAction('[Lookup] RetrieveTaxJurisdictionSuccessful',
  (taxJurisdiction: WorkLocationViewModel[]) => ({taxJurisdiction}));

export const RetrieveDepartmentsSuccessful = createAction(
  '[Lookup] Retrieve Departments Successful',
  (departmentList: Department[]) => ({departmentList})
);

export const RetrieveUnionSuccessful = createAction(
  '[Lookup] Receive Union Code Successful',
  (unions: FullUnion[]) => ({unions})
);

export const RetrieveUnionFailed = createAction(
  '[Lookup] Receive Union Code Failed'
);

export const RetrieveAllPayCodesFailed = createAction(
  '[Lookup] Receive All Pay Codes Failed'
);

export const RetrieveAllPayCodesSuccessful = createAction(
  '[Lookup] Receive All Pay Codes Successful',
  (payCodes: PayCode[]) => ({payCodes})
);
export const RetrieveGlShortcutsSuccessful = createAction(
  '[Lookup] Retrieve Gl Shortcuts Successful',
  (glShortcutList: GLShortcutsViewModel[]) => ({glShortcutList})
);
export const RetrieveTimecardTemplateSuccessful = createAction(
  '[Lookup] Retrieve Timecard Template successful',
  (timecardTemplate: TimecardTemplate) => ({timecardTemplate})
);
export const RetrievePaymasterSuccessful = createAction(
  '[Lookup] Receive Paymaster Successful',
  (paymasters: Paymaster[]) => ({paymasters})
);
export const RetrievePaymanagerSuccessful = createAction(
  '[Lookup] Receive Pay manager Successful',
  (paymasterManagers: Paymaster[]) => ({paymasterManagers})
);

export const RetrieveWorkStatusCombinationSuccessful = createAction(
  '[Lookup] Receive Work Status Combination Successful',
  (workStatusCombinations: IWorkStatusCombinations) => ({workStatusCombinations})
);

export const RetrieveWorkStatusCombinationFailed = createAction(
  '[Lookup] Retrieve Work Status Combination Failed'
);
export const RetrieveTimecardLookupSuccessful = createAction(
  '[Lookup] Retrieve Timecard Lookup Successful',
  (payload: { [key in keyof typeof TimecardTypes]?: TimecardLookupData }) => ({payload})
);
export const RetrieveStartcardExportReportOptionSuccessful = createAction(
    '[Lookup] Retrieve Startcard Export Report Option Successful',
    (startcardExportReportOptions: ExportReportOption[]) => ({startcardExportReportOptions})
);
export const RetrieveStartcardExportReportOptionFailed = createAction(
    '[Lookup] Retrieve Startcard Export Report Option Failed'
);

export const RetrievePayrollDistributionExportReportOptionSuccessful = createAction(
    '[Lookup] Retrieve Payroll Distribution Export Report Option Successful',
    (payrollDistributionExportReportOptions: ExportReportOption[]) => ({payrollDistributionExportReportOptions})
);
export const RetrievePayrollDistributionExportReportOptionFailed = createAction(
    '[Lookup] Retrieve Payroll Distribution Export Report Option Failed'
);
export const RetrieveJobCodes = createAction(
  '[Lookup] Retrieve Job Codes',
  (startcardId: string) => ({startcardId})
);
export const RetrieveJobCodesSuccessful = createAction(
  '[Lookup] Retrieve Job Codes Successful',
  (jobDetails: JobDetails[], contractProfileId: string) => ({jobDetails, contractProfileId})
);
